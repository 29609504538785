import React, { useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import LoginForm from '../components/LoginForm';
import Header from '../../../shared_elements/components/Header';
import '../assets/_styles.scss';
import { toastFlashMessage } from '../../../utils';

export default function Login(props) {
  useEffect(() => {
    if (sessionStorage.getItem('logoutSuccess')) {
      toastFlashMessage('Logged out successfully', 'success');
      sessionStorage.removeItem('logoutSuccess');
    }
  }, []);
  return (
    <section className="user-authentication-section" data-testid="login">
      <Header title="WELCOME TO THE CAMPAIGN REGISTRY"></Header>
      <Grid
        container
        spacing={0}
        alignItems="center"
        justify="center"
        className="user-form-wrapper"
        style={{ height: window.innerHeight - 80 }}
      >
        <LoginForm baseUrl={props.baseUrl} />
      </Grid>
    </section>
  );
}
