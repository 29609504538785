import { withStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';

const CustomTooltip = withStyles((theme) => ({
  arrow: {
    color: '#00698F',
  },
  tooltip: {
    background: '#00698F',
    color: '#fff',
    fontSize: 12,
    borderRadius: '3px',
    maxWidth: 230,
    fontFamily: 'Roboto',
    fontWeight: 400,
    padding: '5px 15px',
  },
}))(Tooltip);
export default CustomTooltip;
