import React, { useState, useEffect } from 'react';
import { ToolTip } from '../../../../shared_elements';
import { CircularProgress, Grid, Link } from '@material-ui/core';
import { ENTITY_TYPES } from '../../../../constants';
import { getSuspensionRule } from '../apiServices';
import {
  US_DATE_FORMAT,
  convertTimeWithTimezone,
} from '../../../../utils/time';
import { withRouter } from 'react-router-dom';
import { Box } from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareMinus, faSquareCheck } from '@fortawesome/pro-solid-svg-icons';

const getR3kStatus = (optionalAttributes) => {
  return optionalAttributes && optionalAttributes['russell3000']
    ? 'True'
    : 'False';
};

const getTaxExemptStatus = (optionalAttributes) => {
  if (optionalAttributes) {
    if (optionalAttributes['section527']) {
      return '527';
    }
    if (optionalAttributes['taxExemptStatus']) {
      return optionalAttributes['taxExemptStatus'];
    }
  }
  return 'N/A';
};

const Field = ({ label, children, className, ...rest }) => (
  <h3 className={`heading2 ${className ?? ''}`} {...rest}>
    <span>{label}:</span>
    {children || 'N/A'}
  </h3>
);

function BrandDetailsBasic(props) {
  const { brandInfo, brandSuspended } = props;
  const [universalEinSuspended, setUniversalEinSuspended] = useState(null);
  const getEntityType = (entityType) => {
    return (
      ENTITY_TYPES.find((entity) => entity.value === entityType)?.label ||
      entityType ||
      'N/A'
    );
  };
  const goToUniversalEin = (event) => {
    event.preventDefault();
    props.history.push({
      pathname: `/universal-ein/${brandInfo.universalEin}/campaigns`,
      state: {
        goBackPage: `/brands/${brandInfo.uid}`,
        suspended: universalEinSuspended,
      },
    });
  };

  const checkSuspensionStatus = async () => {
    const { universalEin, ein, einIssuingCountry } = brandInfo;
    if (universalEin === null) {
      setUniversalEinSuspended(false);
      return;
    }
    const rules = await getSuspensionRule({
      brandUniversalEin: universalEin,
      exactMatch: true,
    });
    if (rules && rules.records.length > 0) {
      const result = rules.records.some(
        (rule) =>
          rule.ein === ein &&
          rule.einIssuingCountry === einIssuingCountry &&
          rule.status === 'ACTIVE'
      );
      setUniversalEinSuspended(result);
    } else {
      setUniversalEinSuspended(false);
    }
  };

  const getGovernmentEntity = () => {
    const { optionalAttributes } = brandInfo || {};
    return optionalAttributes?.governmentEntity ? 'True' : 'False';
  };

  const getPoliticalCommitteeLocale = () => {
    const { optionalAttributes } = brandInfo || {};
    return optionalAttributes?.politicalCommitteeLocale || 'N/A';
  };

  useEffect(() => {
    checkSuspensionStatus();
  }, []);

  return (
    <Grid
      spacing={2}
      container
      className="brand-basic-details"
      data-testid="brandDetailBasic"
    >
      <Grid item xs={4}>
        <div className="left-blk" style={{ height: '100%' }}>
          <h3 className="heading2" data-testid="brandDetailBasicCreateDate">
            <span>Registered on:</span>
            {convertTimeWithTimezone(
              brandInfo.createDate,
              undefined,
              US_DATE_FORMAT
            )}
          </h3>
          <h3
            className="heading2 entityType"
            data-testid="brandDetailBasicEntityType"
          >
            <span>Entity Type:</span>
            {getEntityType(brandInfo.entityType)}
          </h3>
          <h3 className="heading2" data-testid="brandDetailBasicIdentityStatus">
            <span>Identity Status: </span>
            {brandInfo.identityStatus ? brandInfo.identityStatus : 'N/A'}
          </h3>
          <Field label="EIN" data-testid="brandDetailBasicEIN">
            {brandInfo.ein}
          </Field>
        </div>
      </Grid>
      <Grid item xs={4}>
        <div className="middle-blk">
          <h3 className="heading2 mb-4" data-testid="brandDetailBasicBrandUid">
            <span>Brand ID:</span>
            {brandInfo.uid}
          </h3>
          <h3
            className="heading2"
            data-testid="brandDetailBasicBrandSuspended"
            style={{ marginBottom: '16px' }}
          >
            <span>Brand Suspended:</span>
            {brandSuspended === null ? (
              <CircularProgress size={18} style={{ color: '#00698F' }} />
            ) : brandSuspended ? (
              <React.Fragment>
                <FontAwesomeIcon
                  icon={faSquareMinus}
                  size="xl"
                  style={{ color: 'D41C54' }}
                />{' '}
                YES
              </React.Fragment>
            ) : (
              <React.Fragment>
                <FontAwesomeIcon
                  icon={faSquareCheck}
                  size="xl"
                  style={{ color: '009B7C' }}
                />{' '}
                NO
              </React.Fragment>
            )}
          </h3>
          <h3
            className="heading2 mb-4"
            data-testid="brandDetailBasicUniversalEin"
          >
            <Box flexDirection="row" alignItems="center">
              <span>Universal EIN:</span>
              {brandInfo.universalEin ? (
                <Link
                  data-testid="gotoUniversalEINLink"
                  className="universal-ein-link"
                  to="#"
                  underline="always"
                  onClick={goToUniversalEin}
                  style={{ cursor: 'pointer' }}
                >
                  {brandInfo.universalEin}
                </Link>
              ) : (
                'N/A'
              )}
              {brandInfo.universalEin ? (
                <Box margin={{ bottom: 'xxs' }}>
                  <ToolTip title="Universal EIN is a unique identifier generated by TCR and is composed of the country code and the EIN/Tax Number." />
                </Box>
              ) : null}
            </Box>
          </h3>
          <h3
            className="heading2"
            data-testid="brandDetailBasicUniversalEinSuspended"
          >
            <span>Universal EIN Suspended:</span>
            {universalEinSuspended === null ? (
              <CircularProgress size={18} style={{ color: '#00698F' }} />
            ) : universalEinSuspended ? (
              <React.Fragment>
                <FontAwesomeIcon
                  icon={faSquareMinus}
                  size="xl"
                  style={{ color: 'D41C54' }}
                />{' '}
                YES
              </React.Fragment>
            ) : (
              <React.Fragment>
                <FontAwesomeIcon
                  icon={faSquareCheck}
                  size="xl"
                  style={{ color: '009B7C' }}
                />{' '}
                NO
              </React.Fragment>
            )}
          </h3>
        </div>
      </Grid>
      <Grid item xs={4}>
        <div className="right-blk">
          <h3 className="heading2" data-testid="brandDetailBasicRussell3000">
            <span>Russell3000:</span>
            {getR3kStatus(brandInfo.optionalAttributes)}
          </h3>
          <h3
            className="heading2"
            data-testid="brandDetailBasicTaxExemptStatus"
          >
            <span>Tax Exempt Status:</span>
            {getTaxExemptStatus(brandInfo.optionalAttributes)}
          </h3>
          <Field
            label="Government Entity"
            data-testid="brandDetailBasicGovernmentEntity"
          >
            {getGovernmentEntity()}
          </Field>
          <Field
            label="Political Committee Locale"
            data-testid="brandDetailBasicPoliticalCommitteeLocale"
          >
            {getPoliticalCommitteeLocale()}
          </Field>
        </div>
      </Grid>
    </Grid>
  );
}

export default withRouter(BrandDetailsBasic);
