import React from 'react';
import { Grid } from '@material-ui/core';
import { CspDetailItem } from '../../Csp/components';

export default function BrandAddressDetails(props) {
  const { street, city, state, postalCode, country } = props;
  return (
    <Grid container>
      <Grid item xs={6}>
        <CspDetailItem title="Address / Street" value={street} />
        <CspDetailItem title="City" value={city} />
        <CspDetailItem title="State" value={state} />
      </Grid>
      <Grid item xs={6}>
        <CspDetailItem title="Postal Code" value={postalCode} />
        <CspDetailItem title="Country" value={country} />
      </Grid>
    </Grid>
  );
}
