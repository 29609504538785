import React, { Component } from 'react';
import technical_error from '../assets/images/technical_error.svg';
import { Container, Grid } from '@material-ui/core';
function ErrorBoundaryHoc(ComposedComponent, extraInfo) {
  class ErrorBoundaryHoc extends Component {
    constructor(props) {
      super(props);
      this.state = {
        error: null,
        errorInfo: null,
      };
    }
    componentDidCatch(error, errorInfo) {
      this.setState({
        error: error,
        errorInfo: errorInfo,
      });
    }
    render() {
      // console.log('this.props EBHOC', this.props)
      if (this.state.errorInfo) {
        return (
          <Container spacing={0} justify="center">
            <Grid item>
              <div className="technical-error">
                <img src={technical_error} alt="tech-error-page-bg" />
                <h3>Something went wrong here…..</h3>
                <p>
                  Sorry, we are having some technical issues ( as you can see)
                  try to refresh the page, sometime works :)
                </p>
              </div>
            </Grid>
          </Container>
        );
      } else {
        return <ComposedComponent {...this.props} />;
      }
    }
  }
  return ErrorBoundaryHoc;
}
export default ErrorBoundaryHoc;
