import { datadogRum } from '@datadog/browser-rum';

import {
  DATADOG_APPLICATION_ID,
  DATADOG_CLIENT_TOKEN,
  DATADOG_ENV,
  PACKAGE_JSON_VERSION,
} from '../constants';

const defaultConfig = {
  applicationId: DATADOG_APPLICATION_ID,
  clientToken: DATADOG_CLIENT_TOKEN,
  site: 'datadoghq.com',
  service: 'tcr-mno-portal',
  env: DATADOG_ENV,
  version: PACKAGE_JSON_VERSION,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  startSessionReplayRecordingManually: true,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  trackViewsManually: true,
  defaultPrivacyLevel: 'mask-user-input',
};

const datadog = {
  init: async (customConfig = {}) => {
    const config = {
      ...defaultConfig,
      ...customConfig,
    };
    !datadogRum.getInitConfiguration() && datadogRum.init(config);
  },
  setUser: (user) => {
    const config = {
      id: user.profile?.mnoNetworkId ?? user.id,
      name: user.profile.fullName,
      email: user.profile.email,
      userId: user.id,
      authUid: user.profile.mnoNetworkId,
    };
    datadogRum.setUser(config);
    // Disable session replay recording for now
    // datadogRum.startSessionReplayRecording();
  },
  hasUser: () => {
    const user = datadogRum.getUser();
    if (user && user.id) {
      return true;
    }
    return false;
  },
};

export default datadog;
