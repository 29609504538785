import React from 'react';
import { Grid, Container, CircularProgress } from '@material-ui/core';
import { isEqual } from 'lodash-es';
import queryString from 'query-string';
import { globalGetService } from '../../../../utils/globalApiServices';
import {
  BulkActions,
  CampaignListingRow,
  CampaignBulkRejectModal,
} from '../components';
import { toastFlashMessage } from '../../../../utils';
import { Loader, FilterToggleButton } from '../../../../shared_elements';
import {
  getAllUsecasesTypes,
  updateBulkCampaignStatusApi,
} from '../apiServices';
import '../assets/campaign-listing-module.scss';
import { MNO_STATUS } from '../../../../constants';
import {
  BoxV2 as Box,
  Flex,
  Table,
  FilterType,
  TableFilter,
} from 'portal-commons';
import { withFilters } from '../../../../hocs';
import {
  convertTimeWithTimezone,
  US_DATE_FORMAT,
} from '../../../../utils/time';

const FILTER_CONFIG = {
  uid: {
    type: FilterType.Text,
    label: 'Campaign ID',
    placeholder: 'Enter Campaign ID',
  },
  brandUid: {
    type: FilterType.Text,
    label: 'Brand ID',
    placeholder: 'Enter Brand ID',
  },
  brandName: {
    type: FilterType.Text,
    label: 'Brand Name',
    placeholder: 'Enter Brand Name',
    suggestions: [],
  },
  cspName: {
    type: FilterType.Text,
    label: 'CSP Name',
    placeholder: 'Enter CSP Name',
    suggestions: [],
  },
  usecase: {
    type: FilterType.Dropdown,
    options: [],
    label: 'Use Case',
    placeholder: 'Use-Case',
  },
  resellerUid: {
    type: FilterType.Text,
    label: 'Reseller ID',
    placeholder: 'Enter Reseller ID',
  },
  status: {
    type: FilterType.Dropdown,
    options: [
      { label: 'Active', value: 'ACTIVE' },
      { label: 'Deactivated', value: 'EXPIRED' },
    ],
    label: 'TCR Status',
    placeholder: 'TCR Status',
  },
  mnoStatus: {
    type: FilterType.Dropdown,
    options: MNO_STATUS,
    label: 'MNO Status',
    placeholder: 'MNO Status',
    multiple: true,
    width: 250,
  },
};

const DEFAULT_ADDITIONAL_FILTERS = {
  sortField: 'createDate',
  ascendingOrder: false,
};

const headRows = [
  { id: 'uid', label: 'CAMPAIGN ID', sortable: true },
  { id: 'brandUid', label: 'BRAND ID', sortable: true },
  { id: 'brandName', label: 'BRAND NAME', sortable: false },
  { id: 'cspName', label: 'CSP NAME', sortable: true },
  { id: 'usecase', label: 'USE-CASE', sortable: true },
  { id: 'createDate', label: 'REGISTERED ON', sortable: true },
  { id: 'resellerUid', label: 'RESELLER ID', sortOption: false },
  { id: 'tcrStatus', label: 'TCR STATUS', sortable: false },
  { id: 'operationStatus', label: 'MNO STATUS', sortable: false },
];

const fetchBrandSuggestions = (query = {}) =>
  globalGetService('mno/brands/suggestions', { ...query, limit: 20 }).then(
    (response) => {
      if (response && response.status >= 200 && response.status < 300) {
        return response.data?.map((d) => d?.brandName) ?? [];
      }
      return [];
    }
  );

const fetchCspSuggestions = (query = {}) =>
  globalGetService('mno/csp/suggestions', { ...query, limit: 20 }).then(
    (response) => {
      if (response && response.status >= 200 && response.status < 300) {
        return response.data?.map((d) => d?.cspName) ?? [];
      }
      return [];
    }
  );

class CampaignsListing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      campaignInfo: {
        records: [],
        page: 1,
        recordsPerPage: 10,
        totalRecords: 0,
      },
      tableLoader: true,
      loader: true,
      selectedCampaigns: [],
      bulkActionLoader: false,
      reviewPage: this.props.location.pathname.includes('review-campaigns'),
      openRejectModal: false,
      additionalFilters: DEFAULT_ADDITIONAL_FILTERS,
      isFilterVisible: false,
    };
  }

  fetchFilterOptions = async () => {
    const { setOptions } = this.props.filter;
    const usecaseTypes = await getAllUsecasesTypes();

    setOptions(
      'usecase',
      usecaseTypes.map((item) => ({
        label: item.displayName,
        value: item.id,
      }))
    );
  };

  updateMnoStatusReviewOptionDisabled = (disabled = false) => {
    const { setOptions } = this.props.filter;
    setOptions(
      'mnoStatus',
      MNO_STATUS.map((item) =>
        item.value === 'REVIEW' ? { ...item, disabled } : item
      )
    );
  };

  parseSearchParams = (params = {}) => {
    let updatedFilter = this.state.additionalFilters;
    const { appliedFilters } = this.props.filter;
    const queries = queryString.parse(params, { decode: true });
    if (!Object.keys(queries).length) {
      updatedFilter = DEFAULT_ADDITIONAL_FILTERS;
    } else if (queries.sortField || queries.ascendingOrder) {
      updatedFilter = {
        sortField: queries.sortField,
        ascendingOrder: JSON.parse(queries.ascendingOrder),
      };
    }

    if (this.state.reviewPage) {
      updatedFilter.mnoStatus = 'REVIEW';
      updatedFilter.status = 'ACTIVE';
    }

    this.setState({ additionalFilters: updatedFilter });

    return {
      ...updatedFilter,
      ...appliedFilters,
      page: queries.page ? queries.page : 1,
    };
  };

  processFilterSpecialCase = (values = {}) => {
    const newValues = { ...values };
    const { configs } = this.props.filter;
    const isStatusExpired = values?.status === 'EXPIRED';
    const isMnoStatusHasPreview = values?.mnoStatus?.includes('REVIEW');
    const mnoStatusReviewIsDisabled = configs.mnoStatus.options.find(
      (item) => item.value === 'REVIEW' && item.disabled
    );

    if (isStatusExpired) {
      if (isMnoStatusHasPreview) {
        const newMnoStatus = values.mnoStatus
          .split(',')
          .filter((item) => item !== 'REVIEW')
          .join(',');
        if (newMnoStatus) {
          newValues.mnoStatus = newMnoStatus;
        } else {
          delete newValues.mnoStatus;
        }
      }
      if (!mnoStatusReviewIsDisabled) {
        this.updateMnoStatusReviewOptionDisabled(true);
      }
    } else if (mnoStatusReviewIsDisabled) {
      this.updateMnoStatusReviewOptionDisabled(false);
    }
    return newValues;
  };

  componentDidMount() {
    this.fetchFilterOptions();

    // Disable status and mnoStatus filter for Review Campaign page
    if (this.state.reviewPage) {
      const { configs, setConfig } = this.props.filter;
      setTimeout(() => {
        setConfig('status', { ...configs.status, disabled: true });
      }, 0);
      setTimeout(() => {
        setConfig('mnoStatus', { ...configs.mnoStatus, disabled: true });
      }, 0);
    }

    if (this.props.location.search) {
      const queries = this.parseSearchParams(this.props.location.search);
      const finalQuery = this.processFilterSpecialCase(queries);
      if (isEqual(queries, finalQuery)) {
        this.fetchCampaignsList(finalQuery);
      } else {
        this.writeQueryString(finalQuery);
      }
    } else if (this.state.reviewPage) {
      const defaultReviewFilters = {
        status: 'ACTIVE',
        mnoStatus: 'REVIEW',
      };
      this.props.filter.handleApply(defaultReviewFilters);
    } else {
      this.fetchCampaignsList({ ...this.state.additionalFilters });
    }
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.location.search, this.props.location.search)) {
      const params = this.parseSearchParams(this.props.location.search);
      this.fetchCampaignsList(params);
    }
  }

  fetchCampaignsList = (query = {}) => {
    this.setState({ tableLoader: true });
    const updatedQuery = { ...query };
    const isStatusExpired = query?.status === 'EXPIRED';
    if (isStatusExpired && !query?.mnoStatus && !this.state.reviewPage) {
      updatedQuery.mnoStatus = 'REJECTED,REGISTERED,SUSPENDED';
    }
    globalGetService('mno/campaigns', updatedQuery).then((response) => {
      this.setState({ tableLoader: false });
      if (response.status >= 200 && response.status < 300) {
        const campaignInfo = {
          ...response.data,
          totalRecords: Math.min(response.data.totalRecords, 10000),
          // Cap total records as ElasticSearch returns up to 10,000 by default
          displayTotal: response.data.totalRecords,
          // But keep the original count for displaying on the top of campaign listing page
        };
        campaignInfo.records.forEach((campaign) => {
          campaign.createDate = convertTimeWithTimezone(
            campaign.createDate,
            undefined,
            US_DATE_FORMAT
          );
        });
        this.setState((prevState) => ({
          ...prevState,
          campaignInfo,
          loader: false,
          selectedCampaigns: [],
        }));

        if (
          campaignInfo.records.length === 0 &&
          campaignInfo.totalRecords > 0 &&
          campaignInfo.page > 1
        ) {
          const lastPageNo = Math.ceil(campaignInfo.totalRecords / 10);
          this.setState({ loader: true });
          this.writeQueryString({ ...query, page: lastPageNo });
        }
      }
    });
  };

  generateOptions = (data, key) => {
    const options = data.map((item) => {
      return {
        label: item.displayName,
        value: item.uid ? item.uid : item.id,
        isChecked: false,
      };
    });
    this.setState((prevState) => ({
      ...prevState,
      filterObject: {
        ...prevState.filterObject,
        [key]: {
          ...prevState.filterObject[key],
          options,
        },
      },
    }));
  };

  handleCandidateFiltersChange = (values = {}) => {
    const newValues = this.processFilterSpecialCase(values);
    this.props.filter.handleEdit(newValues);
  };

  handleAppliedFiltersChange = (values = {}) => {
    if (this.state.reviewPage) {
      // To keep Review page always apply filter
      // status = 'ACTIVE' and mnoStatus = 'REVIEW'
      values.status = 'ACTIVE';
      values.mnoStatus = 'REVIEW';
    }
    this.props.filter.handleApply(values);
  };

  handleChangePage = (newPage) => {
    const { additionalFilters } = this.state;
    const { appliedFilters } = this.props.filter;

    this.writeQueryString({
      ...additionalFilters,
      ...appliedFilters,
      page: newPage,
    });
  };

  handleSorting = (sortField) => {
    const { campaignInfo, additionalFilters } = this.state;
    const { appliedFilters } = this.props.filter;
    this.setState((prevState) => ({
      ...prevState,
      additionalFilters: {
        ...prevState.additionalFilters,
        ascendingOrder: !prevState.additionalFilters.ascendingOrder,
        sortField,
      },
    }));

    if (campaignInfo.totalRecords) {
      this.writeQueryString({
        ...additionalFilters,
        ...appliedFilters,
        page: campaignInfo.page,
        sortField,
        ascendingOrder: !additionalFilters.ascendingOrder,
      });
    }
  };

  handleSelectCampaign = (selected) => {
    this.setState({
      selectedCampaigns: selected,
    });
  };

  handleUpdateStatus = async (status, explanation = '') => {
    const { selectedCampaigns } = this.state;
    this.setState({ bulkActionLoader: true });
    const formData = {
      explanation:
        status == 'APPROVED'
          ? 'Campaign approved'
          : explanation
          ? explanation
          : 'Campaign rejected',
      campaignUid: selectedCampaigns,
      status,
    };
    const result = await updateBulkCampaignStatusApi(formData);
    if (result) {
      // Delay 3 seconds to wait for elastic search data updated
      setTimeout(() => {
        this.setState({
          bulkActionLoader: false,
          selectedCampaigns: [],
          openRejectModal: false,
        });
        toastFlashMessage(
          'Updated the campaign status successfully',
          'success'
        );

        // After bulk actions, the URL content doesn't change.
        // So we need to re-fetch data manually
        const params = this.parseSearchParams(this.props.location.search);
        this.fetchCampaignsList(params);
      }, 3000);
    }
  };

  writeQueryString = (searchParams = {}) => {
    this.props.history.push({
      search: `?${queryString.stringify(searchParams, { encode: false })}`,
    });
  };

  toggleFilterVisibility = () => {
    this.setState((prev) => ({
      isFilterVisible: !prev.isFilterVisible,
    }));
  };

  displayCampaignStatus = (appliedFilter) => {
    if (appliedFilter) {
      const { status, mnoStatus } = appliedFilter;
      if (!mnoStatus && status) {
        return status === 'EXPIRED' ? 'Deactivated ' : 'Active ';
      }
    }
    return '';
  };

  displayTitle = (total, appliedFilter) => {
    if (this.state.loader) return 'Campaigns';
    if (this.state.reviewPage) {
      return `${total} Campaigns Awaiting Review`;
    } else {
      return `${total} ${this.displayCampaignStatus(appliedFilter)} Campaigns`;
    }
  };

  render() {
    const {
      campaignInfo,
      tableLoader,
      loader,
      selectedCampaigns,
      bulkActionLoader,
      reviewPage,
      openRejectModal,
      additionalFilters,
      isFilterVisible,
    } = this.state;

    const {
      configs: filterConfigs,
      appliedFilters,
      candidateFilters,
    } = this.props.filter;

    return (
      <Box sx={{ width: '100%' }}>
        <section className="campaigns-listing-section page-content">
          {loader ? (
            <Loader />
          ) : (
            <Container maxWidth={false} className="campaigns-listing-container">
              <Box>
                <div className="title-block">
                  <h2>
                    {campaignInfo.displayTotal === 0
                      ? 'Campaigns'
                      : `${this.displayTitle(
                          campaignInfo.displayTotal,
                          appliedFilters
                        )}`}
                  </h2>
                  <span className="">
                    Results capped at first 10,000 records
                  </span>
                </div>
                {reviewPage ? (
                  <BulkActions
                    handleUpdateStatus={this.handleUpdateStatus}
                    selectedCampaigns={selectedCampaigns}
                    loader={bulkActionLoader}
                    handleOpenRejectModal={(flag) =>
                      this.setState({ openRejectModal: flag })
                    }
                  />
                ) : null}
              </Box>
              <Flex
                sx={{
                  flexDirection: 'column',
                  marginTop: 's',
                  marginBottom: 's',
                }}
              >
                <Box>
                  <FilterToggleButton
                    visible={isFilterVisible}
                    onClick={this.toggleFilterVisibility}
                  />
                </Box>
                {isFilterVisible && (
                  <TableFilter
                    portal="mno"
                    configs={filterConfigs}
                    candidateValues={candidateFilters}
                    appliedValues={appliedFilters}
                    onCandidateValuesChange={this.handleCandidateFiltersChange}
                    onAppliedValuesChange={this.handleAppliedFiltersChange}
                    className="filters"
                    data-testid={reviewPage ? 'reviewCampaigns' : 'campaigns'}
                  />
                )}
              </Flex>
              <Grid
                container
                justifyContent="center"
                spacing={0}
                className={reviewPage ? 'reviewPage' : ''}
              >
                {bulkActionLoader ? (
                  <div className="loader-wrapper">
                    <CircularProgress size={40} />
                  </div>
                ) : (
                  <Table
                    testId="campaignsTable"
                    records={{ total: campaignInfo.totalRecords }}
                    loading={tableLoader}
                    handleChangePage={this.handleChangePage}
                    createSortHandler={this.handleSorting}
                    emptyState="no campaigns to view"
                    filter={additionalFilters}
                    headRows={headRows}
                    data={campaignInfo.records}
                    rowKey="uid"
                    renderRow={(data, attributes) => {
                      return (
                        <CampaignListingRow
                          key={data.uid}
                          campaign={data}
                          selectable={attributes.selectable}
                          selected={attributes.selected}
                          onSelectedChange={attributes.onSelectedChange}
                        />
                      );
                    }}
                    pagination={{
                      count: Math.ceil(
                        campaignInfo.totalRecords / campaignInfo.recordsPerPage
                      ),
                      page: campaignInfo.page,
                      rowsPerPage: campaignInfo.recordsPerPage,
                      totalRecords: campaignInfo.totalRecords,
                    }}
                    selectable={reviewPage}
                    selectedRows={selectedCampaigns}
                    onSelectChange={this.handleSelectCampaign}
                  />
                )}
              </Grid>
            </Container>
          )}
        </section>
        {openRejectModal && !bulkActionLoader ? (
          <CampaignBulkRejectModal
            toggleModal={(flag) => this.setState({ openRejectModal: flag })}
            handleUpdateStatus={this.handleUpdateStatus}
            loader={bulkActionLoader}
            count={selectedCampaigns.length}
          />
        ) : null}
      </Box>
    );
  }
}

export default withFilters(CampaignsListing, {
  configs: FILTER_CONFIG,
  loaders: {
    suggestion: {
      brandName: {
        minLength: 2,
        load: (value) => fetchBrandSuggestions({ prefix: value }),
      },
      cspName: {
        minLength: 2,
        load: (value) => fetchCspSuggestions({ prefix: value }),
      },
    },
  },
});
