import { Flex } from 'portal-commons';
import { FunctionComponent } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  faCircleCheck,
  faCircleXmark,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TableCell, TableRow } from '@material-ui/core';

import { formatTimestampToDate } from '../utils';

interface RecordListingRowProps {
  campaignUid?: string;
  nnid?: string;
  current: boolean;
  onMyNetwork: boolean;
  startTimestamp: number;
  endTimestamp?: number;
}

const RecordListingRow: FunctionComponent<RecordListingRowProps> = ({
  campaignUid,
  nnid,
  current,
  onMyNetwork,
  startTimestamp,
  endTimestamp,
}) => {
  const history = useHistory();
  const location = useLocation();

  return (
    <TableRow
      data-testid="recordListingRow"
      style={{ pointerEvents: onMyNetwork ? 'auto' : 'none' }}
      onClick={() => {
        if (campaignUid) {
          history.push({
            pathname: `/campaigns/${campaignUid}`,
            state: {
              goBackPage: `${location.pathname}${location.search ?? ''}`,
            },
          });
        }
      }}
    >
      <TableCell align="left" data-testid="recordListingRowCampaignUid">
        <span>{campaignUid ?? 'N/A'}</span>
      </TableCell>
      <TableCell align="left" data-testid="recordListingRowNnid">
        <span>{nnid ?? 'N/A'}</span>
      </TableCell>
      <TableCell align="left" data-testid="recordListingRowCurrent">
        <span>{current ? 'YES' : 'NO'}</span>
      </TableCell>
      <TableCell align="left" data-testid="recordListingRowOnMyNetwork">
        <Flex sx={{ flexDirection: 'row', columnGap: '7px' }}>
          <FontAwesomeIcon
            icon={onMyNetwork ? faCircleCheck : faCircleXmark}
            color={onMyNetwork ? '#009B7C' : '#AA0000'}
          />
          <span>{onMyNetwork ? 'YES' : 'NO'}</span>
        </Flex>
      </TableCell>
      <TableCell align="left" data-testid="recordListingRowStartTimestamp">
        <span>{formatTimestampToDate(startTimestamp)}</span>
      </TableCell>
      <TableCell align="left" data-testid="recordListingRowEndTimestamp">
        <span>
          {endTimestamp ? formatTimestampToDate(endTimestamp) : 'N/A'}
        </span>
      </TableCell>
    </TableRow>
  );
};

export default RecordListingRow;
