import { sub } from 'date-fns';

const Today = new Date();
Today.setHours(0, 0, 0, 0);

export const DateRangePresets = [
  {
    label: 'Today',
    start: Today,
    end: Today,
  },
  {
    label: 'Last 7 days',
    start: sub(Today, { days: 7 }),
    end: Today,
  },
  {
    label: 'Last 1 month',
    start: sub(Today, { months: 1 }),
    end: Today,
  },
  {
    label: 'Last 6 months',
    start: sub(Today, { months: 6 }),
    end: Today,
  },
];
