import { BoxV2 as Box, Button, Flex } from 'portal-commons';
import { FunctionComponent } from 'react';
import { Dialog, makeStyles } from '@material-ui/core';

interface MfaToggleModalProps {
  open: boolean;
  disabled?: boolean;
  on: boolean;
  onClose?: () => void;
  onSubmit?: () => void;
}

const useStyles = makeStyles({
  container: {
    width: '525px',
    padding: '24px 32px 9px',
  },
});

const MfaToggleModal: FunctionComponent<MfaToggleModalProps> = ({
  open,
  disabled,
  on,
  onClose,
  onSubmit,
}) => {
  const classes = useStyles();

  return (
    <Dialog open={open} classes={{ paper: classes.container }}>
      <Flex sx={{ flexDirection: 'column', rowGap: '8px' }}>
        <Box
          sx={{
            paddingTop: '12px',
            textAlign: 'center',
            color: '#49535D',
            fontFamily: 'Roboto',
            fontWeight: 400,
            fontSize: '20px',
            lineHeight: '23px',
          }}
        >
          Please confirm that you want to{' '}
          <span style={{ fontWeight: 700 }}>
            {on
              ? 'activate MFA for all portal users.'
              : 'deactivate MFA for all portal users.'}
          </span>
        </Box>
        <Flex
          sx={{
            flexDirection: 'row',
            justifyContent: 'center',
            columnGap: '20px',
            padding: '24px 0 40px',
          }}
        >
          <Button
            variant="outline"
            onClick={onClose}
            data-testid="cancelMfaBtn"
          >
            Cancel
          </Button>
          <Button
            disabled={disabled}
            onClick={onSubmit}
            data-testid="confirmMfaBtn"
          >
            Confirm
          </Button>
        </Flex>
      </Flex>
    </Dialog>
  );
};

export default MfaToggleModal;
