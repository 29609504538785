import React, { Component } from 'react';
import { Grid, Container } from '@material-ui/core';
import queryString from 'query-string';
import { withStyles } from '@material-ui/core/styles';
import { Button, Box, BoxV2, CampaignAttributes, Table } from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Loader, ListingTable } from '../../../../shared_elements';
import {
  getCampaignDetailApi,
  listCampaignPhoneNumbers,
  updateCampaignStatusApi,
  getCampaignEventsListApi,
  getCampaignSharingChain,
} from '../apiServices';
import {
  CampaignActionModal,
  SampleMessageView,
  CampaignDetailsBasic,
  CampaignCspDetails,
  CampaignBrandDetails,
  CampaignResellerDetails,
  CampaignCarrierStatus,
  EventsListingRow,
  NumbersProvisioned,
  MultimediaList,
} from '../components';
import '../assets/campaign-details-module.scss';
import { toastFlashMessage } from '../../../../utils';

import { ATTACHMENT_FOLDER } from '../constants';
import SharingChain from '../components/SharingChain';
import {
  faAngleLeft,
  faRotateRight,
  faSquareMinus,
  faSquareExclamation,
  faTag,
  faCircleUser,
  faArrowsTurnRight,
  faSquarePollHorizontal,
  faMessageDots,
} from '@fortawesome/pro-regular-svg-icons';
import { faCheck, faXmark } from '@fortawesome/pro-solid-svg-icons';
import { faCalendarRange } from '@fortawesome/pro-regular-svg-icons';

const useStyles = (theme) => ({
  label: {
    width: '220px',
    fontSize: '14px',
  },
  value: {
    flex: 1,
    paddingRight: '4px',
    fontWeight: 600,
    fontSize: '14px',
  },
});

const headRows = [
  { label: 'EVENT TYPE', id: 'eventType', sortable: false },
  { label: 'SOURCE', id: 'categoryType', sortable: false },
  { label: 'CAMPAIGN ID', id: 'campaignUid', sortable: false },
  { label: 'BRAND ID', id: 'brandUid', sortable: false },
  { label: 'DATE & TIME', id: 'updateDate', sortable: false },
  { label: 'DESCRIPTION', id: 'description', sortable: false },
];

const SectionTitle = ({ children, icon, className, iconSize }) => (
  <div className={className || 'details-heading'}>
    <Box flexDirection="row" alignItems="center">
      <Box margin={{ right: 'xs' }}>
        <FontAwesomeIcon
          size={iconSize}
          icon={icon}
          style={{ fontSize: '18px' }}
        />
      </Box>
      <h3 className="heading2">{children}</h3>
    </Box>
  </div>
);

class CampaignDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      campaign: {},
      loader: true,
      modalOpen: false,
      phoneNumbers: [],
      goBackPage: '/campaigns',
      eventInfo: {
        records: [],
        page: 1,
        recordsPerPage: 10,
        totalRecords: 0,
      },
      filter: {},
      tableLoader: true,
      sharingNodes: [],
      optinPopup: false,
      optoutPopup: false,
      helpPopup: false,
    };
    this.getCampaignDetailApi = getCampaignDetailApi.bind(this);
    this.listCampaignPhoneNumbers = listCampaignPhoneNumbers.bind(this);
    this.getCampaignEventsListApi = getCampaignEventsListApi.bind(this);
  }

  componentDidMount() {
    const id = this.props.match.params.id;
    if (this.props.location.state) {
      const { campaign, goBackPage } = this.props.location.state;
      if (!campaign) {
        this.getCampaignDetailApi(id);
      }
      this.setState({
        campaign,
        loader: campaign ? false : true,
        goBackPage: goBackPage,
      });
    } else {
      this.getCampaignDetailApi(id);
    }
    this.fetchSharingChain(id);
    this.listCampaignPhoneNumbers(id);

    if (this.props.location.search) {
      const querys = queryString.parse(this.props.location.search, {
        decode: true,
      });
      this.getCampaignEventsListApi({ page: querys.page ? querys.page : 1 });
    } else {
      this.getCampaignEventsListApi();
    }
  }

  parseSearchParams = (params = {}) => {
    const querys = queryString.parse(params, { decode: true });
    this.getCampaignEventsListApi({ page: querys.page ? querys.page : 1 });
  };

  componentDidUpdate(prevProps) {
    // debugger
    if (prevProps.location.search != this.props.location.search) {
      this.parseSearchParams(this.props.location.search);
    }
  }

  fetchSharingChain = async (campaignUid) => {
    const response = await getCampaignSharingChain(campaignUid);
    this.setState({
      sharingNodes: response.nodes,
    });
  };

  handleOptinPopupChange = (active) => {
    this.setState({
      optinPopup: active,
      optoutPopup: false,
      helpPopup: false,
    });
  };

  handleOptoutPopupChange = (active) => {
    this.setState({
      optinPopup: false,
      optoutPopup: active,
      helpPopup: false,
    });
  };

  handleHelpPopupChange = (active) => {
    this.setState({
      optinPopup: false,
      optoutPopup: false,
      helpPopup: active,
    });
  };

  handleChangePage = (newPage) => {
    this.props.history.push({
      search: `?${queryString.stringify({ page: newPage }, { encode: false })}`,
    });
  };

  createSortHandler = (sortField) => {
    const { filter, eventInfo } = this.state;
    this.setState((prevState) => ({
      ...prevState,
      filter: {
        ...prevState.filter,
        ascendingOrder: !prevState.filter.ascendingOrder,
        sortField,
      },
    }));
    if (eventInfo.totalRecords) {
      this.props.history.push({
        search: `?${queryString.stringify(
          {
            page: eventInfo.page,
            sortField,
            ascendingOrder: !filter.ascendingOrder,
          },
          { encode: false }
        )}`,
      });
    }
  };

  toggleModal = (flag, type = '') => {
    this.setState((prevState) => ({
      ...prevState,
      modalOpen: flag,
      type,
    }));
  };

  updateOperationStatus = (type) => {
    this.getCampaignDetailApi(this.props.match.params.id);
    this.setState((prevState) => ({
      ...prevState,
      campaign: {
        ...prevState.campaign,
        operationStatus: type === 'suspend' ? 'SUSPENDED' : 'APPROVED',
      },
    }));
  };

  handleGoBack = () => {
    const { goBackPage } = this.state;
    this.props.history.push(`${goBackPage}`);
  };

  handleUpdateCampaignStatus = (status) => {
    const { campaign } = this.state;
    this.setState({ statusLoader: true });
    updateCampaignStatusApi(campaign.uid, {
      status,
      explanation: status == 'APPROVED' ? 'Campaign approved' : '',
    }).then((response) => {
      this.setState({ statusLoader: false });
      if (response.status == 200 && response.status < 300) {
        toastFlashMessage(
          `Campaign ${status == 'APPROVED' ? 'approved' : ''} successfully`,
          'success'
        );
        this.setState((prevState) => ({
          ...prevState,
          campaign: {
            ...prevState.campaign,
            operationStatus: status,
          },
        }));
      }
    });
  };

  withDefaultValue = (value, defaultVal = 'N/A') => {
    return value || defaultVal;
  };

  render() {
    const {
      campaign,
      loader,
      modalOpen,
      phoneNumbers,
      goBackPage,
      type,
      statusLoader,
      eventInfo,
      tableLoader,
    } = this.state;
    const { classes } = this.props;
    const carrier =
      campaign && campaign.rulesEngineResultsJson
        ? JSON.parse(campaign.rulesEngineResultsJson).mno
        : null;

    return (
      <section
        className="campaign-details-section page-content"
        data-testid="campaignDetail"
      >
        {loader ? (
          <Loader />
        ) : (
          <Container maxWidth={false} className="campaign-details-container">
            <Grid container className="top-blk">
              <Grid
                item
                xs={6}
                className="back-btn"
                data-testid="campaignDetailBackButton"
              >
                <a onClick={this.handleGoBack} className="paragraph">
                  <Box flexDirection="row" alignItems="center">
                    <Box margin={{ right: 's' }}>
                      <FontAwesomeIcon icon={faAngleLeft} size="lg" />
                    </Box>
                    {goBackPage == '/campaigns'
                      ? 'back to all campaigns'
                      : 'back'}
                  </Box>
                </a>
              </Grid>
              <Grid item xs={6} className="button-wrapper">
                <ul
                  className="list-inline"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'end',
                  }}
                >
                  {campaign.operationStatus === 'APPROVED' ||
                  campaign.operationStatus === 'REGISTERED' ? (
                    <>
                      <li>
                        <Button
                          data-testid="fileAComplaintButton"
                          portal="mno"
                          shape="rounded"
                          variant="outline"
                          color="primary"
                          onClick={(event) => {
                            // If we do not add stop Propagation and prevent default. It would close the modal.
                            event.stopPropagation();
                            event.preventDefault();
                            this.toggleModal(true, 'report');
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faSquareExclamation}
                            size="lg"
                          />
                          <span>File a Complaint</span>
                        </Button>
                      </li>
                      <li>
                        <Button
                          data-testid="suspendCampaignButton"
                          portal="mno"
                          shape="rounded"
                          variant="standard"
                          color="primary"
                          onClick={(event) => {
                            // If we do not add stop Propagation and prevent default. It would close the modal.
                            event.stopPropagation();
                            event.preventDefault();
                            this.toggleModal(true, 'suspend');
                          }}
                        >
                          <Box>
                            <FontAwesomeIcon icon={faSquareMinus} size="lg" />
                          </Box>
                          <span>Suspend Campaign</span>
                        </Button>
                      </li>
                    </>
                  ) : campaign.operationStatus === 'REVIEW' ? (
                    <>
                      <li>
                        <Button
                          data-testid="approveCampaignButton"
                          portal="mno"
                          shape="rounded"
                          variant="standard"
                          color="secondary"
                          onClick={(event) => {
                            // If we do not add stop Propagation and prevent default. It would close the modal.
                            event.stopPropagation();
                            event.preventDefault();
                            this.handleUpdateCampaignStatus('APPROVED');
                          }}
                        >
                          <Box>
                            <FontAwesomeIcon icon={faCheck} />
                          </Box>
                          <span>Approve Campaign</span>
                        </Button>
                      </li>
                      <li>
                        <Button
                          data-testid="rejectCampaignButton"
                          portal="mno"
                          disabled={statusLoader}
                          shape="rounded"
                          variant="standard"
                          color="primary"
                          onClick={(event) => {
                            // If we do not add stop Propagation and prevent default. It would close the modal.
                            event.stopPropagation();
                            event.preventDefault();
                            this.toggleModal(true, 'reject');
                          }}
                        >
                          <FontAwesomeIcon icon={faXmark} />
                          <span>Reject Campaign</span>
                        </Button>
                      </li>
                    </>
                  ) : campaign.operationStatus === 'SUSPENDED' ? (
                    <>
                      <li>
                        <Button
                          data-testid="fileAComplaintButton"
                          portal="mno"
                          shape="rounded"
                          variant="outline"
                          color="primary"
                          onClick={(event) => {
                            // If we do not add stop Propagation and prevent default. It would close the modal.
                            event.stopPropagation();
                            event.preventDefault();
                            this.toggleModal(true, 'report');
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faSquareExclamation}
                            size="lg"
                          />
                          <span>File a Complaint</span>
                        </Button>
                      </li>
                      <li>
                        <Button
                          data-testid="liftSuspensionButton"
                          portal="mno"
                          shape="rounded"
                          variant="standard"
                          color="secondary"
                          onClick={(event) => {
                            // If we do not add stop Propagation and prevent default. It would close the modal.
                            event.stopPropagation();
                            event.preventDefault();
                            this.toggleModal(true, 'lift-suspension');
                          }}
                        >
                          <FontAwesomeIcon icon={faRotateRight} />
                          <span>Lift Suspension</span>
                        </Button>
                      </li>
                    </>
                  ) : campaign.operationStatus === 'REJECTED' ? (
                    <>
                      <li>
                        <Button
                          data-testid="fileAComplaintButton"
                          portal="mno"
                          shape="rounded"
                          color="primary"
                          onClick={(event) => {
                            // If we do not add stop Propagation and prevent default. It would close the modal.
                            event.stopPropagation();
                            event.preventDefault();
                            this.toggleModal(true, 'report');
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faSquareExclamation}
                            size="lg"
                          />
                          <span>File a Complaint</span>
                        </Button>
                      </li>
                      <li>
                        <Button
                          data-testid="approveCampaignButton"
                          portal="mno"
                          disabled={statusLoader}
                          shape="rounded"
                          variant="standard"
                          color="secondary"
                          onClick={(event) => {
                            // If we do not add stop Propagation and prevent default. It would close the modal.
                            event.stopPropagation();
                            event.preventDefault();
                            this.handleUpdateCampaignStatus('APPROVED');
                          }}
                        >
                          <Box>
                            <FontAwesomeIcon icon={faCheck} />
                          </Box>
                          <span>Approve Campaign</span>
                        </Button>
                      </li>
                    </>
                  ) : null}
                </ul>
              </Grid>
            </Grid>
            <Grid container className="campaign-details basic">
              <Grid item xs={12} data-testid="campaignDetailUid">
                <div className="title">
                  <h3 className="heading1">Campaign ID: {campaign.uid}</h3>
                </div>
              </Grid>
              <Grid item xs={12}>
                <CampaignDetailsBasic campaign={campaign} />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <CampaignCarrierStatus campaign={campaign} />
              </Grid>
              <Grid item xs={12}>
                <SharingChain
                  carrier={carrier}
                  nodes={this.state.sharingNodes}
                />
              </Grid>
              <Grid item xs={12}>
                <NumbersProvisioned campaignUid={campaign.uid} />
              </Grid>
              <Grid item xs={12}>
                <div className="csp-brand-reseller details">
                  <SectionTitle icon={faCircleUser}>CSP Details</SectionTitle>
                  <CampaignCspDetails
                    cspInfo={campaign?.brand ? campaign?.brand?.csp : {}}
                  />
                  <SectionTitle
                    icon={faTag}
                    iconSize="lg"
                    className="details-heading brand"
                  >
                    Brand Details
                  </SectionTitle>
                  <CampaignBrandDetails brandInfo={campaign?.brand} />

                  <SectionTitle
                    icon={faArrowsTurnRight}
                    iconSize="lg"
                    className="details-heading reseller"
                  >
                    Reseller Details
                  </SectionTitle>
                  <CampaignResellerDetails
                    resellerInfo={campaign?.reseller ? campaign?.reseller : {}}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="details">
                  <SectionTitle icon={faSquarePollHorizontal}>
                    Campaign Details
                  </SectionTitle>
                  <Grid container style={{ marginBottom: '12px' }}>
                    <Grid
                      xs={7}
                      container
                      direction="row"
                      alignItems="flex-start"
                      data-testid="campaignDetailsDescription"
                    >
                      <div className={`${classes.label} paragraph`}>
                        Campaign Description:
                      </div>
                      <div className={`${classes.value} paragraph`}>
                        {this.withDefaultValue(campaign.description)}
                      </div>
                    </Grid>
                    <Grid
                      xs={5}
                      container
                      direction="row"
                      alignItems="flex-start"
                      data-testid="campaignDetailsPrivacyPolicyLink"
                    >
                      <div className={`${classes.label} paragraph`}>
                        Privacy Policy Link:
                      </div>
                      <div className={`${classes.value} paragraph`}>
                        {this.withDefaultValue(campaign.privacyPolicyLink)}
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid
                      xs={7}
                      container
                      direction="row"
                      alignItems="flex-start"
                      data-testid="campaignDetailsMessageFlow"
                    >
                      <div className={`${classes.label} paragraph`}>
                        Call-to-Action / Message Flow:
                      </div>
                      <div className={`${classes.value} paragraph`}>
                        {this.withDefaultValue(campaign.messageFlow)}
                      </div>
                    </Grid>
                    <Grid
                      xs={5}
                      container
                      direction="row"
                      alignItems="flex-start"
                      data-testid="campaignDetailsTocLink"
                    >
                      <div className={`${classes.label} paragraph`}>
                        Terms and Conditions Link:
                      </div>
                      <div className={`${classes.value} paragraph`}>
                        {this.withDefaultValue(campaign.termsAndConditionsLink)}
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
              <Grid item xs={12}>
                <MultimediaList
                  sectionTitle="CTA (Call-to-Action), Privacy Policy and/or Terms and Conditions
            Multimedia Upload"
                  subText="Supporting information for opt in, call-to-action, terms and conditions, privacy policy, etc. Not intended for MMS sample messages."
                  listTitle="Multimedia Files"
                  campaignId={campaign.uid}
                  data-testid="CtaMultimediaList"
                  folder={ATTACHMENT_FOLDER.SUPPORTING_DOC}
                />
              </Grid>
              <Grid item xs={12}>
                <div
                  className="sample-messages details"
                  data-testid="campaignDetailsSampleMessages"
                >
                  <SectionTitle icon={faMessageDots} iconSize="lg">
                    Sample Messages
                  </SectionTitle>
                  {Object.keys(campaign).map((k, index) => {
                    if (k.includes('sample')) {
                      return (
                        campaign[k] && (
                          <SampleMessageView
                            key={index}
                            index={k.slice(-1)}
                            message={campaign[k]}
                          />
                        )
                      );
                    }
                  })}
                </div>
              </Grid>
              <Grid item xs={12}>
                <MultimediaList
                  sectionTitle="Sample Multimedia"
                  listTitle="Sample Multimedia Files"
                  data-testid="campaignSampleMultimedia"
                  campaignId={campaign.uid}
                  folder={ATTACHMENT_FOLDER.MMS}
                />
              </Grid>
              <Grid item xs={12}>
                <BoxV2
                  className="attributes details"
                  data-testid="campaignDetailCampaignAndContentAttributes"
                >
                  <SectionTitle icon={faSquarePollHorizontal}>
                    Campaign and Content Attributes
                  </SectionTitle>
                  <CampaignAttributes.ReadonlyPanel
                    data={{
                      ...campaign,
                      optinKeywords: campaign.optinKeywords?.split(',') || [],
                      optoutKeywords: campaign.optoutKeywords?.split(',') || [],
                      helpKeywords: campaign.helpKeywords?.split(',') || [],
                    }}
                  />
                </BoxV2>
              </Grid>
              <Grid item xs={12}>
                <div className="campaign-events details">
                  <SectionTitle icon={faCalendarRange}>Events</SectionTitle>
                  <Grid container>
                    <Table
                      disableHover
                      headRows={headRows}
                      emptyState="no events to view"
                      records={{ total: eventInfo.totalRecords }}
                      loading={tableLoader}
                      tableData={eventInfo.records.map((record, index) => {
                        return <EventsListingRow key={index} event={record} />;
                      })}
                      handleChangePage={this.handleChangePage}
                      createSortHandler={this.createSortHandler}
                      pagination={{
                        count: Math.ceil(
                          eventInfo.totalRecords / eventInfo.recordsPerPage
                        ),
                        rowsPerPage: eventInfo.recordsPerPage,
                        page: eventInfo.page,
                        totalRecords: eventInfo.totalRecords,
                      }}
                    />
                  </Grid>
                </div>
              </Grid>
            </Grid>
          </Container>
        )}
        <CampaignActionModal
          open={modalOpen}
          handleClose={() => this.toggleModal(false)}
          handleOperationStatus={this.updateOperationStatus}
          type={type}
          campaign={campaign}
        />
      </section>
    );
  }
}

export default withStyles(useStyles)(CampaignDetail);
