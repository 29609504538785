import {
  globalPostService,
  globalGetService,
} from '../../utils/globalApiServices';
export const forgotPasswordApi = (data) => {
  return globalPostService('/user/password-reset/request', data);
};
export const resetPasswordApi = (data) => {
  return globalPostService('/user/password-reset', data);
};
export const getUserFromTokenApi = (queryParams) => {
  return globalGetService('/user/password-reset/find-user', queryParams);
};
