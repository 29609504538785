import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TableCell, TableRow } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { S3_ASSETS_PATH } from '../../../../constants';
import {
  US_DATE_FORMAT,
  convertTimeWithTimezone,
} from '../../../../utils/time';

import { Box } from 'portal-commons';
import { getStatusIcon } from '../../../../utils/statusIcon';

const useStyles = makeStyles(() => ({
  horizontalCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '& img': {
      marginRight: '3px',
    },
  },
}));

function MatchedCampaignRow(props) {
  const { data } = props;
  const classes = useStyles();
  return (
    <TableRow
      data-testid="matchedCampaignRow"
      className="link"
      onClick={() =>
        props.history.push({
          pathname: `/campaigns/${data.uid}`,
          state: {
            goBackPage: `${props.location.pathname}${
              props.location.search ? props.location.search : ''
            }`,
          },
        })
      }
    >
      <TableCell
        align="left"
        style={{ minWidth: 125, overflowWrap: 'break-word' }}
        data-testid="matchedCampaignRowId"
      >
        <span>{data.uid ? data.uid : data.id}</span>
      </TableCell>
      <TableCell
        align="left"
        style={{ minWidth: 125, overflowWrap: 'break-word' }}
        data-testid="matchedCampaignRowBrandId"
      >
        <span>{data.brandUid ? data.brandUid : ''}</span>
      </TableCell>
      <TableCell
        align="left"
        style={{ minWidth: 125, overflowWrap: 'break-word', maxWidth: 220 }}
        data-testid="matchedCampaignRowBrandName"
      >
        <span>{data.brandName ? data.brandName : ''}</span>
      </TableCell>
      <TableCell
        align="left"
        style={{ minWidth: 125, overflowWrap: 'break-word', maxWidth: 220 }}
        data-testid="matchedCampaignRowCspDisplayName"
      >
        <span>
          {data.brand && data.brand.csp && data.brand.csp.displayName
            ? data.brand.csp.displayName
            : ''}
        </span>
      </TableCell>
      <TableCell
        align="left"
        style={{ minWidth: 105, overflowWrap: 'break-word' }}
        data-testid="matchedCampaignRowUsecase"
      >
        {data.usecase}
      </TableCell>
      <TableCell
        align="left"
        style={{ minWidth: 135, overflowWrap: 'break-word' }}
        data-testid="matchedCampaignRowCreateDate"
      >
        {convertTimeWithTimezone(data.createDate, undefined, US_DATE_FORMAT)}
      </TableCell>
      <TableCell
        align="left"
        style={{ minWidth: 140, overflowWrap: 'break-word' }}
        data-testid="matchedCampaignRowOperationStatus"
      >
        <div className={classes.horizontalCenter}>
          {data.operationStatus ? (
            <React.Fragment>
              <Box flexDirection="row" alignItems="center">
                <Box margin={{ right: 'xs' }}>
                  {getStatusIcon(data.operationStatus.toLowerCase())}
                </Box>
                <span>{data.operationStatus}</span>
              </Box>
            </React.Fragment>
          ) : (
            <span>N/A</span>
          )}
        </div>
      </TableCell>
    </TableRow>
  );
}
MatchedCampaignRow.propTypes = {
  data: PropTypes.object,
};
export default withRouter(MatchedCampaignRow);
