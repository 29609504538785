import {
  BoxV2 as Box,
  FilterConfig,
  FilterType,
  Flex,
  Table,
  TableFilter,
  withFilters,
  WithFiltersConfig,
} from 'portal-commons';
import queryString from 'query-string';
import { FunctionComponent, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { findPhoneHistory } from '../apis';
import RecordListingRow from '../components/RecordListingRow';
import { DateRangePresets } from '../constants';
import { PhoneHistoryRecord } from '../types';
import { convertDateFormatForApi } from '../utils';
import { FilterToggleButton, Loader } from '../../../../shared_elements';

import '../assets/phone-history.scss';

const FilterConfigs: Record<string, FilterConfig> = {
  campaignUid: {
    type: FilterType.Text,
    label: 'Campaign ID',
    width: 164,
  },
  nnid: {
    type: FilterType.Text,
    label: 'NNID',
    width: 135,
  },
  current: {
    type: FilterType.Dropdown,
    label: 'Campaign Currently Associated',
    options: [
      {
        label: 'Yes',
        value: 'true',
      },
      {
        label: 'No',
        value: 'false',
      },
    ],
    width: 285,
  },
  provisionedRange: {
    type: FilterType.DateRange,
    label: 'Campaign Associated Range',
    placeholder: 'Select Date Range',
    dateRangePresets: DateRangePresets,
    width: 280,
  },
};

const DefaultAdditionalFilters = {
  sortField: 'startTimestamp',
  ascendingOrder: false,
};

const TableHeads = [
  { id: 'campaignUid', label: 'CAMPAIGN ID', sortable: false },
  { id: 'nnid', label: 'NNID', sortable: false },
  { id: 'current', label: 'CAMPAIGN CURRENTLY ASSOCIATED', sortable: false },
  { id: 'onMyNetwork', label: 'ON MY NETWORK', sortable: false },
  { id: 'startTimestamp', label: 'PROVISIONED ON', sortable: true },
  { id: 'endTimestamp', label: 'DEPROVISIONED ON', sortable: false },
];

interface PhoneHistoryProps extends WithFiltersConfig {
  filter: {
    configs: Record<string, FilterConfig>;
    candidateFilters: Record<string, string>;
    appliedFilters: Record<string, string>;
    handleEdit: (value: Record<string, string>) => void;
    handleApply: (value: Record<string, string>) => void;
  };
}

interface PhoneHistoryParams {
  phone: string;
}

const PhoneHistory: FunctionComponent<PhoneHistoryProps> = ({ filter }) => {
  const history = useHistory();
  const location = useLocation();
  const { phone } = useParams<PhoneHistoryParams>();
  const [additionalFilters, setAdditionalFilters] = useState<
    Record<string, any>
  >(DefaultAdditionalFilters);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [records, setRecords] = useState<PhoneHistoryRecord[]>([]);
  const [page, setPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);

  const parseQueries = () => {
    const queries = queryString.parse(location.search, { decode: true });
    let localFilters = additionalFilters;
    if (!Object.keys(queries).length) {
      localFilters = DefaultAdditionalFilters;
    } else {
      if (queries.sortField) {
        localFilters.sortField = queries.sortField as string;
      }
      if (queries.ascendingOrder) {
        localFilters.ascendingOrder = JSON.parse(
          queries.ascendingOrder as string
        ) as boolean;
      }
    }
    setAdditionalFilters(localFilters);
    return {
      ...localFilters,
      ...filter.appliedFilters,
      page: queries.page ?? 1,
    } as Record<string, any>;
  };

  const writeQueries = (queries: Record<string, any> = {}) => {
    history.push({
      search: `?${queryString.stringify(queries)}`,
    });
  };

  const fetchPhoneHistory = async () => {
    setIsTableLoading(true);
    const queries = parseQueries();
    if (queries.provisionedRange) {
      const dates = queries.provisionedRange.split(' - ');
      if (dates.length === 2) {
        queries.startDate = convertDateFormatForApi(dates[0]);
        queries.endDate = convertDateFormatForApi(dates[1]);
      }
    }
    const response = await findPhoneHistory(phone, queries);
    if (response) {
      setIsPageLoading(false);
      const { totalRecords, records, page, recordsPerPage } = response;
      if (records.length === 0 && totalRecords > 0 && page > 1) {
        setIsPageLoading(true);
        const lastPage = Math.ceil(totalRecords / recordsPerPage);
        writeQueries({
          ...queries,
          page: lastPage,
        });
      } else {
        setTotalRecords(totalRecords);
        setRecords(records);
        setPage(page);
        setRecordsPerPage(recordsPerPage);
      }
    }
    setIsTableLoading(false);
  };

  const toggleFilterVisibility = () => {
    setIsFilterVisible(!isFilterVisible);
  };

  const handleSort = (field: string) => {
    if (totalRecords > 0) {
      writeQueries({
        ...additionalFilters,
        ...filter.appliedFilters,
        page,
        sortField: field,
        ascendingOrder: !additionalFilters.ascendingOrder,
      });
    }
  };

  const handlePageChange = (page: number) => {
    writeQueries({
      ...additionalFilters,
      ...filter.appliedFilters,
      page,
    });
  };

  useEffect(() => {
    fetchPhoneHistory();
  }, [location.search]);

  return (
    <section className="phone-history-section" style={{ width: '100%' }}>
      {isPageLoading ? (
        <Loader />
      ) : (
        <Box
          className="phone-history-container"
          sx={{
            padding: '21px 18px 0',
          }}
          data-testid="phoneHistory"
        >
          <Box
            sx={{
              marginBottom: '7px',
              marginLeft: '5px',
              color: '#333E41',
              '& h2': {
                fontWeight: 600,
                fontSize: '27px',
                lineHeight: '32px',
              },
            }}
          >
            <div className="title-block">
              <h2>{phone} – Phone Number History</h2>
            </div>
          </Box>
          <Flex sx={{ flexDirection: 'column', rowGap: '12px' }}>
            <Box>
              <FilterToggleButton
                visible={isFilterVisible}
                onClick={toggleFilterVisibility}
              />
            </Box>
            {isFilterVisible && (
              <TableFilter
                portal="mno"
                className="filters-container"
                configs={filter.configs}
                candidateValues={filter.candidateFilters}
                appliedValues={filter.appliedFilters}
                onCandidateValuesChange={filter.handleEdit}
                onAppliedValuesChange={filter.handleApply}
                data-testid="phoneHistory"
              />
            )}
          </Flex>
          <Box sx={{ marginTop: '14px' }}>
            <Table
              testId="phoneHistoryTable"
              emptyState="no history to view"
              loading={isTableLoading}
              records={{ total: totalRecords }}
              headRows={TableHeads}
              data={records}
              renderRow={(data) => (
                <RecordListingRow
                  key={`${data.campaignUid}-${data.startTimestamp}-${data.endTimestamp}`}
                  campaignUid={data.campaignUid as string}
                  nnid={data.nnid as string}
                  current={data.current as boolean}
                  onMyNetwork={data.onMyNetwork as boolean}
                  startTimestamp={data.startTimestamp as number}
                  endTimestamp={data.endTimestamp as number}
                />
              )}
              filter={{
                sortField: additionalFilters.sortField,
                ascendingOrder: additionalFilters.ascendingOrder,
              }}
              pagination={{
                totalRecords,
                page,
                rowsPerPage: recordsPerPage,
                count: Math.ceil(totalRecords / recordsPerPage),
              }}
              createSortHandler={handleSort}
              handleChangePage={handlePageChange}
            />
          </Box>
        </Box>
      )}
    </section>
  );
};

export default withFilters(PhoneHistory, {
  configs: FilterConfigs,
});
