import { FC, useEffect } from 'react';
import { faCircleUser } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Controller, useForm } from 'react-hook-form';

import { Flex, Button, TextInput } from 'portal-commons';

import Text from './Text';
import Modal from './Modal';
import {
  isRequired,
  isValidEmail,
  isValidName,
  createMaxLengthValidator,
} from '../validators';

interface UserCreateModalProps {
  open: boolean;
  disabled: boolean;
  onClose: () => void;
  onSubmit: (data: any) => void;
  defaultValues?: {
    firstName: string;
    lastName: string;
    email: string;
  };
}

const MAX_LENGTH = {
  firstName: 50,
  lastName: 50,
  email: 100,
};

const UserCreateModal: FC<UserCreateModalProps> = ({
  open,
  disabled,
  onClose,
  onSubmit,
  defaultValues,
}) => {
  const { handleSubmit, control, reset } = useForm<any>({
    defaultValues: defaultValues ?? {
      firstName: '',
      lastName: '',
      email: '',
    },
  });

  useEffect(() => {
    if (open) {
      reset();
    }
  }, [open]);

  return (
    <Modal open={open} data-testid="userCreateModal">
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Flex
          sx={{
            flexDirection: 'column',
            rowGap: '8px',
            color: '#333E41',
            fontFamily: 'Roboto',
          }}
        >
          <Flex
            sx={{
              flexDirection: 'row',
              justifyContent: 'center',
              columnGap: '12px',
              padding: '12px 0',
              fontWeight: 600,
              fontSize: '24px',
              lineHeight: '28px',
            }}
          >
            <FontAwesomeIcon icon={faCircleUser} />
            <Text variant="h3" sx={{ fontSize: '24px', color: 't.black80' }}>
              New User
            </Text>
          </Flex>
          <Text variant="caption">* Indicates a Required Field</Text>
          <Flex sx={{ flexDirection: 'column', rowGap: '16px' }}>
            <Controller
              name="firstName"
              control={control}
              rules={{
                validate: {
                  isRequired,
                  maxLength: createMaxLengthValidator(MAX_LENGTH.firstName),
                  isValidName,
                },
              }}
              render={({
                field: { value, onChange, onBlur },
                fieldState: { error = {} },
              }) => (
                <TextInput
                  required
                  disabled={disabled}
                  error={!!error.message}
                  label="First Name"
                  note={error.message}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  maxLength={MAX_LENGTH.firstName}
                  data-testid="firstName"
                />
              )}
            />
            <Controller
              name="lastName"
              control={control}
              rules={{
                validate: {
                  isRequired,
                  maxLength: createMaxLengthValidator(MAX_LENGTH.lastName),
                  isValidName,
                },
              }}
              render={({
                field: { value, onChange, onBlur },
                fieldState: { error = {} },
              }) => (
                <TextInput
                  required
                  disabled={disabled}
                  error={!!error.message}
                  label="Last Name"
                  note={error.message}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  maxLength={MAX_LENGTH.lastName}
                  data-testid="lastName"
                />
              )}
            />
            <Controller
              name="email"
              control={control}
              rules={{
                validate: {
                  isRequired,
                  maxLength: createMaxLengthValidator(MAX_LENGTH.email),
                  isValidEmail,
                },
              }}
              render={({
                field: { value, onChange, onBlur },
                fieldState: { error = {} },
              }) => (
                <TextInput
                  required
                  disabled={disabled}
                  error={!!error.message}
                  label="Email"
                  note={error.message}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  maxLength={MAX_LENGTH.email}
                  data-testid="email"
                />
              )}
            />
          </Flex>
          <Flex
            sx={{
              flexDirection: 'row',
              justifyContent: 'center',
              columnGap: '20px',
              padding: '24px 0 40px',
            }}
          >
            <Button
              variant="outline"
              onClick={onClose}
              data-testid="cancelButton"
            >
              Cancel
            </Button>
            <Button type="submit" disabled={disabled} data-testid="saveButton">
              Save
            </Button>
          </Flex>
        </Flex>
      </form>
    </Modal>
  );
};

export default UserCreateModal;
