import React, { useState } from 'react';
import { Modal, Backdrop, Fade, TextField } from '@material-ui/core';
import { S3_ASSETS_PATH } from '../../../../constants';
import { isValidInput } from '../../../../utils';
import { Button, Box } from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquareMinus } from '@fortawesome/pro-regular-svg-icons';
export default function CampaignBulkRejectModal(props) {
  const [error, setError] = useState('');
  const [explanation, setExplanation] = useState('');

  const handleClose = () => {
    props.toggleModal(false);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (explanation && explanation.trim()) {
      if (isValidInput(explanation)) {
        props.handleUpdateStatus('REJECTED', explanation);
      } else {
        setError('Invalid input');
      }
    } else {
      setError('Please enter Details');
    }
  };
  const handleError = (value) => {
    if (value.trim()) {
      setError('');
    } else {
      setError('Please enter Details');
    }
  };
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={'campaign-action-form reject'}
        open={true}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        disableBackdropClick
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={true}>
          <form className="modal-content">
            <div className="title">
              <h3 className="heading2">
                <Box flexDirection="row" justifyContent="center">
                  <Box margin={{ right: 'xs' }}>
                    <FontAwesomeIcon icon={faSquareMinus} size="lg" />
                  </Box>
                  Reject Campaign
                </Box>
              </h3>
            </div>
            <p className="text-center count">
              {`${props.count} ${props.count > 1 ? 'Campaigns' : 'Campaign'}`}{' '}
              Selected
            </p>
            <div className="form-group-field">
              <TextField
                value={explanation ? explanation : ' '}
                multiline
                maxLength="2"
                rowsMax="4"
                id="details"
                margin="normal"
                label="Details"
                required
                autoComplete="off"
                autoFocus
                error={error ? true : false}
                inputProps={{ autoFocus: true }}
                fullWidth={true}
                name="details"
                inputProps={{ 'aria-label': 'campaign Id', maxLength: 3000 }}
                onChange={(e) => {
                  setExplanation(e.target.value);
                  setError('');
                }}
                onBlur={(e) => handleError(e.target.value)}
              />
              {error ? <h6 className="error-msg">{error}</h6> : null}
            </div>
            <p className="text-center notice">
              {' '}
              The same rejection details will be applied to all selected items!
            </p>
            <ul className="modal-footer list-inline text-center">
              <li className="p-r-s">
                <Button
                  data-testid="CancelBulkRejectButton"
                  type="submit"
                  portal="mno"
                  variant="outline"
                  shape="rounded"
                  color="primary"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </li>
              <li>
                <Button
                  data-testid="ApplyBulkRejectButton"
                  disabled={props.loader}
                  type="submit"
                  portal="mno"
                  shape="rounded"
                  color="primary"
                  onClick={handleSubmit}
                >
                  Reject
                </Button>
              </li>
            </ul>
          </form>
        </Fade>
      </Modal>
    </div>
  );
}
