import React from 'react';
import { Tooltip } from '@material-ui/core';
import { S3_ASSETS_PATH } from '../../constants';
import { withStyles } from '@material-ui/core/styles';
import { Box } from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo } from '@fortawesome/pro-regular-svg-icons';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    background: ' #00698F',
    color: '#fff',
    fontSize: 12,
    borderRadius: '20px 20px 20px 0px',
    maxWidth: 175,
    fontFamily: 'Roboto',
    padding: '10px 20px',
    marginTop: 40,
    fontWeight: 400,
  },
}))(Tooltip);
const ToolTip = (props) => {
  return (
    <LightTooltip title={props.title} placement="right-end">
      <div style={{ cursor: 'pointer' }}>
        <Box margin={{ left: 'xs' }}>
          <FontAwesomeIcon icon={faInfo} style={{ color: '00698F' }} />
        </Box>
      </div>
    </LightTooltip>
  );
};
export default ToolTip;
