import React from 'react';
import { CspDetailItem } from '../../Csp/components';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

const CampaignCspDetails = (props) => {
  const { cspInfo } = props;
  return (
    <Grid container>
      <Grid item xs={6}>
        <CspDetailItem
          title="Legal Company Name "
          value={cspInfo.companyName}
        />
        <CspDetailItem title="Email Address" value={cspInfo.email} />
      </Grid>
      <Grid item xs={6}>
        <CspDetailItem title="CSP ID" value={cspInfo.uid} />
        <CspDetailItem title="Phone Number" value={cspInfo.phone} />
      </Grid>
    </Grid>
  );
};

CampaignCspDetails.propTypes = {
  cspInfo: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
  }).isRequired,
};

export default CampaignCspDetails;
