import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { remapTCRStatus } from '../utils';
import { getStatusIcon } from '../../../../utils/statusIcon';
import { Box, CustomCheckbox } from 'portal-commons';

function CampaignsListingRow(props) {
  const { campaign, selectable, selected, onSelectedChange } = props;

  return (
    <TableRow
      data-testid="campaignsListingRow"
      className="link"
      onClick={() =>
        props.history.push({
          pathname: `/campaigns/${campaign.uid}`,
          state: {
            goBackPage: `${props.location.pathname}${
              props.location.search ? props.location.search : ''
            }`,
          },
        })
      }
    >
      {selectable ? (
        <td
          onClick={(e) => e.stopPropagation()}
          className="custom-tablecell"
          data-testid="campaignsListingRowSelectUid"
        >
          <CustomCheckbox
            checked={selected}
            onChange={(e) => {
              e.stopPropagation();
              onSelectedChange(e.target.checked);
            }}
          />
        </td>
      ) : null}
      <TableCell align="left" data-testid="campaignsListingRowUid">
        <span>{campaign.uid ? campaign.uid : campaign.id}</span>
      </TableCell>
      <TableCell align="left" data-testid="campaignsListingRowBrandUid">
        {campaign.brandUid}
      </TableCell>
      <TableCell
        align="left"
        style={{ minWidth: 130, maxWidth: 200, overflowWrap: 'break-word' }}
        data-testid="campaignsListingRowBrandName"
      >
        {campaign.usecase === 'SOLE_PROPRIETOR'
          ? 'Redacted due to PII'
          : campaign.brandName || 'N/A'}
      </TableCell>
      <TableCell
        align="left"
        style={{ minWidth: 130, maxWidth: 200, overflowWrap: 'break-word' }}
        data-testid="campaignsListingRowCspName"
      >
        {campaign.cspName ? campaign.cspName : '--'}
      </TableCell>
      <TableCell
        align="left"
        style={{ minWidth: 125 }}
        data-testid="campaignsListingRowUsecase"
      >
        {campaign.usecase}
      </TableCell>
      <TableCell align="left" data-testid="campaignsListingRowCreateDate">
        {campaign.createDate}
      </TableCell>
      <TableCell align="left" data-testid="campaignsListingRowResellerID">
        {campaign.resellerUid || 'N/A'}
      </TableCell>
      <TableCell align="left" data-testid="campaignsListingRowStatus">
        {remapTCRStatus(campaign.status)}
      </TableCell>
      <TableCell align="left" data-testid="campaignsListingRowMnoStatus">
        <div className="mno-operational-status">
          {campaign.mnoStatus ? (
            <Box flexDirection="row" alignItems="center">
              <Box margin={{ right: 'xs' }}>
                {getStatusIcon(campaign.mnoStatus.toLowerCase())}
              </Box>
              <span>
                {campaign.mnoStatus.toLowerCase() === 'approved'
                  ? 'REGISTERED'
                  : campaign.mnoStatus}
              </span>
            </Box>
          ) : (
            <span>N/A</span>
          )}
        </div>
      </TableCell>
    </TableRow>
  );
}

export default withRouter(CampaignsListingRow);
