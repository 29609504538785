import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Menu, MenuItem, Fade } from '@material-ui/core';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';

import { MIGRATION_STATUS_LABEL_MAP } from '../constants';
import { getCampaignMigrationInfo } from '../apiServices';

export default function CampaignDetailsBasic(props) {
  const { campaign } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [migrationInfo, setMigrationInfo] = useState();

  const handleClick = (e) => {
    setOpen(true);
    setAnchorEl(e.currentTarget);
  };
  const handleRequestClose = (e) => {
    setOpen(false);
  };

  useEffect(() => {
    fetchMigrationInfo(campaign.uid);
  }, [campaign?.uid]);

  const fetchMigrationInfo = async (id) => {
    // call the api silently, due to no migration record would returns 400 error
    const info = await getCampaignMigrationInfo(id, { silent: true });
    if (info) {
      setMigrationInfo(info);
    }
  };

  const getMigrationStatus = () => {
    const { status = 'No' } = migrationInfo ?? {};
    return MIGRATION_STATUS_LABEL_MAP[status] ?? status;
  };

  return (
    <ul className="list-inline basic" data-testid="campaignDetailBasic">
      <div className="w-33">
        <div className="column">
          <li data-testid="campaignDetailBasicCreateDate">
            <h3 className="heading2">
              <span>Registered on:</span>
              {campaign.createDate}
            </h3>
          </li>
        </div>
      </div>
      <div className="w-33 border-left">
        <div className="column">
          <li>
            {campaign.subUsecases ? (
              <div className="campaign-usecase-wrapper">
                <h3
                  className="heading2"
                  aria-owns={open ? 'simple-menu' : null}
                  aria-haspopup="true"
                  // onMouseOver={handleClick}
                  data-testid="campaignDetailBasicUsecase"
                >
                  <span>Use-Case:</span>
                  <span onClick={handleClick} className="val">
                    {campaign.usecase}
                    <ExpandMoreOutlinedIcon
                      className={`dropdown-arrow ${open ? 'open' : ''}`}
                    />
                  </span>
                </h3>
                <Menu
                  data-testid="campaignDetailBasicMenu"
                  id="simple-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleRequestClose}
                  className="campaign-subusecases-menu"
                  MenuListProps={
                    {
                      // onMouseLeave: handleRequestClose
                    }
                  }
                  TransitionComponent={Fade}
                >
                  {campaign.subUsecases.split(',').map((sub, index) => (
                    <MenuItem
                      key={index}
                      onClick={handleRequestClose}
                      data-testid={`campaignDetailBasic-${sub.replace(
                        /\s/g,
                        ''
                      )}`}
                    >
                      {sub}
                    </MenuItem>
                  ))}
                </Menu>
              </div>
            ) : (
              <h3 className="heading2" data-testid="campaignDetailBasicUsecase">
                <span>Use-Case:</span>
                {campaign.usecase}
              </h3>
            )}
          </li>
        </div>
      </div>
      <div className="w-33 border-left">
        <div className="column">
          <li data-testid="campaignDetailBasicMigration">
            <h3 className="heading2">
              <span>Migration:</span>
              <span className="val secondary-color">
                {getMigrationStatus()}
              </span>
            </h3>
          </li>
        </div>
      </div>
    </ul>
  );
}
CampaignDetailsBasic.propTypes = {
  campaign: PropTypes.object.isRequired,
};
