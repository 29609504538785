import React, { Component } from 'react';
import { Grid, Container } from '@material-ui/core';
import { isEqual } from 'lodash';
import queryString from 'query-string';
import { globalGetService } from '../../../../utils/globalApiServices';
import { CspListingRow } from '../components';
import { getCspListApi } from '../apiServices';
import '../assets/csp-listing-module.scss';
import { Loader, FilterToggleButton } from '../../../../shared_elements';
import { Box, Table, FilterType, TableFilter } from 'portal-commons';
import { withFilters } from '../../../../hocs';

const FILTER_CONFIG = {
  displayName: {
    type: FilterType.Text,
    label: 'CSP Name',
    placeholder: 'Enter CSP Name',
    suggestions: [],
  },
  entityType: {
    type: FilterType.Dropdown,
    options: [
      { label: 'Publicly Traded Company', value: 'PUBLIC_PROFIT' },
      { label: 'Private Company', value: 'PRIVATE_PROFIT' },
      { label: 'Charity / Non-Profit Organization', value: 'NON_PROFIT' },
    ],
    label: 'Entity Type',
  },
};

const DEFAULT_ADDITIONAL_FILTERS = {
  sortField: 'displayName',
  ascendingOrder: false,
};

const headRows = [
  { id: 'displayName', label: 'CSP NAME', sortable: true },
  { id: 'country', label: 'COUNTRY OF REGISTRATION', sortable: true },
  { id: 'entityType', label: 'ENTITY TYPE', sortable: true },
  { id: 'activeCampaignCount', label: 'NO. OF CAMPAIGNS', sortable: false },
];

const fetchCspSuggestions = (query = {}) =>
  globalGetService('mno/csp/suggestions', { ...query, limit: 20 }).then(
    (response) => {
      if (response && response.status >= 200 && response.status < 300) {
        return response.data?.map((d) => d?.cspName) ?? [];
      }
      return [];
    }
  );

class CspListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cspInfo: {
        records: [],
        page: 1,
        recordsPerPage: 10,
        totalRecords: 0,
        displayTotal: 0,
      },
      tableLoader: true,
      loader: true,
      additionalFilters: DEFAULT_ADDITIONAL_FILTERS,
      isFilterVisible: false,
    };
  }

  parseSearchParams = (params = {}) => {
    let updatedFilter = this.state.additionalFilters;
    const { appliedFilters } = this.props.filter;
    const queries = queryString.parse(params, { decode: true });
    if (!Object.keys(queries).length) {
      updatedFilter = DEFAULT_ADDITIONAL_FILTERS;
    } else if (queries.sortField || queries.ascendingOrder) {
      updatedFilter = {
        sortField: queries.sortField,
        ascendingOrder: JSON.parse(queries.ascendingOrder),
      };
    }

    this.setState({ additionalFilters: updatedFilter });

    return {
      ...updatedFilter,
      ...appliedFilters,
      page: queries.page ? queries.page : 1,
    };
  };

  componentDidMount() {
    if (this.props.location.search) {
      const params = this.parseSearchParams(this.props.location.search);
      this.fetchCspList(params);
    } else {
      this.fetchCspList({ ...this.state.additionalFilters });
    }
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.location.search, this.props.location.search)) {
      const params = this.parseSearchParams(this.props.location.search);
      this.fetchCspList(params);
    }
  }

  fetchCspList = async (query = {}) => {
    this.setState({ tableLoader: true });
    let cspInfo = await getCspListApi(query);
    if (cspInfo) {
      cspInfo = {
        ...cspInfo,
        totalRecords: Math.min(cspInfo.totalRecords, 10000),
        // Cap total records as ElasticSearch returns up to 10,000 by default
        displayTotal: cspInfo.totalRecords,
        // But keep the original count for displaying on the top of campaign listing page
      };
      this.setState({ cspInfo, loader: false });
      if (
        cspInfo.records.length === 0 &&
        cspInfo.totalRecords > 0 &&
        cspInfo.page > 1
      ) {
        const lastPageNo = Math.ceil(cspInfo.totalRecords / 10);
        this.setState({ loader: true });
        this.writeQueryString({ ...query, page: lastPageNo });
      }
    }
    this.setState({ tableLoader: false });
  };

  handleChangePage = (newPage) => {
    const { additionalFilters } = this.state;
    const { appliedFilters } = this.props.filter;

    this.writeQueryString({
      ...additionalFilters,
      ...appliedFilters,
      page: newPage,
    });
  };

  handleSorting = (sortField) => {
    const { cspInfo, additionalFilters } = this.state;
    const { appliedFilters } = this.props.filter;
    this.setState((prevState) => ({
      ...prevState,
      additionalFilters: {
        ...prevState.additionalFilters,
        ascendingOrder: !prevState.additionalFilters.ascendingOrder,
        sortField,
      },
    }));

    if (cspInfo.totalRecords) {
      this.writeQueryString({
        ...additionalFilters,
        ...appliedFilters,
        page: cspInfo.page,
        sortField,
        ascendingOrder: !additionalFilters.ascendingOrder,
      });
    }
  };

  writeQueryString = (searchParams = {}) => {
    this.props.history.push({
      search: `?${queryString.stringify(searchParams, { encode: false })}`,
    });
  };

  toggleFilterVisibility = () => {
    this.setState((prev) => ({
      isFilterVisible: !prev.isFilterVisible,
    }));
  };

  render() {
    const { cspInfo, tableLoader, loader, additionalFilters, isFilterVisible } =
      this.state;

    const {
      configs: filterConfigs,
      appliedFilters,
      candidateFilters,
      handleEdit: handleCandidateFiltersChange,
      handleApply: handleAppliedFiltersChange,
    } = this.props.filter;

    return (
      <section className="csp-listing-section page-content">
        {loader ? (
          <Loader />
        ) : (
          <Container
            maxWidth={false}
            className="csp-listing-container"
            data-testid="cspListing"
          >
            <Box>
              <div className="title-block">
                <h2>{!loader ? `${cspInfo.displayTotal} CSPs` : 'CSPs'}</h2>
              </div>
            </Box>
            <Box flexDirection="column" margin={{ top: 's', bottom: 's' }}>
              <Box>
                <FilterToggleButton
                  visible={isFilterVisible}
                  onClick={this.toggleFilterVisibility}
                />
              </Box>
              {isFilterVisible && (
                <TableFilter
                  portal="mno"
                  configs={filterConfigs}
                  candidateValues={candidateFilters}
                  appliedValues={appliedFilters}
                  onCandidateValuesChange={handleCandidateFiltersChange}
                  onAppliedValuesChange={handleAppliedFiltersChange}
                  className="filters"
                  data-testid="csps"
                />
              )}
            </Box>
            <Grid container justifyContent="center" spacing={0}>
              <Table
                testId="cspTable"
                records={{ total: cspInfo.totalRecords }}
                loading={tableLoader}
                headRows={headRows}
                emptyState="no brands to view"
                data={cspInfo.records}
                rowKey="uid"
                renderRow={(data) => {
                  return <CspListingRow csp={data} key={data.uid} />;
                }}
                handleChangePage={this.handleChangePage}
                createSortHandler={this.handleSorting}
                filter={additionalFilters}
                pagination={{
                  count: Math.ceil(
                    cspInfo.totalRecords / cspInfo.recordsPerPage
                  ),
                  rowsPerPage: cspInfo.recordsPerPage,
                  page: cspInfo.page,
                  totalRecords: cspInfo.totalRecords,
                }}
              />
            </Grid>
          </Container>
        )}
      </section>
    );
  }
}

export default withFilters(CspListing, {
  configs: FILTER_CONFIG,
  loaders: {
    suggestion: {
      displayName: {
        minLength: 2,
        load: (value) => fetchCspSuggestions({ prefix: value }),
      },
    },
  },
});
