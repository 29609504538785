import * as actions from '../actions';
import { browserHistory } from 'react-router';
import produce from 'immer';

export default function (
  state = {
    enums: {
      suspensionCategories: [],
    },
    loadingMessage: '',
  },
  action
) {
  switch (action.type) {
    case actions.ADD_ENUM_SUSPENSION_CATEGORY:
      return {
        ...state,
        enums: {
          suspensionCategories: action.payload,
        },
      };

    case actions.SET_LOADING_MESSAGE:
      return {
        ...state,
        loadingMessage: action.payload,
      };
    case actions.CLEAR_LOADING_MESSAGE:
      return {
        ...state,
        loadingMessage: '',
      };

    default:
      return state;
  }
}
