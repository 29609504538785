import React from 'react';
import { FormHelperText, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { BoxV2 as Box } from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';

const useStyles = makeStyles({
  textFieldWrapper: {
    '& .MuiFormControl-root': {
      height: 40,
      minWidth: 280,
      width: '100%',
      '&.Mui-error': {
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
          color: '#AA0000',
          '& + .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
            borderColor: '#AA0000',
            borderWidth: 2,
          },
        },
        '& .MuiInputBase-root.MuiOutlinedInput-root': {
          borderColor: '#AA0000 !important',
        },
      },
    },
    '& .MuiFormLabel-root': {
      fontSize: '16px',
      fontFamily: 'Roboto',
      fontWeight: 'normal',
      paddingLeft: 20,
      color: '#19262A',
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(14px, 12px) scale(1)',
      '&.MuiInputLabel-shrink': {
        transform: 'translate(0, -6px) scale(0.75)',
        color: '#19262A',
      },
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      '& + .MuiOutlinedInput-root': {
        backgroundColor: 'transparent',
        borderRadius: 20,
        borderWidth: 0,
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#CACC4F',
          borderWidth: 2,
        },
      },
    },
    '& .MuiOutlinedInput-root': {
      height: '100%',
      borderRadius: 20,
      border: '2px solid #CACC4F',
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
      },
      '&.Mui-focused': {
        borderWidth: 0,
        backgroundColor: 'transparent',
      },
    },
    '& .MuiOutlinedInput-input': {
      padding: '8px 12px 8px 35px',
    },
    '& .MuiFormHelperText-root': {
      color: '#AA0000',
      marginTop: 0,
      marginLeft: 10,
      fontSize: 10,
      fontWeight: 400,
      height: 16,
      position: 'absolute',
      bottom: -16,
      left: 0,
    },
  },
  '@keyframes spin': {
    '0%': { left: '12px', top: '14px' },
    '12.5%': { left: '12.6px', top: '13.6px' },
    '25%': { left: '14px', top: '12px' },
    '37.5%': { left: '15.6px', top: '12.6px' },
    '50%': { left: '16px', top: '14px' },
    '62.5%': { left: '15.6px', top: '15.6px' },
    '75%': { left: '14px', top: '16px' },
    '87.5%': { left: '12.6px', top: '15.6px' },
    '100%': { left: '12px', top: '14px' },
  },
  searchIcon: {
    position: 'absolute',
    zIndex: 1,
    fontSize: '14px',
    left: 12,
    top: 13,
    color: '#19262A',
    cursor: 'pointer',
    transition: 'left 2s linear, top 2s linear',
    '&.searching': {
      animation: '$spin 2s linear infinite',
    },
  },
});

interface Props {
  label: string;
  value: string;
  error: string;
  searching?: boolean;
  onChange: (value: string) => void;
  onBlur: () => void;
  onSearchClick: () => void;
  [key: `data-${string}`]: unknown;
}

const SearchTextField: React.FC<Props> = ({
  label,
  value,
  error,
  searching,
  onChange,
  onBlur,
  onSearchClick,
  ...props
}) => {
  const classes = useStyles();
  const dataTestId = props['data-testid'] ?? 'searchTextField';
  const handleSubmit = (e: React.BaseSyntheticEvent) => {
    const event = e.nativeEvent;
    if (event instanceof KeyboardEvent && event.key !== 'Enter') {
      return;
    }
    onSearchClick();
  };

  return (
    <Box
      className={classes.textFieldWrapper}
      sx={{
        minWidth: 300,
        width: '100%',
        position: 'relative',
        mb: 8,
        float: 'right',
      }}
      data-testid={dataTestId}
    >
      <FontAwesomeIcon
        className={`${searching ? 'searching ' : ' '}${classes.searchIcon}`}
        icon={faMagnifyingGlass}
        onClick={handleSubmit}
        data-testid={`${dataTestId}-icon`}
      />
      <TextField
        className={error.length > 0 ? 'Mui-error' : ''}
        variant="outlined"
        label={label}
        value={value}
        inputProps={{
          'data-testid': `${dataTestId}-input`,
        }}
        onChange={(e) => onChange(e.target.value.trim())}
        onBlur={onBlur}
        onKeyUp={handleSubmit}
      />
      {error && (
        <FormHelperText className="error-msg" data-testid="helperText">
          {error}
        </FormHelperText>
      )}
    </Box>
  );
};

export default SearchTextField;
