import React, { useEffect, useState } from 'react';
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import { format } from 'date-fns';

export default function DateRangePicker(props) {
  const { dateRange, presets, className } = props;
  const [dateRangeValue, setDateRangeValue] = useState({
    start: undefined,
    end: undefined,
  });

  const changeDateFormat = (date) => {
    // yyyy-MM-dd -> MM/DD/YYYY
    const dateArray = date.split('-');
    return `${dateArray[1]}/${dateArray[2]}/${dateArray[0]}`;
  };

  useEffect(() => {
    if (dateRange && dateRange?.startDate && dateRange?.endDate) {
      setDateRangeValue({
        start: new Date(changeDateFormat(dateRange.startDate)),
        end: new Date(changeDateFormat(dateRange.endDate)),
      });
    } else {
      setDateRangeValue({
        start: undefined,
        end: undefined,
      });
    }
  }, [dateRange]);

  return (
    <div
      data-testid={props.testId ? props.testId : 'dateRangePicker'}
      className={className}
    >
      <DateRangePickerComponent
        enableRtl={true}
        delayUpdate
        id="daterangepicker"
        placeholder="Select Date Range"
        showClearButton={false}
        format="yyyy-MM-dd"
        presets={presets}
        cssClass="custom-daterange-picker"
        max={new Date()}
        strictMode={true}
        onChange={(e) => {
          setDateRangeValue({
            start: e.target.value.start,
            end: e.target.value.end,
          });
          const dateObj = {
            startDate: format(e.target.value.start, 'yyyy-MM-dd'),
            endDate: format(e.target.value.end, 'yyyy-MM-dd'),
          };
          props.handleChange(dateObj);
        }}
        allowEdit={false}
        value={{ start: dateRangeValue.start, end: dateRangeValue.end }}
        onApply={() => console.log('onApply clicked')}
      />
    </div>
  );
}
