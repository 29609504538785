import React, { useState } from 'react';
import { Button } from 'portal-commons';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  MenuProps,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import {
  CLEAR_LOADING_MESSAGE,
  SET_LOADING_MESSAGE,
} from '../../../../../shared_elements/actions';
import { S3_ASSETS_PATH } from '../../../../../constants';
import { downloadCampaignPhoneNumbers } from '../../apiServices';
import { Box } from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDownToLine } from '@fortawesome/pro-regular-svg-icons';

const useStyles = makeStyles({
  title: {
    textAlign: 'center',
    padding: '40px 30px 10px',
    '& img': {
      marginRight: '5px',
      position: 'relative',
      top: '4px',
      height: '20px',
    },
  },
  content: {
    margin: '10px 30px 30px',
    padding: '7px 0 10px !important',
    fontSize: '16px',
    lineHeight: 1.15,
    textAlign: 'center',
    width: '465px',
    '& .form-group-field': {
      boxSizing: 'border-box',
      '& .MuiSelect-nativeInput': {
        boxSizing: 'border-box',
      },
    },
  },
  paragraph: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: 1.2,
    textAlign: 'left',
    marginBottom: '15px',
    '& .hint': {
      margin: '15px 0',
      fontSize: '10px',
    },
  },
  formGroup: {
    marginBottom: '30px',
    textAlign: 'left',
    '& .MuiFormLabel-root': {
      fontSize: '14px',
    },
    '& .MuiInputBase-input': {
      fontSize: '14px',
    },
  },
  actionBlock: {
    justifyContent: 'center',
    padding: 0,
    '& button:not(:first-child)': {
      marginLeft: '20px',
    },
  },
});

interface Props {
  open: boolean;
  handleClose: () => void;
  campaignUid: string;
}

const DownloadPhoneNumbersModal: React.FC<Props> = ({
  open,
  handleClose,
  campaignUid,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const selectMenuPrpos: Partial<MenuProps> = {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    getContentAnchorEl: null,
  };
  const [downloadState, setDownloadState] = useState<string>('');

  const setLoadingMessage = (message: string) => {
    dispatch({
      type: SET_LOADING_MESSAGE,
      payload: message,
    });
  };

  const clearLoadingMessage = () => {
    dispatch({
      type: CLEAR_LOADING_MESSAGE,
      payload: '',
    });
  };

  const handleDownloadPhoneNumbers = async () => {
    handleCloseModal();
    setLoadingMessage('We are generating the document');
    const query = {
      current: downloadState === 'current',
    };
    const response = await downloadCampaignPhoneNumbers(campaignUid, query);
    const file = new File([response], 'phone-numbers.csv');
    const url = URL.createObjectURL(file);
    const element = document.createElement('a');
    element.href = url;
    element.download = file.name;
    document.body.appendChild(element);
    element.click();
    clearLoadingMessage();
  };

  const handleCloseModal = () => {
    setDownloadState('');
    handleClose();
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="Download Phone Numbers"
      data-testid="downloadPhoneNumbersModal"
    >
      <DialogTitle className={classes.title}>
        <Box flexDirection="row" justifyContent="center">
          <Box margin={{ right: 's' }}>
            <FontAwesomeIcon icon={faArrowDownToLine} />
          </Box>
          Download
        </Box>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <div className={classes.paragraph}>
          <p>
            To download a complete list of Current or Previous Phone Numbers
            please use the drop down below then click Download.
          </p>
          <p className="hint">* Indicates a Required Field</p>
        </div>
        <div className={`${classes.formGroup} form-group-field`}>
          <FormControl
            required
            style={{ width: '100%' }}
            data-testid="currentOrPreviousNumberField"
          >
            <InputLabel>Select Current of Previous Numbers</InputLabel>
            <Select
              MenuProps={selectMenuPrpos}
              value={downloadState}
              fullWidth={true}
              inputProps={{
                'data-testid': 'currentOrPreviousNumberSelect',
              }}
              onChange={(event) =>
                setDownloadState(event.target.value as string)
              }
            >
              <MenuItem key="current" value="current">
                Current Phone Numbers
              </MenuItem>
              <MenuItem key="previous" value="previous">
                Previous Phone Numbers
              </MenuItem>
            </Select>
          </FormControl>
        </div>
        <DialogActions className={classes.actionBlock}>
          <Button
            variant="outline"
            onClick={handleCloseModal}
            data-testid="downloadPhoneNumbersCancelButton"
          >
            Cancel
          </Button>
          <Button
            disabled={downloadState === ''}
            onClick={handleDownloadPhoneNumbers}
            data-testid="downloadPhoneNumberDownloadButton"
          >
            Download
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default DownloadPhoneNumbersModal;
