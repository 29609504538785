import { FC, useState, useEffect, ReactNode } from 'react';
import {
  MultimediaList as BaseMultimediaList,
  BoxV2 as Box,
  AttachmentPreview,
} from 'portal-commons';
import type { AttachmentInfo } from 'portal-commons';
import { Grid } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileImage } from '@fortawesome/pro-regular-svg-icons';

import {
  getCampaignAttachmentsApi,
  downloadAttachmentApi,
} from '../apiServices';

interface IMultimediaList {
  sectionTitle: ReactNode;
  listTitle: string;
  campaignId: string;
  'data-testid': string;
  folder: string;
  subText?: ReactNode;
}

const MultimediaList: FC<IMultimediaList> = ({
  campaignId,
  sectionTitle,
  listTitle,
  folder,
  subText,
  ...restProps
}) => {
  const [attachments, setAttachments] = useState<Array<AttachmentInfo>>([]);
  const [loading, setLoading] = useState(false);
  const [previewAttachment, setPreviewAttachment] =
    useState<AttachmentInfo | null>(null);

  const queryMultimediaList = async (campaignId: string) => {
    if (!campaignId) return;
    const data = await getCampaignAttachmentsApi(campaignId, folder);
    if (data) {
      setAttachments(data);
    }
  };

  const syncRemoteFile = async (
    index: number
  ): Promise<AttachmentInfo | undefined> => {
    const attachment = attachments[index];
    if (!attachment) return;

    let { file } = attachment;
    const { fileName, mimeType } = attachment;

    if (!file) {
      setLoading(true);
      const response = await downloadAttachmentApi(attachment.uuid);
      if (response) {
        file = new File([response], fileName, { type: mimeType });
      }
      setLoading(false);
    }

    const newAttachment = { ...attachment, file };
    setAttachments([
      ...attachments.slice(0, index),
      newAttachment,
      ...attachments.slice(index + 1),
    ]);

    return newAttachment || undefined;
  };

  const handleDownloadClick = async (index: number) => {
    const attachment = await syncRemoteFile(index);

    if (attachment?.file) {
      const url = URL.createObjectURL(attachment.file);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', attachment.fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handlePreviewClick = async (index: number) => {
    const attachment = await syncRemoteFile(index);

    if (attachment?.file) {
      setPreviewAttachment(attachment);
    }
  };

  useEffect(() => {
    queryMultimediaList(campaignId);

    return () => {
      setAttachments([]);
      setPreviewAttachment(null);
    };
  }, [campaignId]);

  return (
    <div
      className="sample-messages details"
      data-testid={restProps['data-testid']}
    >
      <div className="details-heading">
        <Box
          sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center ' }}
        >
          <Box sx={{ mr: 'xs', fontSize: '18px' }}>
            <FontAwesomeIcon icon={faFileImage} />
          </Box>
          <h3 className="heading2">{sectionTitle}</h3>
        </Box>
        {subText && (
          <Box
            sx={{
              fontWeight: 'normal',
              fontSize: 'H200',
              lineHeight: 'H300',
              mt: '4px',
            }}
          >
            {subText}
          </Box>
        )}
      </div>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <BaseMultimediaList
            data-testid="multimediaList"
            portal="mno"
            editable={false}
            attachments={attachments}
            title={listTitle}
            loading={loading}
            onSelect={handlePreviewClick}
            onDownload={handleDownloadClick}
          />
        </Grid>
      </Grid>

      {previewAttachment?.file && (
        <AttachmentPreview
          onClose={() => setPreviewAttachment(null)}
          file={previewAttachment.file}
          mimeType={previewAttachment.mimeType}
        />
      )}
    </div>
  );
};

export default MultimediaList;
