import { globalGetService } from '../../../utils/globalApiServices';
import { BrandsResponse, CspSuggestion } from './types';

export const fetchCspSuggestions = async (queries = {}) => {
  const response = await globalGetService('mno/csp/suggestions', {
    ...queries,
    limit: 20,
  });
  if (response.ok) {
    return response.data as CspSuggestion[];
  }
  return [] as CspSuggestion[];
};

export const fetchBrands = async (queries = {}) => {
  const response = await globalGetService('mno/brands', queries);
  if (response.ok) {
    return response.data as BrandsResponse;
  }
};
