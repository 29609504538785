import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import { ENTITY_TYPES } from '../../../../constants';

function CspListingRow(props) {
  const { csp } = props;
  return (
    <TableRow
      data-testid="cspListingRow"
      className="link"
      onClick={() =>
        props.history.push({
          pathname: `/csp/${csp.uid}`,
          state: {
            goBackPage: `${props.location.pathname}${
              props.location.search ? props.location.search : ''
            }`,
          },
        })
      }
    >
      <TableCell
        align="left"
        style={{ minWidth: 180, maxWidth: 260, overflowWrap: 'break-word' }}
        data-testid="cspListingRowDisplayName"
      >
        <span>{csp.displayName}</span>
      </TableCell>
      <TableCell align="left" data-testid="cspListingRowCountry">
        {csp.country}
      </TableCell>
      <TableCell align="left" data-testid="cspListingRowEntityType">
        {ENTITY_TYPES.find((e) => e.value === csp.entityType)?.label ||
          csp.entityType ||
          'N/A'}
      </TableCell>
      <TableCell align="left" data-testid="cspListingRowActiveCampaignCount">
        {csp.activeCampaignCount || csp.activeCampaignCount === 0
          ? csp.activeCampaignCount
          : '--'}
      </TableCell>
    </TableRow>
  );
}

export default withRouter(CspListingRow);
