import { FC, ComponentProps } from 'react';
import { BoxV2 as Box } from 'portal-commons';

type DiverProps = Pick<ComponentProps<typeof Box>, 'sx'>;

const Divider: FC<DiverProps> = ({ sx }) => {
  return (
    <Box
      as="hr"
      sx={{
        height: '1px',
        backgroundColor: 't.black20',
        width: '100%',
        border: 'none',
        ...sx,
      }}
    />
  );
};

export default Divider;
