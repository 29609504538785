import React, { useEffect, useState } from 'react';
import { AddNewApiKeyDialog, ApiKeyListingRow } from './index';
import {
  globalGetService,
  globalPostService,
} from '../../../../utils/globalApiServices';
import { Button, Box, Table } from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey } from '@fortawesome/pro-regular-svg-icons';

const headRows = [
  { id: 'displayName', label: 'API LABEL', sortable: false },
  { id: 'apiKey', label: 'API KEY', sortable: false },
  { id: 'secret', label: 'SECRET', sortable: false },
  { id: 'updateDate', label: 'LAST UPDATED', sortable: false },
  { id: 'enabled', label: 'ACTIVE', sortable: false },
  { id: 'actions', label: 'ACTIONS', sortable: false },
];

export default function ApiKeysListing(props) {
  const [loading, setLoading] = useState(false);
  const [apiAccounts, setApiAccounts] = useState([]);
  const [addNewApiKey, setAddNewApiKey] = useState(false);
  const [activeAccount, setActiveAccount] = useState({});
  const [addNewApiKeyLoader, setAddNewApiKeyLoader] = useState(false);
  const [displayAccounts, setDisplayAccounts] = useState([]);
  const [pageSize] = useState(10);
  const [currPage, setCurrPage] = useState(1);

  const fetchApiAccounts = (query = {}) => {
    setLoading(true);
    globalGetService(`mno/api/account`, query).then((response) => {
      setLoading(false);
      if (response.status >= 200 && response.status < 300) {
        setApiAccounts(response.data);
      }
    });
  };

  const handleAddNewApiKey = () => {
    setAddNewApiKeyLoader(true);
    globalPostService(`mno/api/account`, {}).then((response) => {
      setAddNewApiKeyLoader(false);
      if (response.status >= 200 && response.status < 300) {
        setActiveAccount(response.data);
        setAddNewApiKey(true);
        fetchApiAccounts();
      }
    });
  };

  useEffect(() => {
    fetchApiAccounts();
  }, []);

  useEffect(() => {
    const start = (currPage - 1) * pageSize;
    const end = start + pageSize;
    setDisplayAccounts(apiAccounts.slice(start, end));
  }, [apiAccounts, currPage, pageSize]);

  return (
    <>
      <div className="api-keys-listing-wrapper" data-testid="apiKeysListing">
        <div className="title-blk">
          <h3 className="flex heading1 space-between v-center">
            <div>
              <Box flexDirection="row" justifyContent="center">
                <Box margin={{ right: 'xs' }}>
                  <FontAwesomeIcon icon={faKey} />
                </Box>
                <span>API Keys</span>
              </Box>
            </div>
            <Button
              data-testid="apiKeysListingAddButton"
              disabled={addNewApiKeyLoader}
              portal="mno"
              shape="rounded"
              color="tertiary"
              onClick={handleAddNewApiKey}
            >
              Add New
            </Button>
          </h3>
        </div>
        <Table
          disableHover
          loading={loading}
          className="api-keys-listing-table"
          emptyState=""
          headRows={headRows}
          records={{ total: apiAccounts.length }}
          data={displayAccounts}
          renderRow={(data) => {
            return (
              <ApiKeyListingRow
                accountObj={data}
                key={data.id}
                fetchApiAccounts={fetchApiAccounts}
              />
            );
          }}
          handleChangePage={(page) => setCurrPage(page)}
          pagination={{
            count: Math.ceil(apiAccounts.length / pageSize),
            page: currPage,
            rowsPerPage: pageSize,
            totalRecords: apiAccounts.length,
          }}
        />
      </div>
      <AddNewApiKeyDialog
        open={addNewApiKey}
        type={'add'}
        handleClose={() => {
          setAddNewApiKey(false);
          setActiveAccount({});
        }}
        account={activeAccount}
      />
    </>
  );
}
