import React, { useEffect, useState } from 'react';
import { BoxV2 as Box, Flex, Button } from 'portal-commons';
import { useHistory, useLocation } from 'react-router-dom';

const DashboardNotFound: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const [type, setType] = useState('CAMPAIGN ID');
  const [value, setValue] = useState('');

  const handleBack = () => {
    history.go(-1);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setValue(params.get('value') ?? '');
    setType(params.get('type') ?? 'CAMPAIGN ID');
  }, [location.search]);

  return (
    <Flex
      sx={{
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100%',
        gap: 16,
      }}
      data-testid="emptySearchResult"
    >
      <Box
        sx={{
          minWidth: 550,
          p: '30px 60px',
          border: '1px solid #00698f',
          backgroundColor: 'rgba(0, 105, 143, 0.1)',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          fontSize: 16,
          lineHeight: 1.22,
          fontWeight: 400,
          color: '#00698F',
        }}
      >
        <img
          style={{ marginBottom: 20 }}
          src={
            // eslint-disable-next-line @typescript-eslint/no-var-requires
            require('../../../../assets/images/SearchResultIcon.svg').default
          }
        />
        <Box as="h3" sx={{ fontSize: 16, fontWeight: 500 }}>
          We couldn’t find{' '}
          {type === 'PHONE NUMBER' ? 'any number history' : 'anything'} for{' '}
          {value}
        </Box>
        {type !== 'PHONE NUMBER' && (
          <p className="text">
            Please try different or less specific keywords.
          </p>
        )}
      </Box>
      <Button
        data-testid="backToDashboard"
        portal="mno"
        shape="rounded"
        color="secondary"
        onClick={handleBack}
      >
        Back to Dashboard
      </Button>
    </Flex>
  );
};

export default DashboardNotFound;
