import { startOfDay, sub } from 'date-fns';

export const Today = startOfDay(new Date());
export const dateRangePresets = [
  {
    label: 'Last 24 hours',
    start: sub(Today, { days: 1 }),
    end: Today,
  },
  {
    label: 'Last 7 days',
    start: sub(Today, { days: 7 }),
    end: Today,
  },
  {
    label: 'Last 1 month',
    start: sub(Today, { months: 1 }),
    end: Today,
  },
  {
    label: 'Last 6 months',
    start: sub(Today, { months: 6 }),
    end: Today,
  },
];
