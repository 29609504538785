import React, { Component } from 'react';
import Grid from '@material-ui/core/Grid';
import Header from '../../../shared_elements/components/Header';
import { ResetEmailSuccess } from '../components';
import { withRouter } from 'react-router-dom';
function UserAuthThanks(props) {
  return (
    <section
      className="user-authentication-section"
      data-testid="userAuthThanks"
    >
      <Header
        title="WELCOME TO THE CAMPAIGN REGISTRY"
        path={props.location.pathname}
      ></Header>
      <Grid
        container
        spacing={0}
        alignItems="center"
        justify="center"
        className="user-form-wrapper auth-thanks"
        style={{ height: window.innerHeight - 60 }}
      >
        <ResetEmailSuccess />
      </Grid>
    </section>
  );
}
export default withRouter(UserAuthThanks);
