import moment from 'moment-timezone';

export const US_DATE_FORMAT = 'MM/DD/YYYY';

export const getTimezone = () => {
  let timezone = 'America/Los_Angeles';
  if (Intl && Intl.DateTimeFormat) {
    timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
  return timezone;
};

export const convertTimeWithTimezone = (
  date,
  timezone = getTimezone(),
  format = 'MM/DD/YYYY - HH:mm z'
) => {
  const formattedTime = moment.tz(date, timezone).format(format);
  return formattedTime;
};
