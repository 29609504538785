import React from 'react';
import { Grid } from '@material-ui/core';
import { S3_ASSETS_PATH } from '../../../../constants';
import { Box } from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAward, faThumbsUp } from '@fortawesome/pro-regular-svg-icons';
import { getStatusIcon } from '../../../../utils/statusIcon';

export default function CarrierItem(props) {
  const { campaign } = props;
  return (
    <Grid container className="" data-testid="carrierItem">
      <Grid item xs={1} data-testid="carrierItemMno">
        <div className="name flex-centered">
          <p>
            {campaign.rulesEngineResultsJson
              ? JSON.parse(campaign.rulesEngineResultsJson).mno
              : '--'}
          </p>
        </div>
      </Grid>
      <Grid item xs={2} data-testid="carrierItemQualify">
        <div className="label">
          <h3 className="paragraph">
            <Box
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              <Box margin={{ right: 'xxs' }}>
                <FontAwesomeIcon icon={faAward} size="xl" />
              </Box>
              <span>QUALIFY</span>
            </Box>
          </h3>
        </div>
        <div className="value">
          <p className="paragraph medium">
            {campaign.rulesEngineResultsJson
              ? JSON.parse(campaign.rulesEngineResultsJson).qualify
                ? 'Yes'
                : 'No'
              : '--'}
          </p>
        </div>
      </Grid>
      <Grid item xs={2} data-testid="carrierItemMnoReview">
        <div className="label">
          <h3 className="paragraph">
            <Box
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
            >
              <Box margin={{ right: 'xxs' }}>
                <FontAwesomeIcon icon={faThumbsUp} size="xl" />
              </Box>
              <span>MNO REVIEW</span>
            </Box>
          </h3>
        </div>
        <div className="value">
          <p className="paragraph medium">
            {campaign.rulesEngineResultsJson
              ? JSON.parse(campaign.rulesEngineResultsJson).mnoReview
                ? 'Yes'
                : 'No'
              : '--'}
          </p>
        </div>
      </Grid>
      <Grid
        item
        xs={2}
        style={{ marginLeft: 5, maxWidth: '15.3%' }}
        data-testid="carrierItemOperationStatus"
      >
        <div className="label" style={{ padding: 11 }}>
          <h3 className="paragraph">
            <span>CAMPAIGN STATUS</span>
          </h3>
        </div>
        <div className="value">
          <p className="paragraph medium">
            {/* <img src={images[campaign.operationStatus]} alt=""/> */}
            {campaign.operationStatus ? (
              <React.Fragment>
                <Box
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box margin={{ right: 'xs' }}>
                    {getStatusIcon(campaign.operationStatus)}
                  </Box>
                  <span>
                    {campaign.operationStatus.toLowerCase() === 'approved'
                      ? 'Registered'
                      : campaign.operationStatus.toLowerCase()}
                  </span>
                </Box>
              </React.Fragment>
            ) : null}
          </p>
        </div>
      </Grid>
      <Grid
        item
        xs={2}
        style={{ maxWidth: '13.9%' }}
        data-testid="carrierItemMnoDetails"
      >
        <div className="label" style={{ padding: 11 }}>
          <h3 className="paragraph">
            <span>
              {campaign.rulesEngineResultsJson
                ? JSON.parse(campaign.rulesEngineResultsJson).mno == 'T-Mobile'
                  ? 'BRAND TIER'
                  : 'MESSAGE CLASS'
                : '--'}
            </span>
          </h3>
        </div>
        <div className="value">
          <p className="paragraph">
            {campaign.rulesEngineResultsJson
              ? JSON.parse(campaign.rulesEngineResultsJson).mno == 'T-Mobile'
                ? JSON.parse(campaign.rulesEngineResultsJson).brandTier
                : JSON.parse(campaign.rulesEngineResultsJson).msgClass || 'N/A'
              : '--'}
          </p>
        </div>
      </Grid>
      <Grid
        item
        xs={2}
        style={{ maxWidth: '16.2%' }}
        data-testid="carrierItemDcaName"
      >
        <div className="label" style={{ padding: 11 }}>
          <h3 className="paragraph">
            <span>DCA NAME</span>
          </h3>
        </div>
        <div className="value">
          <p className="paragraph medium">
            {campaign.dcaName ? `DCA: ${campaign.dcaName}` : 'None'}
          </p>
        </div>
      </Grid>
      <Grid
        item
        xs={2}
        style={{ maxWidth: '12.3%' }}
        data-testid="carrierItemStatus"
      >
        <div className="label" style={{ padding: 11 }}>
          <h3 className="paragraph">
            <span>TCR STATUS</span>
          </h3>
        </div>
        <div className="value">
          <p className="paragraph medium">
            {campaign.status == 'ACTIVE'
              ? 'Active'
              : campaign.status == 'EXPIRED'
              ? 'Deactivated'
              : campaign.status
              ? campaign.status
              : '--'}
          </p>
        </div>
      </Grid>
    </Grid>
  );
}
