import regexExpressions from '../../../constants/regExpressions';

export const isRequired = (value?: string) => !!value?.length || 'Required';
export const isValidName = (value?: string) =>
  regexExpressions.name.test(value?.trim() || '') || 'Invalid name';
export const isValidEmail = (value?: string) =>
  regexExpressions.email.test(value?.trim() || '') || 'Invalid email';

export const createMaxLengthValidator =
  (maxLength: number) => (value?: string) =>
    (value?.length ?? 0) <= maxLength ||
    `Maximum ${maxLength} chars are allowed`;
