import React from 'react';
import { useHistory } from 'react-router-dom';
import { BoxV2 as Box, Flex, Button } from 'portal-commons';
import {
  faLightbulbOn,
  faCircleExclamation,
  faCircleCheck,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CampaignReviewSection: React.FC<{ count: number }> = ({ count }) => {
  const history = useHistory();

  return (
    <Box
      sx={{
        p: 24,
        height: 142,
        minWidth: 480,
        border: '1px solid #B3B7B8',
        borderRadius: 16,
        flex: 2,
      }}
    >
      <Flex sx={{ alignItems: 'center', justifyContent: 'space-around' }}>
        <Flex
          sx={{
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexShrink: 0,
            gap: 8,
          }}
          className="campaigns-awaiting-review-wrapper"
        >
          <Flex
            sx={{
              alignItems: 'center',
              fontSize: 48,
              fontWeight: 800,
              color: '#333E41',
              lineHeight: 'normal',
            }}
            data-testid="reviewCampaignsCount"
          >
            <FontAwesomeIcon
              icon={faLightbulbOn}
              style={{ color: '#D41C62', fontSize: 48, marginRight: 12 }}
            />
            {count}
          </Flex>
          <Box
            as="span"
            sx={{ fontSize: 14, fontWeight: 600, color: '#4D5659' }}
          >
            Campaigns Awaiting Review
          </Box>
        </Flex>
        <Flex sx={{ flexDirection: 'column', gap: 12 }}>
          <Button
            color="secondary"
            data-testid="reviewCampaignsButton"
            onClick={() =>
              history.push({
                pathname: '/review-campaigns',
                search:
                  '?status=ACTIVE&ascendingOrder=false&mnoStatus=REVIEW&sortField=createDate',
              })
            }
          >
            <FontAwesomeIcon icon={faCircleExclamation} />
            Review Campaigns
          </Button>
          <Button
            color="secondary"
            variant="outline"
            data-testid="reviewedCampaignsButton"
            onClick={() =>
              history.push({
                pathname: '/campaigns',
                search: `?mnoStatus=REGISTERED,REJECTED&status=ACTIVE`,
              })
            }
          >
            <FontAwesomeIcon icon={faCircleCheck} />
            Reviewed Campaigns
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
};

export default CampaignReviewSection;
