import React, { useState } from 'react';
import { useForm, Controller, FieldValues } from 'react-hook-form';
import { useLocation, useHistory } from 'react-router-dom';
import {
  getBrandDetail,
  getCampaignDetail,
  getCampaignDetailByPhone,
  queryCampaignPhoneHistory,
} from '../../apis';
import { toastFlashMessage } from '../../../../../utils';
import SearchTextField from '../SearchTextField';
import { Flex, BoxV2 as Box } from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn, faTags, faPhone } from '@fortawesome/pro-light-svg-icons';
import { SearchResult } from '../../types';

const ValidateRules = {
  searchId: {
    maxLength: {
      value: 7,
      message: 'Maximum 7 characters id',
    },
    minLength: {
      value: 7,
      message: 'Minimum 7 characters id',
    },
    pattern: {
      value: /^[a-zA-Z0-9]{7}$/,
      message: 'Invalid id',
    },
  },
  searchPhoneNumber: {
    minLength: {
      value: 10,
      message: 'Minimum 10 digits phone number',
    },
    maxLength: {
      value: 20,
      message: 'Maximum 20 digits phone number',
    },
    pattern: {
      // refer to https://campaignreg.atlassian.net/wiki/x/CICuC
      value: /^\+?(?:[0-9][_!#$%&'*+\/=?`{|}~^.\-()\s]*?){6,14}[0-9]$/,
      message: 'Invalid phone number',
    },
  },
};

interface Props {
  onNoSearchResult: (result: SearchResult) => void;
}

const SearchArea: React.FC<Props> = ({ onNoSearchResult }) => {
  const { control: controlCampaign, handleSubmit: handleSubmitCampaign } =
    useForm();
  const { control: controlBrand, handleSubmit: handleSubmitBrand } = useForm();
  const {
    control: controlPhone,
    handleSubmit: handleSubmitPhone,
    setError: setErrorPhone,
    clearErrors: clearErrorsPhone,
  } = useForm({ mode: 'onBlur' });
  const history = useHistory();
  const location = useLocation();
  const [searchCampaign, setSearchCampaign] = useState(false);
  const [searchBrand, setSearchBrand] = useState(false);
  const [searchPhoneNumber, setSearchPhoneNumber] = useState(false);

  const onSubmitCampaign = async (data: FieldValues) => {
    const { campaignId } = data;
    if (!campaignId.trim()) return;
    setSearchCampaign(true);
    const campaign = await getCampaignDetail(campaignId);
    if (campaign) {
      history.push({
        pathname: `/campaigns/${campaignId}`,
        state: { campaign, goBackPage: location.pathname },
      });
    } else {
      onNoSearchResult({ type: 'CAMPAIGN ID', value: campaignId });
    }
    setSearchCampaign(false);
  };

  const onSubmitBrand = async (data: FieldValues) => {
    const { brandId } = data;
    if (!brandId.trim()) return;
    setSearchBrand(true);
    const brand = await getBrandDetail(brandId);
    if (brand) {
      history.push({
        pathname: `/brands/${brandId}`,
        state: { brandInfo: brand, goBackPage: location.pathname },
      });
    } else {
      onNoSearchResult({ type: 'BRAND ID', value: brandId });
    }
    setSearchBrand(false);
  };

  const onSubmitPhone = async (data: FieldValues) => {
    const { phoneNumber } = data;
    if (!phoneNumber.trim()) {
      setErrorPhone('phoneNumber', {
        type: 'required',
        message: 'This field is required',
      });
      return;
    }
    setSearchPhoneNumber(true);
    const campaign = await getCampaignDetailByPhone(phoneNumber);
    if (campaign) {
      history.push({
        pathname: `/campaigns/${campaign.uid}`,
        state: { campaign, goBackPage: location.pathname },
      });
    } else {
      const campaignHistory = await queryCampaignPhoneHistory(phoneNumber);
      if (campaignHistory && campaignHistory.totalRecords > 0) {
        history.push({
          pathname: `/phones/${phoneNumber}`,
          state: { goBackPage: location.pathname },
        });
      } else {
        toastFlashMessage('Phone number not found', 'error');
        onNoSearchResult({ type: 'PHONE NUMBER', value: phoneNumber });
      }
    }
    setSearchPhoneNumber(false);
  };

  return (
    <Flex
      sx={{
        alignItems: 'center',
        justifyContent: 'space-around',
        flexWrap: 'wrap',
        gap: 32,
        width: '100%',
        px: 16,
      }}
    >
      <Box
        sx={{ display: 'inline-flex', alignItems: 'flex-start', flexGrow: 1 }}
      >
        <FontAwesomeIcon
          icon={faBullhorn}
          style={{
            fontSize: 30,
            marginRight: 12,
            color: '#0D1A1E',
            marginTop: 4,
          }}
        />
        <Controller
          name="campaignId"
          control={controlCampaign}
          defaultValue=""
          rules={ValidateRules.searchId}
          render={({ field, fieldState: { error = {} } }) => (
            <SearchTextField
              data-testid="searchCampaignIdTextField"
              label="Search by Campaign ID"
              searching={searchCampaign}
              value={field.value}
              error={error.message ?? ''}
              onChange={field.onChange}
              onBlur={field.onBlur}
              onSearchClick={() => handleSubmitCampaign(onSubmitCampaign)()}
            />
          )}
        />
      </Box>
      <Box
        sx={{ display: 'inline-flex', alignItems: 'flex-start', flexGrow: 1 }}
      >
        <FontAwesomeIcon
          icon={faTags}
          style={{
            fontSize: 30,
            marginRight: 12,
            color: '#0D1A1E',
            marginTop: 4,
          }}
        />
        <Controller
          name="brandId"
          control={controlBrand}
          defaultValue=""
          rules={ValidateRules.searchId}
          render={({ field, fieldState: { error = {} } }) => (
            <SearchTextField
              data-testid="searchBrandIdTextField"
              label="Search by Brand ID"
              searching={searchBrand}
              value={field.value}
              error={error.message ?? ''}
              onChange={field.onChange}
              onBlur={field.onBlur}
              onSearchClick={() => handleSubmitBrand(onSubmitBrand)()}
            />
          )}
        />
      </Box>
      <Box
        sx={{ display: 'inline-flex', alignItems: 'flex-start', flexGrow: 1 }}
      >
        <FontAwesomeIcon
          icon={faPhone}
          style={{
            fontSize: 30,
            marginRight: 12,
            color: '#0D1A1E',
            marginTop: 4,
          }}
        />
        <Controller
          name="phoneNumber"
          control={controlPhone}
          defaultValue=""
          rules={ValidateRules.searchPhoneNumber}
          render={({ field, fieldState: { error = {} } }) => (
            <SearchTextField
              data-testid="searchPhoneNumberTextField"
              label="Search by Phone Number"
              searching={searchPhoneNumber}
              value={field.value}
              error={error.message ?? ''}
              onChange={(e) => {
                field.onChange(e);
                if (error?.type === 'required') {
                  clearErrorsPhone('phoneNumber');
                }
              }}
              onBlur={() => {
                field.onBlur();
                clearErrorsPhone('phoneNumber');
              }}
              onSearchClick={() => handleSubmitPhone(onSubmitPhone)()}
            />
          )}
        />
      </Box>
    </Flex>
  );
};

export default SearchArea;
