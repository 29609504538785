import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSquareMinus,
  faSquareCheck,
  faSquareXmark,
  faSquareEllipsis,
  faSquareExclamation,
} from '@fortawesome/pro-solid-svg-icons';

export const DEV_BASE_URL = process.env.REACT_APP_BASE_URL
  ? process.env.REACT_APP_BASE_URL
  : 'https://mno-portal-api-dev.campaignregistry.com/api/v1/';
export const OKTA_BASE_URL = process.env.REACT_APP_OKTA_BASE
  ? process.env.REACT_APP_OKTA_BASE
  : 'https://dev-752242.okta.com/';
export const S3_ASSETS_PATH = process.env.REACT_APP_S3_ASSETS_PATH
  ? process.env.REACT_APP_S3_ASSETS_PATH
  : 'http://static-dev.campaignregistry.com/mno';
export const OKTA_ISSUER_URL = process.env.REACT_APP_OKTA_AUTH_SERVER
  ? process.env.REACT_APP_OKTA_AUTH_SERVER
  : 'https://dev-752242.okta.com/oauth2/aus2sz86veSMe8VRA357';
export const OKTA_CLIENT_ID = process.env.REACT_APP_OKTA_CLIENT_ID
  ? process.env.REACT_APP_OKTA_CLIENT_ID
  : '0oa2sz38fwJ0c1u9g357';
export const GOOGLE_RECAPTCHA_SITE_KEY = process.env
  .REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY
  ? process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY
  : '6LcwRCYaAAAAAJcrI20yKbz9O4Zgd6S_ErRrt6tX';
export const DATADOG_APPLICATION_ID = process.env
  .REACT_APP_DATADOG_APPLICATION_ID
  ? process.env.REACT_APP_DATADOG_APPLICATION_ID
  : '8928f89c-8e72-417d-962a-b97a8808fb00';
export const DATADOG_CLIENT_TOKEN = process.env.REACT_APP_DATADOG_CLIENT_TOKEN
  ? process.env.REACT_APP_DATADOG_CLIENT_TOKEN
  : 'pub38d24eda5902328a6d12c42b0902bbef';
export const DATADOG_ENV = process.env.REACT_APP_DATADOG_ENV
  ? process.env.REACT_APP_DATADOG_ENV
  : 'local';
// eslint-disable-next-line @typescript-eslint/no-var-requires
export const PACKAGE_JSON_VERSION = require('../../package.json').version;

// DEV
// OKTA domain - dev-752242.okta.com
// Client ID - 0oa2sz38fwJ0c1u9g357
// Issuer ID - aus2sz86veSMe8VRA357
export function getEventTypeImage(eventType) {
  if (
    [
      'CAMPAIGN_ADD',
      'CAMPAIGN_ACTIVE',
      'CAMPAIGN_DCA_ADD',
      'CSP_ACTIVE',
      'CSP_APPROVED',
      'CSP_EMAIL_UPDATE',
      'EVP_REPORT',
      'MNO_CAMPAIGN_OPERATION_APPROVED',
      'MNO_CAMPAIGN_OPERATION_UNSUSPENDED',
    ].includes(eventType)
  ) {
    return (
      <FontAwesomeIcon
        icon={faSquareCheck}
        size="xl"
        style={{ color: '009B7C' }}
      />
    );
  } else if (
    ['CAMPAIGN_EXPIRED', 'MNO_COMPLAINT', 'CAMPAIGN_DCA_UPDATE'].includes(
      eventType
    )
  ) {
    return (
      <FontAwesomeIcon
        icon={faSquareExclamation}
        size="xl"
        style={{ color: 'F5C245' }}
      />
    );
  } else if (
    [
      'CAMPAIGN_DCA_DELETE',
      'CAMPAIGN_SUSPEND',
      'CSP_SUSPEND',
      'MNO_CAMPAIGN_OPERATION_SUSPENDED',
    ].includes(eventType)
  ) {
    return (
      <FontAwesomeIcon
        icon={faSquareMinus}
        size="xl"
        style={{ color: 'D41C54' }}
      />
    );
  } else if (['MNO_CAMPAIGN_OPERATION_REVIEW'].includes(eventType)) {
    return (
      <FontAwesomeIcon
        icon={faSquareEllipsis}
        size="xl"
        style={{ color: '00698F' }}
      />
    );
  } else if (['MNO_CAMPAIGN_OPERATION_REJECTED'].includes(eventType)) {
    return (
      <FontAwesomeIcon
        icon={faSquareXmark}
        size="xl"
        style={{ color: 'black' }}
      />
    );
  } else {
    return (
      <FontAwesomeIcon
        icon={faSquareCheck}
        size="xl"
        style={{ color: '009B7C' }}
      />
    );
  }
}

export const ENTITY_TYPES = [
  {
    label: 'Publicly Traded Company',
    value: 'PUBLIC_PROFIT',
  },
  {
    label: 'Private Company',
    value: 'PRIVATE_PROFIT',
  },
  {
    label: 'Non-Profit Organization',
    value: 'NON_PROFIT',
  },
  {
    label: 'Government',
    value: 'GOVERNMENT',
  },
  {
    label: 'Sole Proprietor',
    value: 'SOLE_PROPRIETOR',
  },
];

export const MNO_STATUS = [
  {
    label: 'Review',
    value: 'REVIEW',
  },
  {
    label: 'Rejected',
    value: 'REJECTED',
  },
  {
    label: 'Registered',
    value: 'REGISTERED',
  },
  {
    label: 'Suspended',
    value: 'SUSPENDED',
  },
];

export const MNO_STATUS_ENUM = {
  REVIEW: 'REVIEW',
  REJECTED: 'REJECTED',
  REGISTERED: 'REGISTERED',
  SUSPENDED: 'SUSPENDED',
};

export const MAX_TEXT_LENGTH = 2048;

export const BRAND_RELATIONSHIP_SCORE = [
  {
    img: `${S3_ASSETS_PATH}/images/basic-accounts.svg`,
    label: `Basic accounts`,
    description: `no business history with the CSP`,
    value: 1,
    icon: `${S3_ASSETS_PATH}/images/br-1.svg`,
  },
  {
    img: `${S3_ASSETS_PATH}/images/small-accounts.svg`,
    label: `Small accounts`,
    description: `or some business history with the CSP`,
    value: 2,
    icon: `${S3_ASSETS_PATH}/images/br-2.svg`,
  },
  {
    img: `${S3_ASSETS_PATH}/images/medium-accounts.svg`,
    label: `Medium accounts`,
    description: `with good standing with the CSP and solid business history`,
    value: 3,
    icon: `${S3_ASSETS_PATH}/images/br-3.svg`,
  },
  {
    img: `${S3_ASSETS_PATH}/images/large-accounts.svg`,
    label: `Large accounts`,
    description: `with a dedicated account manager, highly trusted`,
    value: 4,
    icon: `${S3_ASSETS_PATH}/images/br-4.svg`,
  },
  {
    img: `${S3_ASSETS_PATH}/images/key-accounts.svg`,
    label: `Key accounts`,
    description: `with strategic value and a dedicated account team`,
    value: 5,
    icon: `${S3_ASSETS_PATH}/images/br-5.svg`,
  },
];
