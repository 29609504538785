import { ComponentProps, FunctionComponent } from 'react';
import { Dialog, makeStyles } from '@material-ui/core';

interface ModalProps extends ComponentProps<typeof Dialog> {
  open: boolean;
  width?: string;
  'data-testid'?: string;
}

const useStyles = makeStyles({
  container: {
    width: (props: { width?: string }) => (props.width ? props.width : '525px'),
    padding: '24px 32px 9px',
  },
});

const Modal: FunctionComponent<ModalProps> = ({
  open,
  children,
  width,
  ...restProps
}) => {
  const classes = useStyles({
    width: width,
  });

  return (
    <Dialog open={open} classes={{ paper: classes.container }} {...restProps}>
      {children}
    </Dialog>
  );
};

export default Modal;
