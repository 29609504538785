import React, { useState, useEffect, useCallback } from 'react';
import {
  Backdrop,
  Container,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Loader } from '../../../../shared_elements';
import { BrandSuspensionModal, SuspensionCampaignRow } from '../components';
import Spinner from '../../Campaigns/components/Spinner';
import { MNO_STATUS_ENUM } from '../../../../constants';
import { toastFlashMessage } from '../../../../utils';
import {
  fetchBrandDetail,
  getSuspensionRule,
  getSuspensionPreviewCampaigns,
  createSuspensionRule,
  updateSuspensionRule,
  updateBulkCampaignStatusApi,
} from '../apiServices';
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import { Button, Box, Table } from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faBullhorn } from '@fortawesome/pro-regular-svg-icons';

const headRows = [
  { id: 'uid', label: 'CAMPAIGN ID', sortable: true },
  { id: 'usecase', label: 'USE-CASE', sortable: true },
  { id: 'createDate', label: 'REGISTERED ON', sortable: true },
  { id: 'operationStatus', label: 'MNO STATUS', sortable: false },
];

const useStyles = makeStyles(() => ({
  progressBackground: {
    background: 'rgba(14, 31, 43, 0.75)',
    zIndex: 1005,
  },
  progressModal: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '430px',
    height: '226px',
    background: '#FFFFFF',
    borderRadius: '5px',
    marginLeft: 'calc(50% - 120px)',
  },
  progressText: {
    textAlign: 'center',
    width: '100%',
    marginTop: 30,
    color: '#49535D',
    wordBreak: 'break-all',
  },
}));

const BrandSuspension = (props) => {
  const classes = useStyles();
  const brandUid = props.match.params.id;
  const locationState = props.history.location.state;
  const initFilter = {
    ascendingOrder: false,
    brandUid,
    page: 1,
    recordsPerPage: 10,
    sortField: 'createDate',
  };
  const emptyPagination = {
    records: [],
    page: 1,
    recordsPerPage: 10,
    totalRecords: 0,
  };
  const [loader, setLoader] = useState(true);
  const [tableLoader, setTableLoader] = useState(true);
  const [brandSuspended, setBrandSuspended] = useState(
    locationState && locationState.brandSuspended
      ? locationState.brandSuspended
      : null
  );
  const [brandInfo, setBrandInfo] = useState({});
  const [campaignInfo, setCampaignInfo] = useState(emptyPagination);
  const [effectedCampaignCount, setEffectedCampaignCount] = useState(0);
  const [filter, setFilter] = useState(initFilter);
  const [suspensionRuleId, setSuspensionRuleId] = useState(null);
  const [openBrandSuspension, setOpenBrandSuspension] = useState(false);

  // This page only create suspension rule, backend will update campaigns status
  const manualUpdateCampaign = false;
  const processAction = brandSuspended ? 'Lift' : 'Suspending';
  const [progressMsg, setProgressMsg] = useState(null);

  const handleChangePage = (newPage) => {
    props.history.push({
      search: `?${queryString.stringify(
        { ...filter, page: newPage },
        { encode: false }
      )}`,
    });
  };
  const createSortHandler = (sortField) => {
    const newFilter = {
      ...filter,
      sortField,
      ascendingOrder: !filter.ascendingOrder,
    };
    setFilter(newFilter);

    if (campaignInfo.totalRecords) {
      const query = {
        ...newFilter,
        page: campaignInfo.page,
      };
      props.history.push({
        search: `?${queryString.stringify(query, { encode: false })}`,
      });
    }
  };
  const handleModalSubmit = (payload) => {
    let authUser = null;
    const user = localStorage.getItem('user');
    if (user && JSON.parse(user)) {
      authUser = JSON.parse(user).profile.mnoNetworkId;
    }
    const data = {
      ...payload, // explanation, suspensionCategory
      authUser,
      brandUid,
      status: brandSuspended ? 'INACTIVE' : 'ACTIVE',
    };

    createBrandSuspensionRule(data);
  };

  const createBrandSuspensionRule = async (payload) => {
    try {
      setProgressMsg(
        `${suspensionRuleId ? 'Update' : 'Create'} suspension rule`
      );

      let response;
      if (suspensionRuleId) {
        response = await updateSuspensionRule(suspensionRuleId, payload);
      } else {
        response = await createSuspensionRule(payload);
      }

      if (response.ruleId && manualUpdateCampaign) {
        setProgressMsg(`${processAction} all related campaigns`);
        await updateAllCampaigns(response.explanation);
      }

      setTimeout(() => {
        setProgressMsg(null);
        props.history.push({ pathname: `/brands/${brandUid}` });
        const message = brandSuspended
          ? 'Brand Suspension Successfully Lifted'
          : 'Brand Successfully Suspended';
        toastFlashMessage(message, 'success');
      }, 1000);
    } catch (e) {
      setProgressMsg(null);
      props.history.push({ pathname: `/brands/${brandUid}` });
    }
  };

  const updateAllCampaigns = async (reason) => {
    let count = 0;
    let tableInfo = { ...campaignInfo };
    const total = tableInfo.totalRecords;
    const updateStatus = brandSuspended
      ? MNO_STATUS_ENUM.REGISTERED
      : MNO_STATUS_ENUM.SUSPENDED;

    try {
      while (tableInfo && count < total) {
        const campaignIds = tableInfo.records.map((campaign) => campaign.uid);
        count += campaignIds.length;
        setProgressMsg(`${processAction} ${count} / ${total} campaigns`);
        // eslint-disable-next-line no-await-in-loop
        await updateBulkCampaignStatusApi(campaignIds, updateStatus, reason);
        if (count < total) {
          // eslint-disable-next-line no-await-in-loop
          tableInfo = await getTableData(filter);
        }
      }
    } catch (e) {
      toastFlashMessage(
        `Update Campaigns Status to ${updateStatus} Failed`,
        'error'
      );
    }
  };

  const getEffectedCampaignCount = useCallback(async () => {
    if (brandSuspended === null) return;
    const queryEffect = {
      ...initFilter,
      mnoStatus: brandSuspended
        ? MNO_STATUS_ENUM.SUSPENDED
        : MNO_STATUS_ENUM.REGISTERED,
    };
    const effectedCampaign = await getTableData(queryEffect, false);
    if (effectedCampaign.totalRecords > 0) {
      setEffectedCampaignCount(effectedCampaign.totalRecords);
    }
  }, [brandSuspended]);

  const btnColor = useCallback(() => {
    return brandSuspended ? 'secondary' : 'primary';
  }, [brandSuspended]);

  const getBrandRelatedData = useCallback(async () => {
    try {
      const response = await fetchBrandDetail(brandUid);
      setBrandInfo(response.data);

      if (brandSuspended === null || suspensionRuleId === null) {
        const rules = await getSuspensionRule({
          brandUid,
          exactMatch: true,
        });
        if (rules && rules.records.length > 0) {
          const suspensionRule = rules.records.find(
            (rule) => rule.brandUid === brandUid
          );
          if (suspensionRule) {
            setSuspensionRuleId(suspensionRule.ruleId);
          }
          const result = suspensionRule && suspensionRule.status === 'ACTIVE';
          setBrandSuspended(result);
        } else {
          setBrandSuspended(false);
        }
      }

      setLoader(false);
    } catch (error) {
      throw new Error('API Error!', error);
    }
  }, [brandUid]);

  const getTableData = useCallback(async (query, setToTable = true) => {
    setToTable && setTableLoader(true);
    const result = await getSuspensionPreviewCampaigns(query);
    if (result) {
      setToTable && setCampaignInfo(result);
    }
    setToTable && setTableLoader(false);
    return result;
  }, []);

  useEffect(() => {
    getBrandRelatedData();
  }, [getBrandRelatedData]);

  useEffect(() => {
    if (brandSuspended === null) return;
    setFilter(initFilter);
    getTableData(initFilter);

    getEffectedCampaignCount();
  }, [brandUid, brandSuspended, getTableData]);

  useEffect(() => {
    const searchParams = new URLSearchParams(props.location.search);
    if (searchParams.size === 0) return;
    const newFilter = { ...filter };
    searchParams.forEach((value, key) => {
      if (newFilter.hasOwnProperty(key)) {
        newFilter[key] = value;
      }
    });
    getTableData(newFilter);
  }, [props.location.search, getTableData]);

  return (
    <section className="brand-details-section page-content">
      {loader ? (
        <Loader />
      ) : (
        <Container maxWidth={false} className="brand-details-container">
          <Grid container className="top-blk">
            <Grid item xs={6} className="back-btn">
              <Link className="paragraph" to={`/brands/${brandUid}`}>
                <Box flexDirection="row" alignItems="center">
                  <Box margin={{ right: 's' }}>
                    <FontAwesomeIcon icon={faAngleLeft} size="lg" />
                  </Box>
                  back
                </Box>
              </Link>
            </Grid>
            <Grid item xs={6} className="button-wrapper"></Grid>
          </Grid>
          <Grid container className="brand-details basic">
            <Grid item xs={12}>
              <div className="title">
                <h3 className="heading1">
                  Brand Name:{' '}
                  {brandInfo.entityType.toLowerCase() === 'sole_proprietor'
                    ? 'Redacted due to PII'
                    : brandInfo.displayName
                    ? brandInfo.displayName
                    : 'N/A'}
                </h3>
                <p className="heading2">
                  Brand ID: <span style={{ fontWeight: 700 }}>{brandUid}</span>
                </p>
              </div>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <div className="campaigns-listing details">
              <div className="details-heading">
                <Box flexDirection="row" alignItems="center">
                  <Box margin={{ right: 'xs' }}>
                    <FontAwesomeIcon icon={faBullhorn} size="lg" />
                  </Box>
                  <h3>
                    {`${campaignInfo.totalRecords} ${
                      brandSuspended ? 'Suspended' : 'Active'
                    } Campaigns`}
                  </h3>
                </Box>
              </div>
              <Table
                loading={tableLoader}
                emptyState="no campaigns to view"
                records={{ total: campaignInfo.totalRecords }}
                handleChangePage={handleChangePage}
                createSortHandler={createSortHandler}
                filter={filter}
                headRows={headRows}
                tableData={campaignInfo.records.map((record) => {
                  return (
                    <SuspensionCampaignRow campaign={record} key={record.uid} />
                  );
                })}
                pagination={{
                  count: Math.ceil(
                    campaignInfo.totalRecords / campaignInfo.recordsPerPage
                  ),
                  page: campaignInfo.page,
                  rowsPerPage: campaignInfo.recordsPerPage,
                  totalRecords: campaignInfo.totalRecords,
                }}
              />
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            className="flex-centered"
            style={{ justifyContent: 'center', marginTop: '40px' }}
          >
            <Button
              variant="outline"
              color={brandSuspended ? 'secondary' : 'primary'}
              style={{ marginRight: '15px' }}
              onClick={(event) => {
                event.preventDefault();
                props.history.push(`/brands/${brandUid}`);
              }}
              data-testid="backToBrandDetailButton"
            >
              Cancel
            </Button>
            <Button
              color={btnColor()}
              onClick={() => setOpenBrandSuspension(true)}
              data-testid={
                brandSuspended ? 'liftSuspensionButton' : 'suspendButton'
              }
            >
              {brandSuspended ? 'Lift Suspension' : 'Suspend'}
            </Button>
          </Grid>
        </Container>
      )}
      <Backdrop
        className={classes.progressBackground}
        open={progressMsg !== null}
      >
        <Container className={classes.progressModal}>
          <Spinner />
          <Typography variant="body1" className={classes.progressText}>
            {progressMsg}
          </Typography>
        </Container>
      </Backdrop>
      <BrandSuspensionModal
        open={openBrandSuspension}
        handleClose={() => setOpenBrandSuspension(false)}
        handleSubmit={handleModalSubmit}
        brandInfo={brandInfo}
        totalCampaigns={effectedCampaignCount}
        brandSuspended={brandSuspended}
      />
    </section>
  );
};

export default withRouter(BrandSuspension);
