import React from 'react';
import { Grid } from '@material-ui/core';
import { CspDetailItem } from '../../Csp/components';
import { BRAND_RELATIONSHIP_SCORE } from '../../../../constants';

const CampaignBrandDetails = (props) => {
  const { brandInfo = {} } = props;
  const { optionalAttributes, brandRelationshipScore } = brandInfo;
  const brandRelation = BRAND_RELATIONSHIP_SCORE.find(
    (item) => item.value === brandRelationshipScore
  )?.label;

  return (
    <Grid container data-testid="campaignBrandDetails">
      <Grid item xs={6}>
        <CspDetailItem
          title="Legal Company Name"
          value={
            brandInfo?.entityType === 'SOLE_PROPRIETOR'
              ? 'Redacted due to PII'
              : brandInfo?.companyName
          }
        />
        <CspDetailItem title="Country" value={brandInfo?.country} />
        <CspDetailItem title="EIN" value={brandInfo.ein} />
        <CspDetailItem title="Stock Symbol" value={brandInfo.stockSymbol} />
        <CspDetailItem
          title="Website/Online Presence"
          value={brandInfo.website}
        />
        <CspDetailItem title="Support Phone Number" value={brandInfo.phone} />
        <CspDetailItem
          title="Political Committee Locale"
          value={brandInfo?.optionalAttributes?.politicalCommitteeLocale}
        />
      </Grid>
      <Grid item xs={6}>
        <CspDetailItem
          title="Brand ID"
          value={brandInfo?.uid}
          link={brandInfo?.uid ? `/brands/${brandInfo.uid}` : undefined}
        />
        <CspDetailItem title="State" value={brandInfo?.state} />
        <CspDetailItem
          title="EIN Issuing Country"
          value={brandInfo.einIssuingCountry}
        />
        <CspDetailItem title="Stock Exchange" value={brandInfo.stockExchange} />
        <CspDetailItem title="Brand Relationship" value={brandRelation} />
        <CspDetailItem title="Vertical" value={brandInfo.vertical} />
        <CspDetailItem
          title="Government Entity"
          value={optionalAttributes?.governmentEntity ? 'True' : 'False'}
        />
      </Grid>
    </Grid>
  );
};

export default CampaignBrandDetails;
