import { StyledToast } from 'portal-commons';
import sanitizeHtml from 'sanitize-html';

export const toastFlashMessage = (message, type) => {
  if (message) {
    let formattedMessage = '';
    const messageType = typeof message;
    if (messageType === 'string') {
      formattedMessage = message.trim();
    } else if (messageType === 'number') {
      formattedMessage = message.toString();
    } else if (messageType === 'object') {
      formattedMessage = JSON.stringify(message);
    }
    if (Array.isArray(message) && !message.length) {
      formattedMessage = '';
    } else if (Array.isArray(message) && message.length) {
      formattedMessage = message.join(' ');
    }
    if (formattedMessage) {
      StyledToast({
        content: formattedMessage,
        type,
        'data-testid': `${type}Toast`,
      });
    }
  }
};

export const customSanitizeHtml = (html) => {
  return sanitizeHtml(html, {
    allowedTags: [],
  });
};

export const isValidInput = (input) => {
  var regex = /(\b)(on\S+)(\s*)=|javascript:|(<\s*)(\/*)script/gim;
  return !regex.test(input);
};
