import { withRouter } from 'react-router-dom';
import { withOktaAuth } from '@okta/okta-react';
import { TopBar } from 'portal-commons';

const Header = ({ authenticated, oktaAuth, title, note, history }) => {
  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.setItem('logoutSuccess', true);
    oktaAuth.signOut('/login');
  };

  const userName = localStorage.getItem('okta-token-storage')
    ? JSON.parse(localStorage.getItem('okta-token-storage')).idToken
      ? JSON.parse(localStorage.getItem('okta-token-storage')).idToken.claims
          .name
      : localStorage.getItem('userInfo')
      ? JSON.parse(localStorage.getItem('userInfo')).name
      : ''
    : '';

  return (
    <TopBar
      authenticated={authenticated}
      portal="mno"
      username={userName}
      title={title}
      subText={note}
      menus={[
        <TopBar.MenuItem
          key="profile"
          data-testid="headerMenuItemProfile"
          onClick={() => {
            history.push('/profile');
          }}
        >
          MNO Profile
        </TopBar.MenuItem>,
        <TopBar.MenuItem
          key="logout"
          data-testid="headerMenuItemLogoutButton"
          onClick={handleLogout}
        >
          Logout
        </TopBar.MenuItem>,
      ]}
    />
  );
};

export default withRouter(withOktaAuth(Header));
