import React, { FC } from 'react';
import { Brand } from '../types';
import { Grid } from '@material-ui/core';
import { CspDetailItem } from '../../Csp/components';
import { BRAND_RELATIONSHIP_SCORE } from '../../../../constants';

const BrandDetailsSection: FC<{ brand: Brand }> = ({ brand }) => {
  const {
    website,
    stockSymbol,
    phone,
    vertical,
    stockExchange,
    optionalAttributes = {},
    brandRelationshipScore,
    altBusinessId,
    altBusinessIdType,
  } = brand || {};
  const getGovernmentEntity = () => {
    return optionalAttributes?.governmentEntity ? 'True' : 'False';
  };
  const brandRelation = BRAND_RELATIONSHIP_SCORE.find(
    (item) => item.value === brandRelationshipScore
  )?.label;

  return (
    <Grid container>
      <Grid item xs={6}>
        <CspDetailItem title="Brand Relationship" value={brandRelation} />
        <CspDetailItem title="Website" value={website} />
        <CspDetailItem title="Stock Symbol" value={stockSymbol} />
        <CspDetailItem
          title="Political Committee Locale"
          value={optionalAttributes?.politicalCommitteeLocale}
        />
        <CspDetailItem title="Alt Business ID" value={altBusinessId} />
      </Grid>
      <Grid item xs={6}>
        <CspDetailItem title="Support Phone Number" value={phone} />
        <CspDetailItem title="Vertical" value={vertical} />
        <CspDetailItem title="Stock Exchange" value={stockExchange} />
        <CspDetailItem
          title="Government Entity"
          value={getGovernmentEntity()}
        />
        <CspDetailItem title="Alt Business ID Type" value={altBusinessIdType} />
      </Grid>
    </Grid>
  );
};

export default BrandDetailsSection;
