import {
  globalGetService,
  globalPutService,
  globalPostService,
} from '../../../utils/globalApiServices';
import queryString from 'query-string';
import { US_DATE_FORMAT, convertTimeWithTimezone } from '../../../utils/time';

export function getBrandsListApi(query = {}) {
  this.setState({ tableLoader: true });
  return globalGetService('mno/brands', { ...query }).then((response) => {
    this.setState({ tableLoader: false });
    if (response.status >= 200 && response.status < 300) {
      const brandInfo = response.data;
      this.setState((prevState) => ({
        ...prevState,
        brandInfo,
        loader: false,
      }));
      if (
        brandInfo.records.length == 0 &&
        brandInfo.totalRecords > 0 &&
        brandInfo.page > 1
      ) {
        const lastPageNo = Math.ceil(brandInfo.totalRecords / 10);
        this.setState({
          loader: true,
        });
        this.props.history.push({
          search: `?${queryString.stringify(
            { ...query, page: lastPageNo },
            { encode: false }
          )}`,
        });
        // this.getCampaignsListApi({...query, page: lastPageNo})
      }
    }
  });
}
export function fetchBrandDetail(brandId, query = {}) {
  return globalGetService('mno/brands/' + brandId, query);
}
export function getBrandsDetailApi(brandId, query = {}) {
  fetchBrandDetail(brandId, query).then((response) => {
    const brand = response.data;
    if (response.status >= 200 && response.status < 300) {
      this.setState((prevState) => ({
        ...prevState,
        brandInfo: brand,
        loader: false,
      }));
      this.checkSuspensionStatus();
    }
  });
}

export const getAllUsecasesTypes = async () => {
  const response = await globalGetService('enums/usecaseTypes');
  if (response.status >= 200 && response.status < 300) {
    return response.data;
  }
  return [];
};

export function findAllCspApi() {
  return globalGetService('mno/csp', { recordsPerPage: 1000 }).then(
    (response) => {
      if (response.status >= 200 && response.status < 300) {
        this.generateOptions(response.data.records, 'cspUid');
      }
    }
  );
}

export const getCampaignsByBrandApi = async (brandUid, query = {}) => {
  const response = await globalGetService('mno/campaigns', {
    ...query,
    brandUid,
  });
  if (response.status >= 200 && response.status < 300) {
    const campaignInfo = response.data;
    campaignInfo.records.forEach((campaign) => {
      campaign.createDate = convertTimeWithTimezone(
        campaign.createDate,
        undefined,
        US_DATE_FORMAT
      );
    });
    return campaignInfo;
  }
  return null;
};

export function getBrandsSuggestionsApi(query = {}, key) {
  globalGetService('mno/brands/suggestions', { ...query, limit: 20 }).then(
    (response) => {
      if (response && response.status >= 200 && response.status < 300) {
        this.setState((prevState) => ({
          filterObject: {
            ...prevState.filterObject,
            [key]: {
              ...prevState.filterObject[key],
              suggestions: response.data,
              suggestionLoader: false,
            },
          },
        }));
      }
    }
  );
}
export function getCspsSuggestionsApi(query = {}, key) {
  globalGetService('mno/csp/suggestions', { ...query, limit: 20 }).then(
    (response) => {
      if (response && response.status >= 200 && response.status < 300) {
        this.setState((prevState) => ({
          filterObject: {
            ...prevState.filterObject,
            [key]: {
              ...prevState.filterObject[key],
              suggestions: response.data,
              suggestionLoader: false,
            },
          },
        }));
      }
    }
  );
}
export function listVettingRecordsApi(brandUid) {
  globalGetService(`mno/brands/${brandUid}/vettings`, {
    vettingStatus: 'ACTIVE',
  }).then((response) => {
    if (response && response.status >= 200 && response.status < 300) {
      const vettingRecords = response.data;
      this.setState({ vettingRecords });
    }
  });
}

export const getAllCampaignUidsByBrandApi = async (
  brandUid,
  status,
  mnoStatus
) => {
  const params = {
    brandUid,
    status,
    mnoStatus,
  };
  const campaignUids = [];
  let page = 0;
  do {
    page = page + 1;
    const response = await globalGetService('mno/campaigns', {
      ...params,
      page,
    });
    if (response.status >= 200 && response.status < 300) {
      const { data } = response;
      for (const campaign of data.records) {
        campaignUids.push(campaign.uid);
      }
      if (data.records.length < data.recordsPerPage) {
        break;
      }
    } else {
      throw new Error('Unexpected error');
    }
  } while (true);
  return campaignUids;
};

export const updateBulkCampaignStatusApi = async (
  campaignUids,
  status,
  explanation,
  suspensionCategory
) => {
  const body = {
    campaignUid: campaignUids,
    status,
    explanation,
    suspensionCategory,
  };
  const response = await globalPutService(`mno/campaigns/status`, body);
  if (response.status >= 200 && response.status < 300) {
    return response.data;
  }
  throw new Error('Unexpected error');
};

export const findAllIdentityStatuses = async () => {
  const response = await globalGetService('enums/brandIdentityStatus');
  if (response.status >= 200 && response.status < 300) {
    return response.data;
  }
};

export const getSuspensionCategories = async () => {
  const response = await globalGetService('enums/suspensionCategories');
  if (response.status >= 200 && response.status < 300) {
    return response.data;
  }
};

export const getSuspensionCategoryOptions = async () => {
  const categories = await getSuspensionCategories();
  if (categories.length) {
    return categories.map((category) => ({
      label: category.name,
      value: category.id,
    }));
  } else {
    return [];
  }
};

export const getSuspensionRule = async (query = {}) => {
  const response = await globalGetService('mno/suspenisonRule', { ...query });
  if (response && response.status >= 200 && response.status < 300) {
    return response.data;
  }
  throw new Error('Unexpected API error', error);
};

export const getSuspensionPreviewCampaigns = async (query = {}) => {
  try {
    const response = await globalGetService(
      'mno/suspenisonRule/preview/matchedCampaign',
      {
        ...query,
      }
    );
    if (response && response.status >= 200 && response.status < 300) {
      return response.data;
    } else {
      return null;
    }
  } catch (error) {
    throw new Error('Unexpected API error', error);
  }
};

export const getSuspensionPreviewBrands = async (query = {}) => {
  try {
    const response = await globalGetService(
      'mno/suspenisonRule/preview/matchedBrand',
      {
        ...query,
      }
    );
    if (response && response.status >= 200 && response.status < 300) {
      return response.data;
    } else {
      return null;
    }
  } catch (error) {
    throw new Error('Unexpected API error', error);
  }
};

export const createSuspensionRule = async (payload) => {
  const response = await globalPostService(`mno/suspenisonRule`, payload);
  if (response && response.status >= 200 && response.status < 300) {
    return response.data;
  }
  throw new Error('Unexpected API error');
};

export const updateSuspensionRule = async (ruleId, payload) => {
  const response = await globalPutService(
    `mno/suspenisonRule/${ruleId}`,
    payload
  );
  if (response && response.status >= 200 && response.status < 300) {
    return response.data;
  }
  throw new Error('Unexpected API error');
};
