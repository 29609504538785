import React, { useState } from 'react';
import { Menu, MenuItem, Fade } from '@material-ui/core';
import CampaignBulkRejectModal from './CampaignBulkRejectModal';
import ExpandMoreOutlinedIcon from '@material-ui/icons/ExpandMoreOutlined';
import { S3_ASSETS_PATH } from '../../../../constants';
import { Button } from 'portal-commons';

export default function BulkActions(props) {
  const { selectedCampaigns } = props;
  const [option, setOption] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };
  const handleRequestClose = (e) => {
    setAnchorEl(null);
  };
  return (
    <>
      <div className="bulk-actions-wrapper">
        <div
          aria-owns={Boolean(anchorEl) ? 'simple-menu' : null}
          aria-haspopup="true"
          onClick={handleClick}
          // onMouseOver={handleClick}
          className="bulk-action-button"
        >
          <img src={`${S3_ASSETS_PATH}/images/MNO/bulk-action-icon.svg`} />
          <span>
            {option == 'APPROVED'
              ? 'Approve'
              : option == 'REJECTED'
              ? 'Reject'
              : 'Bulk Actions'}
          </span>
          <ExpandMoreOutlinedIcon className="dropdown-arrow" />
        </div>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleRequestClose}
          className="bulk-actions-menu"
          MenuListProps={
            {
              // onMouseLeave: handleRequestClose
            }
          }
          TransitionComponent={Fade}
        >
          <MenuItem
            className={option == 'APPROVED' ? 'selected' : ''}
            key={'0'}
            onClick={() => {
              setOption('APPROVED');
              setAnchorEl(null);
            }}
          >
            Approve
          </MenuItem>
          <MenuItem
            className={option == 'REJECTED' ? 'selected' : ''}
            key={'1'}
            onClick={() => {
              setOption('REJECTED');
              setAnchorEl(null);
            }}
          >
            Reject
          </MenuItem>
        </Menu>
        {selectedCampaigns.length ? (
          <p className="paragraph">{`${selectedCampaigns.length} selected`}</p>
        ) : null}

        <div className="apply-wrapper">
          <Button
            data-testid="bulkApplyButton"
            type="submit"
            portal="mno"
            shape="rounded"
            color="secondary"
            disabled={selectedCampaigns.length == 0 || props.loader || !option}
            onClick={() =>
              option == 'REJECTED'
                ? props.handleOpenRejectModal(true)
                : props.handleUpdateStatus(option)
            }
          >
            Apply
          </Button>
        </div>
      </div>
    </>
  );
}
