import {
  globalGetService,
  globalPutService,
  globalPostService,
  globalGetServiceV2,
} from '../../../utils/globalApiServices';
import { axiosInstanceV2 } from '../../../utils/apiInterceptor';
import {
  convertTimeWithTimezone,
  getTimezone,
  US_DATE_FORMAT,
} from '../../../utils/time';
import queryString from 'query-string';
import apiInstance from '../../../utils/apiInterceptor';

export function getCampaignsListApi(query = {}) {
  this.setState({ tableLoader: true });
  let queryUpdated = query;
  if (
    queryUpdated.status &&
    queryUpdated.status === 'EXPIRED' &&
    !this.props.location.pathname.includes('/review-campaigns') &&
    !queryUpdated.mnoStatus
  ) {
    queryUpdated.mnoStatus = 'REJECTED,REGISTERED,SUSPENDED';
  }
  globalGetService('mno/campaigns', { ...queryUpdated }).then((response) => {
    this.setState({ tableLoader: false });
    if (response.status >= 200 && response.status < 300) {
      const campaignInfo = {
        ...response.data,
        totalRecords: Math.min(response.data.totalRecords, 10000), // Cap total records as ElasticSearch returns up to 10,000 by default
        displayTotal: response.data.totalRecords, // But keep the original count for displaying on the top of campaign listing page
      };
      campaignInfo.records.forEach((campaign) => {
        campaign.createDate = convertTimeWithTimezone(
          campaign.createDate,
          undefined,
          US_DATE_FORMAT
        );
      });
      this.setState((prevState) => ({
        ...prevState,
        campaignInfo,
        loader: false,
        selectedCampaigns: [],
      }));
      if (
        campaignInfo.records.length == 0 &&
        campaignInfo.totalRecords > 0 &&
        campaignInfo.page > 1
      ) {
        const lastPageNo = Math.ceil(campaignInfo.totalRecords / 10);
        this.setState({
          loader: true,
        });
        this.props.history.push({
          search: `?${queryString.stringify(
            { ...query, page: lastPageNo },
            { encode: false }
          )}`,
        });
        // this.getCampaignsListApi({...query, page: lastPageNo})
      }
    }
  });
}

export function getCampaignDetailApi(campaignUid) {
  globalGetService(`mno/campaigns/${campaignUid}`).then((response) => {
    // console.log('response', response)
    if (response.status >= 200 && response.status < 300) {
      const campaign = response.data;
      campaign.createDate = convertTimeWithTimezone(
        campaign.createDate,
        undefined,
        US_DATE_FORMAT
      );
      this.setState((prevState) => ({
        ...prevState,
        campaign,
        loader: false,
      }));
    }
  });
}

export const getAllUsecasesTypes = async () => {
  const response = await globalGetService('enums/usecaseTypes');
  if (response.status >= 200 && response.status < 300) {
    return response.data;
  }
  return [];
};

export function findAllVerticalsApi() {
  globalGetService('enums/verticalTypes').then((response) => {
    if (response.status >= 200 && response.status < 300) {
      this.generateOptions(response.data, 'vertical');
    }
  });
}

export function findAllBrandsApi() {
  globalGetService('mno/brands', { recordsPerPage: 1000 }).then((response) => {
    if (response.status >= 200 && response.status < 300) {
      this.generateOptions(response.data.records, 'brandUid');
    }
  });
}

export function findAllCspApi() {
  globalGetService('mno/csp', { recordsPerPage: 1000 }).then((response) => {
    if (response.status >= 200 && response.status < 300) {
      this.generateOptions(response.data.records, 'cspUid');
    }
  });
}

export function updateCampaignStatusApi(campaignUid, body = {}) {
  return globalPutService(`mno/campaigns/${campaignUid}/status`, body);
}

export function fileComplaintApi(campaignUid, body) {
  return globalPostService(`mno/campaigns/${campaignUid}/complaint`, body);
}

export function listCampaignPhoneNumbers(campaignUid) {
  globalGetService(`mno/campaigns/${campaignUid}/phoneNumbers`).then(
    (response) => {
      if (response.status >= 200 && response.status < 300) {
        // response.data = [ "9544531840", "6543456789", "0987654321"]
        this.setState({
          phoneNumbers: response.data,
        });
      }
    }
  );
}

export const queryCampaignPhoneNumbers = async (id, query = {}) => {
  const response = await axiosInstanceV2.get(
    `mno/campaigns/${id}/phoneNumbers`,
    {
      params: query,
      validateStatus: null,
    }
  );
  if (response && response.status >= 200 && response.status < 300) {
    return response.data;
  } else {
    return null;
  }
};

export const getCampaignPhoneNumbersOnly = async (campaignUid, query = {}) => {
  const response = await globalGetServiceV2(
    `mno/campaigns/${campaignUid}/phoneNumbersOnly`,
    {
      ...query,
    }
  );
  if (response && response.status >= 200 && response.status < 300) {
    return response.data;
  } else {
    return null;
  }
};

export const downloadCampaignPhoneNumbers = async (campaignUid, query = {}) => {
  const response = await globalGetService(
    `mno/campaigns/${campaignUid}/phoneNumbers/csv`,
    { ...query, timezone: getTimezone() },
    'arraybuffer'
  );
  if (response && response.status >= 200 && response.status < 300) {
    return response.data;
  } else {
    return null;
  }
};

export const updateBulkCampaignStatusApi = async (body = {}) => {
  const response = await globalPutService(`mno/campaigns/status`, body);
  if (response?.ok) {
    return true;
  }
};

export function getBrandsSuggestionsApi(query = {}, key) {
  globalGetService('mno/brands/suggestions', { ...query, limit: 20 }).then(
    (response) => {
      if (response && response.status >= 200 && response.status < 300) {
        this.setState((prevState) => ({
          filterObject: {
            ...prevState.filterObject,
            [key]: {
              ...prevState.filterObject[key],
              suggestions: response.data,
              suggestionLoader: false,
            },
          },
        }));
      }
    }
  );
}
export function getCspsSuggestionsApi(query = {}, key) {
  globalGetService('mno/csp/suggestions', { ...query, limit: 20 }).then(
    (response) => {
      if (response && response.status >= 200 && response.status < 300) {
        this.setState((prevState) => ({
          filterObject: {
            ...prevState.filterObject,
            [key]: {
              ...prevState.filterObject[key],
              suggestions: response.data,
              suggestionLoader: false,
            },
          },
        }));
      }
    }
  );
}
export function getCampaignEventsListApi(query = {}) {
  this.setState({ tableLoader: true });
  globalGetService('mno/event', {
    ...query,
    ascendingOrder: false,
    sortField: 'createDate',
    campaignUid: this.props.match.params.id,
  }).then((response) => {
    this.setState({ tableLoader: false });
    if (response.status >= 200 && response.status < 300) {
      const eventInfo = response.data;
      eventInfo.records.forEach((event) => {
        event.createDate = convertTimeWithTimezone(event.createDate);
      });
      this.setState({ eventInfo });
      if (
        eventInfo.records.length === 0 &&
        eventInfo.totalRecords > 0 &&
        eventInfo.page > 1
      ) {
        const lastPageNo = Math.ceil(eventInfo.totalRecords / 10);
        this.props.history.push({
          search: `?${queryString.stringify(
            { ...query, page: lastPageNo },
            { encode: false }
          )}`,
        });
        // this.getCampaignsListApi({...query, page: lastPageNo})
      }
    }
  });
}

export const getCampaignAttachmentsApi = async (campaignUid, folder) => {
  const response = await globalGetService(
    `mno/attachments/campaign/${campaignUid}?folder=${encodeURIComponent(
      folder
    )}`
  );
  if (response.status >= 200 && response.status < 300) {
    return response.data;
  }
};

export const downloadAttachmentApi = async (attachmentUuid) => {
  const response = await globalGetService(
    `mno/attachments/download?attachmentUuid=${encodeURIComponent(
      attachmentUuid
    )}`,
    {},
    'arraybuffer'
  );
  if (response.status >= 200 && response.status < 300) {
    return response.data;
  }
};

export const downloadAttachmentToDesktop = async (attachmentUuid, fileName) => {
  const response = await globalGetService(
    `mno/attachments/download?attachmentUuid=${encodeURIComponent(
      attachmentUuid
    )}`,
    {},
    'blob'
  );
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();

  // Clean up and remove the link
  link.parentNode.removeChild(link);
};

export const getCampaignSharingChain = async (campaignUid) => {
  const response = await globalGetService(
    `mno/campaigns/${campaignUid}/sharingChain`
  );
  if (response.status >= 200 && response.status < 300) {
    return response.data;
  }
};

export const getCampaignMigrationInfo = async (
  campaignId,
  config = { silent: false }
) => {
  const reqUrl = `mno/campaigns/${campaignId}/migrateCnp`;
  let response;
  if (!!config.silent) {
    response = await apiInstance(reqUrl, {
      validateStatus: null,
    });
  } else {
    response = await globalGetService(reqUrl);
  }

  if (response.ok) {
    return response.data;
  }
};
