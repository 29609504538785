import React from 'react';
import { Grid } from '@material-ui/core';

export default function CspDetailItem(props) {
  return (
    <Grid
      container
      spacing={3}
      className="detail-item"
      data-testid="cspDetailItem"
    >
      <Grid
        item
        xs={props.xs ? props.xs : 4}
        data-testid={props.title && props.title.replace(/\s/g, '')}
      >
        <h3 className="paragraph">{props.title}:</h3>
      </Grid>
      <Grid
        item
        xs={props.xs ? props.xs : 8}
        data-testid={props.value && props.value.replace(/\s/g, '')}
      >
        {props.link ? (
          <h3 className="paragraph">
            <a href={props.link}>
              <span style={{ color: '#00698F', textDecoration: 'underline' }}>
                {props.value ? props.value : 'N/A'}
              </span>
            </a>
          </h3>
        ) : (
          <h3 className="paragraph">
            <span>{props.value ? props.value : 'N/A'}</span>
          </h3>
        )}
      </Grid>
    </Grid>
  );
}
