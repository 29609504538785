import React from 'react';
import { CspDetailItem } from '../../Csp/components';
import { Grid } from '@material-ui/core';

const CampaignResellerDetails = (props) => {
  const { resellerInfo = {} } = props;
  return (
    <Grid container data-testid="campaignResellerDetails">
      <Grid item xs={6}>
        <CspDetailItem
          title="Legal Company Name"
          value={resellerInfo?.companyName}
        />
        <CspDetailItem title="Email Address" value={resellerInfo?.email} />
      </Grid>
      <Grid item xs={6}>
        <CspDetailItem title="Reseller ID" value={resellerInfo?.uid} />
        <CspDetailItem title="Phone Number" value={resellerInfo?.phone} />
      </Grid>
    </Grid>
  );
};

export default CampaignResellerDetails;
