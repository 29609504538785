import React from 'react';
import { Container } from '@material-ui/core';
import { BoxV2 as Box } from 'portal-commons';
import { ApiKeysListing } from '../components';
import '../assets/integrations-module.scss';

export default function Integrations(props) {
  return (
    <section
      className="integrations-section page-content"
      data-testid="integration"
    >
      <Container maxWidth={false} className="integrations-container">
        <Box sx={{ mb: 15 }} className="title-block">
          <Box
            as="h2"
            sx={{
              fontSize: 27,
              fontWeight: 600,
              color: '#19262A',
              lineHeight: '32px',
            }}
          >
            Integrations
          </Box>
        </Box>
        <ApiKeysListing />
      </Container>
    </section>
  );
}
