import React from 'react';
import { S3_ASSETS_PATH } from '../../../../constants';
import CarrierItem from './CarrierItem';
import { Box } from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBullhorn,
  faCalendarDay,
  faShareNodes,
} from '@fortawesome/pro-regular-svg-icons';
export default function CampaignCarrierStatus(props) {
  const { campaign } = props;
  return (
    <div
      className={`carrier-status details`}
      data-testid="campaignCarrierStatus"
    >
      <div className="details-heading">
        <Box flexDirection="row" alignItems="center">
          <Box margin={{ right: 'xs' }}>
            <FontAwesomeIcon icon={faShareNodes} style={{ fontSize: '18px' }} />
          </Box>
          <Box>
            <span className="heading2">Carrier Status</span>
          </Box>
        </Box>
      </div>
      <ul className="list-unstyled campaign-operations">
        <li className="carrier-item-wrapper">
          <CarrierItem campaign={campaign} />
        </li>
      </ul>
    </div>
  );
}
