import { FC } from 'react';
import { BoxV2 as Box, Flex } from 'portal-commons';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';

const SectionBlock: FC & { Icon: typeof Icon } = ({ children }) => {
  return (
    <Box
      as="section"
      sx={{
        pt: '30px',
        pb: '35px',
        paddingX: 'xxl',
        border: '1px solid #C0CCD4',
        mb: 'xxl',
      }}
    >
      {children}
    </Box>
  );
};

const Icon: FC<
  Omit<FontAwesomeIconProps, 'size'> & { size?: 'xl' | 'lg' | 'md' | 'sm' }
> = ({ size = 'md', ...iconProps }) => {
  const FONT_SIZE_MAP: Record<string, string> = {
    xl: 'H600',
    lg: 'H500',
    md: 'H400',
    sm: 'H300',
  };
  return (
    <Flex
      sx={{
        alignItems: 'center',
        color: 't.black70',
        fontSize: FONT_SIZE_MAP[size] ?? FONT_SIZE_MAP.md,
      }}
    >
      <FontAwesomeIcon {...iconProps} />
    </Flex>
  );
};

SectionBlock.Icon = Icon;

export default SectionBlock;
