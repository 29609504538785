import { FC, Fragment } from 'react';
import { BoxV2 as Box, Flex } from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/pro-solid-svg-icons';

interface ISharingChain {
  carrier?: string;
  nodes: Array<SharingNode>;
}

interface SharingNode {
  role: string;
  uid: string;
  displayName: string;
  acceptOrElectDate?: string; // ISO string
  myDca?: boolean;
  proxyConnectorUid?: string;
  proxyConnectorName?: string;
}

const Arrow = () => (
  <Box
    sx={{
      width: '6px',
      height: '6px',
      borderTop: '1px solid #009B7C',
      borderRight: '1px solid #009B7C',
      transform: 'rotate(45deg)',
    }}
  />
);

const Node: FC<{
  role: string;
  displayName: string;
  uid: string;
  roleTestId?: string;
}> = ({ role, displayName, uid, roleTestId }) => (
  <Box data-testid="sharingChainRoleName" sx={{ width: '191px' }}>
    <Box
      data-testid={roleTestId}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '20px',
        fontSize: '12px',
        fontWeight: '500',
        lineHeight: '14px',
        color: '#FFFFFF',
        background: '#49535D',
      }}
    >
      {role}
    </Box>
    <Box
      title={displayName}
      data-testid={`sharingChain-${uid}`}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '20px',
        padding: '0 16px',
        fontSize: '12px',
        fontWeight: '500',
        lineHeight: '14px',
        color: '#FFFFFF',
        background: 'rgba(73, 83, 93, 0.6)',
        boxSizing: 'border-box',
        '& > span': {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        },
      }}
    >
      <span>{displayName || 'N/A'}</span>
    </Box>
  </Box>
);

const SharingChain: FC<ISharingChain> = ({ carrier, nodes }) => {
  const getNodeDisplayRole = (node: SharingNode, index: number) => {
    if (index > 0) {
      switch (node.role) {
        case 'CSP':
          return `CNP ${index}`;
        case 'DCA':
          if (!node.myDca) {
            return `CNP ${index}`;
          }
          return carrier ? `${carrier} DCA` : 'DCA';
      }
    }
    return node.role;
  };

  /* 
    myDca=false means it's not authorized to pass data to current MNO 
    there is some other authorized DCA not accept election 
  */
  const hasPendingElectedDca =
    nodes.length > 0 &&
    nodes[nodes.length - 1].role === 'DCA' &&
    !nodes[nodes.length - 1].myDca;

  return (
    <Box className="details" data-testid="sharingChain">
      <Flex
        sx={{
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Flex
          className="details-heading"
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            flex: '1',
          }}
        >
          <Box mr="xs">
            <FontAwesomeIcon icon={faLink} style={{ fontSize: '18px' }} />
          </Box>
          <h3 className="heading2">Connectivity Chain</h3>
        </Flex>
      </Flex>
      <Box
        sx={{
          display: 'inline-flex',
          columnGap: '10px',
          rowGap: '20px',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        {nodes.map((node, index) => (
          <Fragment key={node.uid}>
            {index > 0 && <Arrow />}
            {!!node.proxyConnectorUid && (
              <>
                <Node
                  role="Proxy Connector"
                  displayName={node.proxyConnectorName || ''}
                  uid={node.proxyConnectorUid}
                />
                <Arrow />
              </>
            )}
            <Node
              role={getNodeDisplayRole(node, index)}
              displayName={node.displayName}
              uid={node.uid}
            />
          </Fragment>
        ))}
        {hasPendingElectedDca && (
          <>
            <Arrow />
            <Node
              role={carrier ? `${carrier} DCA` : 'DCA'}
              displayName="PENDING"
              roleTestId="sharingChainCarrier"
              uid="pending"
            />
          </>
        )}
      </Box>
    </Box>
  );
};

export default SharingChain;
