import { DEV_BASE_URL } from '../constants';
import axios from 'axios';
import { toastFlashMessage } from '../utils';

const axiosInstance = axios.create();
axiosInstance.defaults.baseURL = DEV_BASE_URL;

const requestOnFulfilled = (config) => {
  const oktaTokenStorage = JSON.parse(
    localStorage.getItem('okta-token-storage')
  );
  if (oktaTokenStorage) {
    config.headers = {
      ...config.headers,
      Authorization: `Bearer ${oktaTokenStorage.accessToken.accessToken}`,
    };
  }
  return config;
};

const requestOnRejected = (error) => {
  return Promise.reject(error);
};

const responseOnFulfilled = (response) => {
  if (response.status >= 200 && response.status < 300) {
    response.ok = true;
  }
  return response;
};

const responseOnRejected = (error) => {
  // console.log('Api error', error.response);
  if (error.response && error.response.status < 500) {
    if (error.response.data.error) {
      const responseError = error.response.data.error;
      const keyName = Object.keys(responseError)[0];
      // const errorMsg = keyName ? `${keyName} ${error.response.data.error[keyName][0]}`: error.response.data.error[keyName][0]
      const errorMsg =
        typeof responseError === 'string'
          ? responseError
          : responseError[keyName][0];
      if (
        ['phone', 'brandUid', 'campaignUid'].includes(keyName) &&
        window.location.pathname.includes('/dashboard')
      ) {
        // console.log('inside this')
      } else if (
        errorMsg &&
        errorMsg.includes(
          'HTTP 400, Okta E0000001 (Api validation failed: password - password: Password requirements were not met. Password requirements: at least 12 characters, a lowercase letter, an uppercase letter, a number, a symbol, no parts of your username, does not include your first name, does not include your last name. Your password cannot be any of your last 5 passwords.)'
        )
      ) {
        toastFlashMessage(`Password requirements are not met`, 'error');
      } else {
        toastFlashMessage(errorMsg, 'error');
      }
    }
  } else {
    toastFlashMessage(
      'Unable to process the request due to technical error',
      'error'
    );
  }
  return error.response;
};

axiosInstance.interceptors.request.use(requestOnFulfilled, requestOnRejected);
axiosInstance.interceptors.response.use(
  responseOnFulfilled,
  responseOnRejected
);

export const axiosInstanceV2 = axios.create();
axiosInstanceV2.defaults.baseURL = DEV_BASE_URL.replace('api/v1', 'api/v2');
axiosInstanceV2.interceptors.request.use(requestOnFulfilled, requestOnRejected);
axiosInstanceV2.interceptors.response.use(
  responseOnFulfilled,
  responseOnRejected
);

export default axiosInstance;
