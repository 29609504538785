import { format } from 'date-fns';

// Format timestamp to MM/dd/yyyy string
export const formatTimestampToDate = (timestamp: number) => {
  return format(timestamp, 'MM/dd/yyyy');
};

// Convert MM/dd/yyyy string to yyyy-MM-dd string
export const convertDateFormatForApi = (date: string) => {
  const year = date.slice(6, 10);
  const month = date.slice(0, 2);
  const day = date.slice(3, 5);
  return `${year}-${month}-${day}`;
};
