import React, { useState, useEffect } from 'react';
import { BoxV2 as Box, Flex } from 'portal-commons';
import { getCampaignTopCsps, getCampaignTopUsecases } from '../../apis';
import { DateRangePicker } from '../../../../../shared_elements';
import DashboardChart from '../DashboardChart';
import { format, sub } from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/pro-regular-svg-icons';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  GradientColorStopObject,
  LinearGradientColorObject,
  Options,
  OptionsOverflowValue,
  SeriesOptionsType,
} from 'highcharts';
import { TopCsp, TopUsecase } from '../../types';
import { dateRangePresets } from '../../utils';

const color: {
  [key: number]: {
    linearGradient: LinearGradientColorObject;
    stops: Array<GradientColorStopObject>;
  };
} = {
  0: {
    linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
    stops: [{ 0: 0, 1: 'rgba(0, 105, 143, 0.6)' }],
  },
  1: {
    linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
    stops: [{ 0: 0, 1: 'rgba(0, 105, 143, 0.5)' }],
  },
  2: {
    linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
    stops: [{ 0: 0, 1: 'rgba(0, 105, 143, 0.4)' }],
  },
  3: {
    linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
    stops: [{ 0: 0, 1: 'rgba(0, 105, 143, 0.3)' }],
  },
  4: {
    linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
    stops: [{ 0: 0, 1: 'rgba(0, 105, 143, 0.2)' }],
  },
};
const options = {
  chart: {
    type: 'bar',
    spacing: [5, 10, 0, 10],
    height: 100,
  },
  title: {
    text: '',
  },
  series: [],
  credits: {
    enabled: false,
  },
  xAxis: {
    categories: [],
    title: {
      text: null,
    },
    lineWidth: 0,
    labels: {
      y: 3,
      style: {
        color: '#666E71',
        fontFamily: 'Roboto',
        fontSize: '10px',
        lineHeight: '10px',
        fontWeight: '600',
      },
    },
  },
  yAxis: {
    gridLineColor: 'transparent',
    title: {
      text: '',
    },
    labels: {
      overflow: 'justify' as OptionsOverflowValue,
      style: {
        color: '#666E71',
        fontFamily: 'Roboto',
        fontSize: '12px',
        fontWeight: '600',
      },
    },
  },
  plotOptions: {
    series: {
      // general options for all series
    },
    bar: {
      grouping: false,
    },
  },
};

interface DateRange {
  startDate: string;
  endDate: string;
}
interface ChartProps extends DateRange {
  options?: Options;
}

const CampaignChartsSection: React.FC = () => {
  const [currChart, setCurrChart] = useState<'ByUsecase' | 'ByCsp'>('ByCsp');
  const defaultDateRange = {
    startDate: format(sub(new Date(), { days: 7 }), 'yyyy-MM-dd'),
    endDate: format(new Date(), 'yyyy-MM-dd'),
  };
  const [campaignsByCsp, setCampaignsByCsp] =
    useState<ChartProps>(defaultDateRange);
  const [campaignsByUsecase, setCampaignsByUsecase] =
    useState<ChartProps>(defaultDateRange);

  const changeChart = () => {
    if (currChart === 'ByCsp') {
      setCurrChart('ByUsecase');
    } else {
      setCurrChart('ByCsp');
    }
  };

  const handleChartChange = (date: DateRange) => {
    const currDateRange =
      currChart === 'ByCsp' ? campaignsByCsp : campaignsByUsecase;
    if (
      date.startDate !== currDateRange.startDate ||
      date.endDate !== currDateRange.endDate
    ) {
      const newChartProps = {
        startDate: date.startDate,
        endDate: date.endDate,
        options: undefined,
      };
      if (currChart === 'ByCsp') {
        setCampaignsByCsp(newChartProps);
        fetchCampaignTopCsps(date.startDate, date.endDate);
      } else {
        setCampaignsByUsecase(newChartProps);
        fetchCampaignTopUsecases(date.startDate, date.endDate);
      }
    }
  };

  const createChartSeries = (
    array: TopCsp[] | TopUsecase[],
    type: 'usecase' | 'cspName'
  ) => {
    const series: Array<SeriesOptionsType> = [];
    const categories: string[] = [];
    array.forEach((item, index) => {
      const data: number[] = new Array(array.length).fill(0);
      const category =
        type === 'cspName'
          ? (item as TopCsp).cspName
          : (item as TopUsecase).usecase;
      data[index] = item.count;
      series.push({
        showInLegend: false,
        name: '',
        data,
        type: 'bar',
        color: color[index],
        pointWidth: 10,
      });
      categories.push(category);
    });
    return {
      ...options,
      series,
      xAxis: {
        ...options.xAxis,
        categories,
        height: categories.length * 15,
      },
    };
  };

  const fetchCampaignTopCsps = async (startDate: string, endDate?: string) => {
    const data = await getCampaignTopCsps({ startDate, endDate });
    if (data) {
      const dateRange = endDate
        ? { startDate, endDate }
        : { ...campaignsByCsp };
      const updatedChartProps = {
        ...dateRange,
        options: createChartSeries(data, 'cspName'),
      };
      setCampaignsByCsp(updatedChartProps);
    }
  };

  const fetchCampaignTopUsecases = async (
    startDate: string,
    endDate?: string
  ) => {
    const data = await getCampaignTopUsecases({ startDate, endDate });
    if (data) {
      const dateRange = endDate
        ? { startDate, endDate }
        : { ...campaignsByUsecase };
      const updatedChartProps = {
        ...dateRange,
        options: createChartSeries(data, 'usecase'),
      };
      setCampaignsByUsecase(updatedChartProps);
    }
  };

  useEffect(() => {
    fetchCampaignTopCsps(campaignsByCsp.startDate);
    fetchCampaignTopUsecases(campaignsByUsecase.startDate);
  }, []);

  return (
    <Box
      sx={{
        p: 10,
        height: 142,
        minWidth: 385,
        border: '1px solid #B3B7B8',
        borderRadius: 16,
        position: 'relative',
        flex: 2,
      }}
      data-testid="dashboardCampaignChartsSection"
    >
      <Flex
        sx={{ flexDirection: 'row', gap: 4, float: 'left' }}
        data-testid="dashboardCampaignChartsSwitcher"
      >
        <AngleIcon
          icon={faAngleLeft}
          onClick={changeChart}
          data-testid="switchLeft"
        />
        <AngleIcon
          icon={faAngleRight}
          onClick={changeChart}
          data-testid="switchRight"
        />
      </Flex>
      {currChart === 'ByCsp' ? (
        <CampaignBarCharts
          title="Campaigns by CSP"
          testId="CampaignsByCsp"
          dateRange={campaignsByCsp}
          chartOptions={campaignsByCsp.options}
          handleDateRangeChange={handleChartChange}
        />
      ) : (
        <CampaignBarCharts
          title="Campaigns by Use Case"
          testId="CampaignsByUseCase"
          dateRange={campaignsByUsecase}
          chartOptions={campaignsByUsecase.options}
          handleDateRangeChange={handleChartChange}
        />
      )}
    </Box>
  );
};

const AngleIcon: React.FC<{
  icon: IconProp;
  onClick?: () => void;
}> = ({ icon, onClick, ...props }) => {
  return (
    <Flex
      sx={{
        border: '1px solid #B3B7B8',
        borderRadius: 4,
        alignItems: 'center',
        justifyContent: 'center',
        width: 18,
        height: 18,
        cursor: 'pointer',
      }}
      onClick={onClick}
      {...props}
    >
      <FontAwesomeIcon
        icon={icon}
        style={{ color: '#00576B', fontWeight: 900 }}
      />
    </Flex>
  );
};

const CampaignBarCharts: React.FC<{
  title: string;
  testId?: string;
  dateRange: DateRange;
  chartOptions?: Options;
  handleDateRangeChange: (date: DateRange) => void;
}> = ({
  title,
  testId = 'BarCharts',
  dateRange,
  chartOptions,
  handleDateRangeChange,
}) => {
  return (
    <Box sx={{ height: '100%' }} data-testid={`dashboard${testId}`}>
      <Box
        as="h3"
        sx={{
          position: 'relative',
          top: 1,
          ml: 35,
          mr: 175,
          textAlign: 'center',
          color: '#49535D',
          fontSize: 14,
          fontWeight: 800,
          lineHeight: 'normal',
        }}
      >
        {title}
      </Box>
      <Box
        sx={{
          position: 'absolute',
          right: 13,
          top: 4,
          width: 174,
        }}
      >
        <DateRangePicker
          presets={dateRangePresets}
          dateRange={dateRange}
          handleChange={handleDateRangeChange}
        />
      </Box>
      {chartOptions ? (
        <Box sx={{ px: 10, pt: 5, height: 'calc(100% - 10px)' }}>
          <DashboardChart key={title} options={chartOptions} />
        </Box>
      ) : (
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          data-testid="dashboardCampaignChartsLoading"
        >
          <FontAwesomeIcon
            spin
            icon={faSpinnerThird}
            style={{ fontSize: '40px', color: '#00698F' }}
          />
        </Box>
      )}
    </Box>
  );
};

export default CampaignChartsSection;
