import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableRow,
  Paper,
  TableHead,
  TableSortLabel,
  LinearProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
// import { isMobile } from 'react-device-detect'
const ColorLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: '#fff',
  },
  barColorPrimary: {
    background:
      'linear-gradient(90deg, #054751 0%, #006FA0 18.3%, #0071A0 19.2%, #0291A8 32.5%, #03A5AE 43.6%, #04ADB0 51.1%, #F5C245 100%)',
  },
})(LinearProgress);

export const useStylesTable = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    boxShadow: 'none',
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));
function CustomTable(props) {
  const classes = useStylesTable();
  const {
    headRows,
    filter,
    createSortHandler,
    tableData,
    pagination = {},
  } = props;
  const emptyRows = Object.keys(pagination).length
    ? pagination.rowsPerPage -
      Math.min(pagination.rowsPerPage, pagination.recordsLength)
    : null;
  const rejectedFlag = headRows.find((item) => {
    return item.id == 'id';
  });
  return (
    <div className={classes.root}>
      <Paper className={classes.paper} style={{ margin: '0px' }}>
        <div className={classes.tableWrapper}>
          <Table
            className={`${classes.table} ${props.className}`}
            aria-labelledby="tableTitle"
            size="medium"
          >
            <TableHead>
              <TableRow style={{ height: 32 }}>
                {headRows.map((row) => (
                  <TableCell
                    key={row.id}
                    align={'left'}
                    padding={'default'}
                    sortDirection={
                      filter.sortField === row.id
                        ? filter.ascendingOrder
                          ? 'asc'
                          : 'desc'
                        : false
                    }
                  >
                    {row.sortOption ? (
                      <TableSortLabel
                        active={filter.sortField === row.id}
                        direction={filter.ascendingOrder ? 'asc' : 'desc'}
                        onClick={() => createSortHandler(row.id)}
                      >
                        {' '}
                        {row.label}{' '}
                      </TableSortLabel>
                    ) : (
                      <span> {row.label} </span>
                    )}
                  </TableCell>
                ))}
              </TableRow>
              {props.tableLoader ? (
                <TableRow>
                  <TableCell colSpan={8} className="loader">
                    <ColorLinearProgress className={classes.margin} />
                  </TableCell>
                </TableRow>
              ) : null}
            </TableHead>
            <TableBody>
              {tableData}
              {emptyRows > 0 && (
                <TableRow style={{ height: 48 * emptyRows }}>
                  {!props.tableLoader ? (
                    <TableCell
                      colSpan={8}
                      className="no-records-found"
                      style={
                        props.records.total != 0 ? { background: '#fff' } : {}
                      }
                    >
                      {props.records.total == 0 ? (
                        <>
                          <img
                            src={
                              require('../../assets/images/NodataFound.svg')
                                .default
                            }
                            alt=""
                          />
                          <p>No data found</p>{' '}
                        </>
                      ) : null}
                    </TableCell>
                  ) : null}
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        {Object.keys(pagination).length ? (
          <TablePagination
            rowsPerPageOptions={
              pagination.rowsPerPageOptions
                ? pagination.rowsPerPageOptions
                : [5, 10, 25]
            }
            component="div"
            count={pagination.count}
            rowsPerPage={pagination.rowsPerPage}
            page={pagination.page}
            backIconButtonProps={{ 'aria-label': 'previous page' }}
            nextIconButtonProps={{ 'aria-label': 'next page' }}
            onChangePage={props.handleChangePage}
            onChangeRowsPerPage={props.handleChangeRowsPerPage}
          />
        ) : null}
      </Paper>
    </div>
  );
}
CustomTable.propTypes = {
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired,
  handleRequestSort: PropTypes.func,
  createSortHandler: PropTypes.func.isRequired,
  filter: PropTypes.object.isRequired,
  headRows: PropTypes.array.isRequired,
  tableData: PropTypes.array.isRequired,
  pagination: PropTypes.object,
};
export default CustomTable;
