import React from 'react';
import { Icon, Checkbox, makeStyles } from '@material-ui/core';
import { S3_ASSETS_PATH } from '../../constants';
const useStyles = makeStyles({
  imageIcon: {
    height: '100%',
    width: '100%',
  },
  iconRoot: {
    textAlign: 'center',
    width: 18,
    height: 18,
  },
});
export default function CheckBox({
  checked,
  handleCheckbox,
  id,
  name,
  disabled = false,
}) {
  // console.log('checked', checked)
  const classes = useStyles();
  return (
    <Checkbox
      data-testid={id}
      checked={checked}
      icon={
        <Icon classes={{ root: classes.iconRoot }}>
          <img
            className={classes.imageIcon}
            src={`${S3_ASSETS_PATH}/images/MNO/unchecked-new.svg`}
          />
        </Icon>
      }
      checkedIcon={
        <Icon classes={{ root: classes.iconRoot }}>
          <img
            className={classes.imageIcon}
            src={`${S3_ASSETS_PATH}/images/MNO/checked-new-mno.svg`}
          />
        </Icon>
      }
      id={id}
      onChange={(e) => handleCheckbox(e)}
      className="custom-checkbox"
      name={name}
      disabled={disabled}
    />
  );
}
