import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { SideNavigation as BaseSideNavigation } from 'portal-commons';
import { S3_ASSETS_PATH } from '../../constants';
import { isMobile } from 'react-device-detect';
import { globalGetService } from '../../utils/globalApiServices';
import datadog from '../../utils/datadog';
import {
  faHouse,
  faBullhorn,
  faTags,
  faCalendarDay,
  faMessageCaptions,
  faGearCode,
} from '@fortawesome/pro-regular-svg-icons';

const SIDE_NAV_ITEMS = [
  {
    title: 'Dashboard',
    icon: faHouse,
    pathname: '/dashboard',
    'data-testid': 'sideNavigationDashboardButton',
  },
  {
    title: 'Campaigns',
    icon: faBullhorn,
    pathname:
      '/campaigns?ascendingOrder=false&sortField=createDate&status=ACTIVE',
    'data-testid': 'sideNavigationCampaignsButton',
  },
  {
    title: 'Brands',
    icon: faTags,
    pathname: '/brands',
    'data-testid': 'sideNavigationBrandButton',
  },
  {
    title: 'CSPs',
    icon: faMessageCaptions,
    pathname: '/csps',
    'data-testid': 'sideNavigationCspButton',
  },
  {
    title: 'Events',
    icon: faCalendarDay,
    pathname: '/events',
    'data-testid': 'sideNavigationEventsButton',
  },
  {
    title: 'Integrations',
    icon: faGearCode,
    pathname: '/integrations',
    'data-testid': 'sideNavigationIntegrationsButton',
  },
];

export default function SideNavigation(props) {
  const [lastLogin, setLastLogin] = useState('');

  useEffect(() => {
    if (localStorage.getItem('user') && !lastLogin) {
      const lastLoginTime = JSON.parse(localStorage.getItem('user')).lastLogin;
      setLastLogin(lastLoginTime);
    }
    if (!localStorage.getItem('user') && !lastLogin) {
      globalGetService('user').then((response) => {
        const user = response.data;
        !datadog.hasUser() && datadog.setUser(user);
        localStorage.setItem('user', JSON.stringify(user));
        setLastLogin(response.data ? response.data.lastLogin : '');
      });
    }
  }, []);
  const { path } = props;

  return (
    <>
      <div
        data-testid="sideNavigationUserButton"
        className="primary-side-menu"
        style={
          path.includes('/user') ? (isMobile ? {} : { display: 'none' }) : {}
        }
      >
        {isMobile ? (
          <div className="header-logo">
            <Link to="/">
              <img
                src={`${S3_ASSETS_PATH}/images/Campaignregistry.svg`}
                alt="logo"
              />
            </Link>
          </div>
        ) : null}
        <BaseSideNavigation
          linkComponent={Link}
          portal="mno"
          location={{ pathname: path }}
          items={!path.includes('/user') ? SIDE_NAV_ITEMS : []}
          lastLoginTime={lastLogin}
        />
      </div>
      {/* <div className="sidebar-gradient"></div> */}
    </>
  );
}
