import React from 'react';
import Grid from '@material-ui/core/Grid';
import { ResetPasswordForm } from '../components';
import Header from '../../../shared_elements/components/Header';
import { withRouter } from 'react-router-dom';
function ResetPassword(props) {
  return (
    <section className="user-authentication-section">
      <Header title="WELCOME TO THE CAMPAIGN REGISTRY"></Header>
      <Grid
        container
        spacing={0}
        alignItems="center"
        justify="center"
        className="user-form-wrapper"
        style={{ height: window.innerHeight - 80 }}
      >
        <ResetPasswordForm />
      </Grid>
    </section>
  );
}
export default withRouter(ResetPassword);
