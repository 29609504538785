import { BoxV2 as Box, Button, Flex } from 'portal-commons';
import { FunctionComponent } from 'react';

import Modal from './Modal';
import { User } from '../types';

interface UserDeleteModalProps {
  open: boolean;
  disabled: boolean;
  user?: User;
  onClose: () => void;
  onSubmit: () => void;
}

const UserDeleteModal: FunctionComponent<UserDeleteModalProps> = ({
  open,
  disabled,
  user,
  onClose,
  onSubmit,
}) => {
  return (
    <Modal open={open} data-testid="userDeleteModal">
      <Flex sx={{ flexDirection: 'column', rowGap: '8px' }}>
        <Box
          sx={{
            paddingTop: '12px',
            textAlign: 'center',
            color: '#49535D',
            fontFamily: 'Roboto',
            fontWeight: 400,
            fontSize: '20px',
            lineHeight: '23px',
          }}
          data-testid="userDeleteModal"
        >
          Do you want to&nbsp;
          <span style={{ fontWeight: 700 }}>permanently delete</span> user&nbsp;
          <span data-testid="fullName">{user?.profile.fullName}</span>?
        </Box>
        <Flex
          sx={{
            flexDirection: 'row',
            justifyContent: 'center',
            columnGap: '20px',
            padding: '24px 0 40px',
          }}
        >
          <Button
            variant="outline"
            onClick={onClose}
            data-testid="cancelButton"
          >
            Cancel
          </Button>
          <Button
            disabled={disabled}
            onClick={onSubmit}
            data-testid="deleteButton"
          >
            Delete
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default UserDeleteModal;
