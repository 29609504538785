import { Flex } from 'portal-commons';
import { FunctionComponent } from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  convertTimeWithTimezone,
  US_DATE_FORMAT,
} from '../../../../utils/time';
import { User } from '../types';

interface UserListingRowProps {
  role?: string;
  user: User;
  onDelete?: () => void;
}

const NullishFallback = 'N/A';

const UserListingRow: FunctionComponent<UserListingRowProps> = ({
  user,
  onDelete,
}) => {
  return (
    <TableRow data-testid="userListingRow">
      <TableCell data-testid="fullName">{user.profile.fullName}</TableCell>
      <TableCell data-testid="email">{user.profile.email}</TableCell>
      <TableCell data-testid="created">
        {!!user.created
          ? convertTimeWithTimezone(user.created, undefined, US_DATE_FORMAT)
          : NullishFallback}
      </TableCell>
      <TableCell data-testid="lastLogin">
        {!!user.lastLogin
          ? convertTimeWithTimezone(user.lastLogin, undefined, US_DATE_FORMAT)
          : NullishFallback}
      </TableCell>
      <TableCell>
        <Flex
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
            columnGap: '7px',
            fontWeight: 400,
            fontSize: '12px',
            lineHeight: '12px',
          }}
          data-testid="deleteUserButton"
        >
          <FontAwesomeIcon
            style={{ cursor: 'pointer' }}
            icon={faTrash}
            onClick={onDelete}
          />
        </Flex>
      </TableCell>
    </TableRow>
  );
};

export default UserListingRow;
