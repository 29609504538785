import React, { useEffect, useMemo, useState } from 'react';
import queryString from 'query-string';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  SearchArea,
  CampaignReviewSection,
  CampaignSuspendSection,
  CampaignChartsSection,
} from '../components';
import {
  getBrandNameSuggestions,
  getCampaignList,
  queryEventList,
} from '../apis';
import { EventData } from '../../Events/types';
import { getAllEventTypes } from '../../Events/apiServices';
import { MAX_EVENT_RECORDS } from '../../Events/constants';
import EventsListingRow from '../../Events/components/EventsListingRow';
import '../../Events/assets/events-listing-module.scss';
import {
  BoxV2 as Box,
  Flex,
  Table,
  FilterType,
  TableFilter,
  WithFiltersConfig,
} from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faShareNodes,
  faCalendarDay,
} from '@fortawesome/pro-regular-svg-icons';
import { Loader } from '../../../../shared_elements';
import { Pagination } from '../../../../utils/types';
import { toastFlashMessage } from '../../../../utils';
import { withFilters } from '../../../../hocs';
import { SearchResult } from '../types';
import { dateRangePresets, Today } from '../utils';

export const FILTER_CONFIG = {
  eventType: {
    type: FilterType.Dropdown,
    options: [],
    label: 'Event Type',
  },
  campaignUid: {
    type: FilterType.Text,
    label: 'Campaign ID',
    placeholder: 'Enter Campaign ID',
    width: 180,
  },
  brandUid: {
    type: FilterType.Text,
    label: 'Brand ID',
    placeholder: 'Enter Brand ID',
    width: 180,
  },
  brandName: {
    type: FilterType.Text,
    label: 'Brand Name',
    placeholder: 'Enter Brand Name',
    suggestions: [],
    width: 180,
  },
  dateRange: {
    type: FilterType.DateRange,
    label: 'Date Range',
    placeholder: 'Select Date Range',
    delayUpdate: false,
    dateRangePresets: [
      {
        label: 'Today',
        start: Today,
        end: Today,
      },
      ...dateRangePresets.slice(1),
    ],
  },
};

export const headRows = [
  { label: 'EVENT TYPE', id: 'eventType', sortable: true },
  { label: 'SOURCE', id: 'categoryType', sortable: false },
  { label: 'CAMPAIGN ID', id: 'campaignUid', sortable: true },
  { label: 'BRAND ID', id: 'brandUid', sortable: true },
  { label: 'DATE & TIME', id: 'createDate', sortable: true },
  { label: 'DESCRIPTION', id: 'description', sortable: false },
];

interface DashboardProps extends WithFiltersConfig {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filter: any;
}

const EmptyPaginationData: Pagination<EventData> = {
  records: [],
  page: 1,
  recordsPerPage: 10,
  totalRecords: 0,
};

const Dashboard: React.FC<DashboardProps> = ({ filter }) => {
  const history = useHistory();
  const location = useLocation();
  const [eventInfo, setEventInfo] =
    useState<Pagination<EventData>>(EmptyPaginationData);
  const [additionalFilters, setAdditionalFilters] = useState<{
    sortField: string;
    ascendingOrder: boolean;
    [key: string]: string | boolean | number | undefined;
  }>({ sortField: 'createDate', ascendingOrder: false });
  const [loader, setLoader] = useState(true);
  const [tableLoader, setTableLoader] = useState(true);
  const [registeredCount, setRegisteredCount] = useState(0);
  const [reviewCount, setReviewCount] = useState(0);
  const [suspendedCount, setSuspendedCount] = useState(0);

  const restrictPagination = useMemo(() => {
    const totalRecords =
      eventInfo.totalRecords > MAX_EVENT_RECORDS
        ? MAX_EVENT_RECORDS
        : eventInfo.totalRecords;

    return {
      count: Math.ceil(totalRecords / eventInfo.recordsPerPage),
      rowsPerPage: eventInfo.recordsPerPage,
      page: eventInfo.page,
      totalRecords,
    };
  }, [eventInfo]);

  const fetchFilterOptions = async () => {
    const { setOptions } = filter;
    const eventTypes = await getAllEventTypes();
    if (eventTypes.length === 0) return;
    setOptions(
      'eventType',
      eventTypes.map((item: { id: string; displayName: string }) => ({
        label: item.id,
        value: item.id,
      }))
    );
  };

  const fetchCampaignCounts = async (mnoStatus = 'REGISTERED') => {
    const campaigns = await getCampaignList({ mnoStatus });
    if (campaigns) {
      switch (mnoStatus) {
        case 'REVIEW':
          setReviewCount(campaigns.totalRecords);
          break;
        case 'SUSPENDED':
          setSuspendedCount(campaigns.totalRecords);
          break;
        case 'REGISTERED':
        default:
          setRegisteredCount(campaigns.totalRecords);
          break;
      }
    }
  };

  const handleSearchNotFound = (result: SearchResult) => {
    history.push(`/dashboard/not-found?${queryString.stringify(result)}`);
  };

  const parseSearchParams = (params = '') => {
    let updatedFilter = additionalFilters;
    const { appliedFilters } = filter;
    const queries = queryString.parse(params, { decode: true });
    if (!Object.keys(queries).length) {
      updatedFilter = { sortField: '', ascendingOrder: false };
    } else if (queries.sortField || queries.ascendingOrder) {
      updatedFilter = {
        sortField: queries.sortField as string,
        ascendingOrder: JSON.parse(queries.ascendingOrder as string),
      };
    }
    setAdditionalFilters(updatedFilter);

    return {
      ...updatedFilter,
      ...appliedFilters,
      page: queries.page ? queries.page : 1,
    };
  };

  const fetchEventList = async (query = {}) => {
    setTableLoader(true);
    const eventData = await queryEventList(query);
    if (eventData) {
      setEventInfo(eventData);
      if (
        eventData.records.length === 0 &&
        eventData.totalRecords > 0 &&
        eventData.page > 1
      ) {
        const lastPageNo = Math.ceil(
          eventData.totalRecords /
            (eventInfo.recordsPerPage ?? EmptyPaginationData.recordsPerPage)
        );
        writeQueryString({ ...query, page: lastPageNo });
      }
    }
    setTableLoader(false);
  };

  const handleChangePage = (newPage: number) => {
    const { appliedFilters } = filter;

    writeQueryString({
      ...additionalFilters,
      ...appliedFilters,
      page: newPage,
    });
  };

  const handleSorting = (sortField: string) => {
    const { appliedFilters } = filter;
    const updatedAdditionalFilters = {
      ...additionalFilters,
      ascendingOrder: !additionalFilters.ascendingOrder,
      sortField,
    };
    setAdditionalFilters(updatedAdditionalFilters);

    if (eventInfo.totalRecords) {
      writeQueryString({
        ...additionalFilters,
        ...appliedFilters,
        page: eventInfo.page,
        sortField,
        ascendingOrder: !additionalFilters.ascendingOrder,
      });
    }
  };

  const writeQueryString = (searchParams = {}) => {
    history.push({ search: `?${queryString.stringify(searchParams)}` });
  };

  useEffect(() => {
    if (location.search) {
      const params = parseSearchParams(location.search);
      fetchEventList(params);
    }
  }, [location.search]);

  useEffect(() => {
    if (!loader) {
      const loginFlag = localStorage.getItem('loginSuccess');
      if (loginFlag === '1') {
        toastFlashMessage('Logged in successfully', 'success');
        localStorage.removeItem('loginSuccess');
      }
    }
  }, [loader]);

  useEffect(() => {
    const fetchAllData = async () => {
      const params = location.search
        ? parseSearchParams(location.search)
        : { ...additionalFilters };
      try {
        await Promise.all([
          fetchCampaignCounts('REGISTERED'),
          fetchCampaignCounts('REVIEW'),
          fetchCampaignCounts('SUSPENDED'),
          fetchFilterOptions(),
          fetchEventList(params),
        ]);
      } finally {
        setLoader(false);
      }
    };
    fetchAllData();
  }, []);

  return (
    <Box sx={{ px: 'xxl', py: 'l', width: '100%' }}>
      {loader ? (
        <Loader />
      ) : (
        <>
          <HeaderSection count={registeredCount} />
          <SearchArea onNoSearchResult={handleSearchNotFound} />
          <Flex
            sx={{
              alignItems: 'flex-start',
              width: '100%',
              mt: 20,
              gap: 14,
              justifyContent: 'space-between',
              flexWrap: 'wrap',
            }}
          >
            <CampaignReviewSection count={reviewCount} />
            <CampaignSuspendSection count={suspendedCount} />
            <CampaignChartsSection />
          </Flex>
          <Flex
            sx={{
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              mt: 'xl',
              mb: 's',
            }}
          >
            <FontAwesomeIcon
              icon={faCalendarDay}
              style={{ fontSize: 26, color: '#CACC4F', marginRight: 8 }}
            />
            <Box
              sx={{
                fontSize: 'H600',
                fontWeight: 600,
                lineHeight: 'normal',
                color: 't.black80',
              }}
            >
              Events
            </Box>
          </Flex>
          <Box
            className="events-filter-wrapper"
            sx={{ mb: 's', borderColor: '#B3B7B8' }}
          >
            <TableFilter
              portal="mno"
              configs={filter.configs}
              candidateValues={filter.candidateFilters}
              appliedValues={filter.appliedFilters}
              onCandidateValuesChange={filter.handleEdit}
              onAppliedValuesChange={filter.handleApply}
              className="filters"
              data-testid="events"
            />
          </Box>
          <Box sx={{ width: '100%' }} className="events-table-wrapper">
            <Table
              testId="eventsTable"
              headRows={headRows}
              emptyState="no events to view"
              records={{ total: eventInfo.totalRecords }}
              loading={tableLoader}
              tableData={eventInfo.records.map((record, index) => {
                return <EventsListingRow key={index} event={record} />;
              })}
              handleChangePage={handleChangePage}
              createSortHandler={handleSorting}
              filter={additionalFilters}
              pagination={restrictPagination}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

const HeaderSection: React.FC<{ count: number }> = ({ count }) => {
  return (
    <Flex
      sx={{
        alignItems: 'center',
        mb: 24,
        position: 'relative',
      }}
    >
      <FontAwesomeIcon
        icon={faShareNodes}
        style={{ color: '#B6B847', marginRight: 12, fontSize: 64 }}
      />
      <Box
        as="h1"
        sx={{
          color: '#000',
          fontSize: 36,
          fontWeight: 800,
          lineHeight: 'normal',
        }}
      >
        Network Overview
      </Box>
      <Link
        to="/campaigns?ascendingOrder=false&mnoStatus=REGISTERED&sortField=createDate&status=ACTIVE"
        style={{
          position: 'absolute',
          left: 70,
          bottom: -6,
          fontSize: 16,
          fontWeight: 400,
          textDecoration: 'none',
          color: '#4D5659',
        }}
        data-testid="dashboardRegisteredCampaignLink"
      >
        <span style={{ fontWeight: 800 }} data-testid="registeredCampaignCount">
          {count}{' '}
        </span>
        Registered Campaigns
      </Link>
    </Flex>
  );
};

export default withFilters(Dashboard, {
  configs: FILTER_CONFIG,
  loaders: {
    suggestion: {
      brandName: {
        minLength: 2,
        load: (value: string) => getBrandNameSuggestions({ prefix: value }),
      },
    },
  },
});
