import React, { Component } from 'react';
import {
  Backdrop,
  Grid,
  Container,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { globalGetService } from '../../../../utils/globalApiServices';
import { Loader, FilterToggleButton } from '../../../../shared_elements';
import {
  CampaignListingRow,
  BrandDetailsBasic,
  BrandCspDetails,
  BrandAddressDetails,
  VettingDetailsListingRow,
  BrandDetailsSection,
} from '../components';
import {
  getBrandsDetailApi,
  getCampaignsByBrandApi,
  getAllUsecasesTypes,
  listVettingRecordsApi,
  getAllCampaignUidsByBrandApi,
  updateBulkCampaignStatusApi,
  getSuspensionRule,
} from '../apiServices';
import { MNO_STATUS } from '../../../../constants';
import queryString from 'query-string';
import { isEqual } from 'lodash-es';
import '../assets/brand-details-module.scss';
import Spinner from '../../Campaigns/components/Spinner';
import BrandActionModal from '../components/BrandActionModal';
import { toastFlashMessage } from '../../../../utils';
import {
  Button,
  BoxV2 as Box,
  Flex,
  Table,
  FilterType,
  TableFilter,
} from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleLeft,
  faRotateRight,
  faSquareMinus,
  faBullhorn,
  faCircleUser,
  faFileMagnifyingGlass,
  faAddressBook,
} from '@fortawesome/pro-regular-svg-icons';
import { withFilters } from '../../../../hocs';

const FILTER_CONFIG = {
  usecase: {
    type: FilterType.Dropdown,
    options: [],
    label: 'Use Case',
    placeholder: 'Use-Case',
  },
  cspName: {
    type: FilterType.Text,
    label: 'CSP Name',
    placeholder: 'Enter CSP Name',
    suggestions: [],
  },
  status: {
    type: FilterType.Dropdown,
    options: [
      { label: 'Active', value: 'ACTIVE' },
      { label: 'Deactivated', value: 'EXPIRED' },
    ],
    label: 'TCR Status',
    placeholder: 'TCR Status',
  },
  mnoStatus: {
    type: FilterType.Dropdown,
    options: MNO_STATUS,
    label: 'MNO Status',
    placeholder: 'MNO Status',
    multiple: true,
    width: 250,
  },
};

const DEFAULT_ADDITIONAL_FILTERS = {
  sortField: 'createDate',
  ascendingOrder: false,
};

const headRows = [
  { id: 'uid', label: 'CAMPAIGN ID', sortable: true },
  { id: 'brandName', label: 'BRAND NAME', sortable: false },
  { id: 'usecase', label: 'USE-CASE', sortable: true },
  { id: 'createDate', label: 'REGISTERED ON', sortable: true },
  { id: 'status', label: 'TCR STATUS', sortOption: false },
  { id: 'operationStatus', label: 'MNO STATUS', sortable: false },
];

const VettingDetailHeadRows = [
  { id: 'type', label: 'VETTING TYPE', sortable: false },
  { id: 'partner', label: 'VETTING PARTNER', sortable: false },
  { id: 'date', label: 'VETTED ON', sortable: false },
  { id: 'outcome', label: 'OUTCOME', sortable: false },
  { id: 'status', label: 'VETTING STATUS', sortable: false },
];

const fetchCspSuggestions = (query = {}) =>
  globalGetService('mno/csp/suggestions', { ...query, limit: 20 }).then(
    (response) => {
      if (response && response.status >= 200 && response.status < 300) {
        return response.data?.map((d) => d?.cspName) ?? [];
      }
      return [];
    }
  );

class BrandDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      brandUid: this.props.match.params.id,
      loader: true,
      campaignInfo: {
        records: [],
        page: 1,
        recordsPerPage: 10,
        totalRecords: 0,
      },
      tableLoader: false,
      brandInfo: {},
      openFilter: false,
      goBackPage: '/brands',
      vettingRecords: [],
      modalOpen: false,
      modalMessage: '',
      brandAction: '',
      brandSuspended: null,
      additionalFilters: DEFAULT_ADDITIONAL_FILTERS,
      isFilterVisible: false,
    };
    this.getBrandsDetailApi = getBrandsDetailApi.bind(this);
    this.listVettingRecordsApi = listVettingRecordsApi.bind(this);
    this.getSuspensionRule = getSuspensionRule.bind(this);
  }

  fetchFilterOptions = async () => {
    const { setOptions } = this.props.filter;
    const usecaseTypes = await getAllUsecasesTypes();

    setOptions(
      'usecase',
      usecaseTypes.map((item) => ({
        label: item.displayName,
        value: item.id,
      }))
    );
  };

  updateMnoStatusReviewOptionDisabled = (disabled = false) => {
    const { setOptions } = this.props.filter;
    setOptions(
      'mnoStatus',
      MNO_STATUS.map((item) =>
        item.value === 'REVIEW' ? { ...item, disabled } : item
      )
    );
  };

  parseSearchParams = (params = {}) => {
    let updatedFilter = this.state.additionalFilters;
    const { appliedFilters } = this.props.filter;
    const queries = queryString.parse(params, { decode: true });
    if (!Object.keys(queries).length) {
      updatedFilter = DEFAULT_ADDITIONAL_FILTERS;
    } else if (queries.sortField || queries.ascendingOrder) {
      updatedFilter = {
        sortField: queries.sortField,
        ascendingOrder: JSON.parse(queries.ascendingOrder),
      };
    }

    this.setState({ additionalFilters: updatedFilter });

    return {
      ...updatedFilter,
      ...appliedFilters,
      page: queries.page ? queries.page : 1,
    };
  };

  processFilterSpecialCase = (values = {}) => {
    const newValues = { ...values };
    const { configs } = this.props.filter;
    const isStatusExpired = values?.status === 'EXPIRED';
    const isMnoStatusHasPreview = values?.mnoStatus?.includes('REVIEW');
    const mnoStatusReviewIsDisabled = configs.mnoStatus.options.find(
      (item) => item.value === 'REVIEW' && item?.disabled
    );

    if (isStatusExpired) {
      if (isMnoStatusHasPreview) {
        const newMnoStatus = values.mnoStatus
          .split(',')
          .filter((item) => item !== 'REVIEW')
          .join(',');
        if (newMnoStatus) {
          newValues.mnoStatus = newMnoStatus;
        } else {
          delete newValues.mnoStatus;
        }
      }
      if (!mnoStatusReviewIsDisabled) {
        this.updateMnoStatusReviewOptionDisabled(true);
      }
    } else if (mnoStatusReviewIsDisabled) {
      this.updateMnoStatusReviewOptionDisabled(false);
    }
    return newValues;
  };

  componentDidMount() {
    if (this.props.location.state) {
      if (this.props.location.brandInfo) {
        this.setState((prevState) => ({
          ...prevState,
          brandInfo: this.props.location.state.brandInfo,
          loader: false,
          goBackPage: this.props.location.state.goBackPage,
        }));
      } else {
        this.setState({ goBackPage: this.props.location.state.goBackPage });
        this.getBrandsDetailApi(this.state.brandUid);
      }
    } else {
      this.getBrandsDetailApi(this.state.brandUid);
    }

    this.listVettingRecordsApi(this.state.brandUid);
    this.fetchFilterOptions();
    if (this.props.location.search) {
      const queries = this.parseSearchParams(this.props.location.search);
      const finalQuery = this.processFilterSpecialCase(queries);
      if (isEqual(queries, finalQuery)) {
        this.fetchCampaignsByBrand(finalQuery);
      } else {
        this.writeQueryString(finalQuery);
      }
    } else {
      this.fetchCampaignsByBrand({ ...this.state.filter });
    }
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.location.search, this.props.location.search)) {
      const params = this.parseSearchParams(this.props.location.search);
      this.fetchCampaignsByBrand(params);
    }
  }

  fetchCampaignsByBrand = async (query = {}) => {
    this.setState({ tableLoader: true });
    const updatedQuery = { ...query };
    const isStatusExpired = query?.status === 'EXPIRED';
    if (isStatusExpired && !query?.mnoStatus) {
      updatedQuery.mnoStatus = 'REJECTED,REGISTERED,SUSPENDED';
    }
    if (updatedQuery.status && updatedQuery.status.split(',').length > 1) {
      delete updatedQuery.status;
    }
    const campaignInfo = await getCampaignsByBrandApi(
      this.state.brandUid,
      updatedQuery
    );
    if (campaignInfo) {
      this.setState({ campaignInfo });
      if (
        campaignInfo.records.length === 0 &&
        campaignInfo.totalRecords > 0 &&
        campaignInfo.page > 1
      ) {
        const lastPageNo = Math.ceil(campaignInfo.totalRecords / 10);
        this.setState({ loader: true });
        this.writeQueryString({ ...query, page: lastPageNo });
      }
    }
    this.setState({ tableLoader: false });
  };

  handleCandidateFiltersChange = (values = {}) => {
    const newValues = this.processFilterSpecialCase(values);
    this.props.filter.handleEdit(newValues);
  };

  checkSuspensionStatus() {
    const { brandUid } = this.state;
    this.getSuspensionRule({ brandUid, exactMatch: true })
      .then((rulesInfo) => {
        if (rulesInfo && rulesInfo.records.length > 0) {
          const result = rulesInfo.records.some(
            (rule) => rule.brandUid === brandUid && rule.status === 'ACTIVE'
          );
          this.setState({ brandSuspended: result });
        } else {
          this.setState({ brandSuspended: false });
        }
      })
      .catch((e) => {});
  }

  generateOptions = (data, key) => {
    const options = data.map((item) => {
      return {
        label: item.displayName,
        value: item.uid ? item.uid : item.id,
        isChecked: false,
      };
    });
    this.setState((prevState) => ({
      ...prevState,
      filterObject: {
        ...prevState.filterObject,
        [key]: {
          ...prevState.filterObject[key],
          options,
        },
      },
    }));
  };

  handleChangePage = (newPage) => {
    const { additionalFilters } = this.state;
    const { appliedFilters } = this.props.filter;

    this.writeQueryString({
      ...appliedFilters,
      ...additionalFilters,
      page: newPage,
    });
  };

  handleSorting = (sortField) => {
    const { campaignInfo, additionalFilters } = this.state;
    const { appliedFilters } = this.props.filter;
    this.setState((prevState) => ({
      ...prevState,
      additionalFilters: {
        ...prevState.additionalFilters,
        ascendingOrder: !prevState.additionalFilters.ascendingOrder,
        sortField,
      },
    }));

    if (campaignInfo.totalRecords) {
      this.writeQueryString({
        ...additionalFilters,
        ...appliedFilters,
        page: campaignInfo.page,
        sortField,
        ascendingOrder: !additionalFilters.ascendingOrder,
      });
    }
  };

  handleGoBack = () => {
    const { goBackPage } = this.state;
    this.props.history.push(`${goBackPage}`);
  };

  writeQueryString = (searchParams = {}) => {
    this.props.history.push({
      search: `?${queryString.stringify(searchParams, { encode: false })}`,
    });
  };

  toggleFilterVisibility = () => {
    this.setState((prev) => ({
      isFilterVisible: !prev.isFilterVisible,
    }));
  };

  suspendAllCampaigns = async (explanation, suspensionCategory) => {
    this.setState({
      modalOpen: true,
      modalMessage: 'Suspending all campaigns...',
    });
    try {
      const campaignUids = await getAllCampaignUidsByBrandApi(
        this.props.match.params.id,
        'ACTIVE',
        'REGISTERED'
      );
      const total = campaignUids.length;
      let done = 0;
      do {
        this.setState({
          modalMessage: `Suspending ${done}/${total} campaigns...`,
        });
        const targetCampaignUids = campaignUids.splice(0, 10);
        await updateBulkCampaignStatusApi(
          targetCampaignUids,
          'SUSPENDED',
          explanation,
          suspensionCategory
        );
        done += targetCampaignUids.length;
      } while (done < total);
    } catch (e) {}
    this.setState({
      modalOpen: false,
      modalMessage: '',
    });
    this.fetchCampaignsByBrand();
    toastFlashMessage('Successfully updated campaign status', 'success');
  };

  liftAllSuspensions = async (explanation) => {
    this.setState({
      modalOpen: true,
      modalMessage: 'Lift all suspensions...',
    });
    try {
      const campaignUids = await getAllCampaignUidsByBrandApi(
        this.props.match.params.id,
        'ACTIVE',
        'SUSPENDED'
      );
      const total = campaignUids.length;
      let done = 0;
      do {
        this.setState({
          modalMessage: `Lifting ${done}/${total} suspensions...`,
        });
        const targetCampaignUids = campaignUids.splice(0, 10);
        await updateBulkCampaignStatusApi(
          targetCampaignUids,
          'REGISTERED',
          explanation
        );
        done += targetCampaignUids.length;
      } while (done < total);
    } catch (e) {}
    this.setState({
      modalOpen: false,
      modalMessage: '',
    });
    this.fetchCampaignsByBrand();
    toastFlashMessage('Successfully updated campaign status', 'success');
  };

  goToBrandSuspension = () => {
    this.props.history.push({
      pathname: `/brand-suspension/${this.state.brandUid}`,
      state: { brandSuspended: this.state.brandSuspended },
    });
  };

  render() {
    const {
      loader,
      campaignInfo,
      // filter,
      // filterObject,
      // appliedFilterObj,
      // openFilter,
      tableLoader,
      brandInfo,
      vettingRecords,
      goBackPage,
      modalOpen,
      modalMessage,
      brandAction,
      brandSuspended,
      additionalFilters,
      isFilterVisible,
    } = this.state;

    const {
      configs: filterConfigs,
      appliedFilters,
      candidateFilters,
      handleApply: handleAppliedFiltersChange,
    } = this.props.filter;

    return (
      <section className="brand-details-section page-content">
        {loader ? (
          <Loader />
        ) : (
          <Container maxWidth={false} className="brand-details-container">
            <Grid container className="top-blk" style={{ height: '40px' }}>
              <Grid item xs={6} className="back-btn">
                <a onClick={this.handleGoBack} className="paragraph">
                  <Flex sx={{ alignItems: 'center' }}>
                    <Box mr="s">
                      <FontAwesomeIcon icon={faAngleLeft} size="lg" />
                    </Box>
                    {goBackPage === '/brands' ? 'back to all brands' : 'back'}
                  </Flex>
                </a>
              </Grid>
              <Grid item xs={6} className="button-wrapper flex-centered">
                {brandSuspended === null ? (
                  <CircularProgress
                    size={40}
                    style={{ color: '#00698F', marginRight: '50px' }}
                  />
                ) : brandSuspended ? (
                  <React.Fragment>
                    <Button
                      color="secondary"
                      data-testid="gotoLiftBrandSuspensionButton"
                      onClick={this.goToBrandSuspension}
                    >
                      <FontAwesomeIcon icon={faRotateRight} />
                      <span>Lift Brand Suspension</span>
                    </Button>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Button
                      data-testid="gotoBrandSuspensionButton"
                      onClick={this.goToBrandSuspension}
                    >
                      <Box>
                        <FontAwesomeIcon icon={faSquareMinus} size="lg" />
                      </Box>
                      <span>Suspend Brand</span>
                    </Button>
                  </React.Fragment>
                )}
              </Grid>
            </Grid>
            <Grid container className="brand-details basic">
              <Grid item xs={12}>
                <div className="title" data-testid="brandDetailDisplayName">
                  <h3 className="heading1">
                    Brand Name:{' '}
                    {brandInfo.entityType.toLowerCase() === 'sole_proprietor'
                      ? 'Redacted due to PII'
                      : brandInfo.displayName
                      ? brandInfo.displayName
                      : 'N/A'}
                  </h3>
                  {brandInfo.entityType.toLowerCase() !== 'sole_proprietor' && (
                    <p className="heading2">
                      Legal Company Name:{' '}
                      {brandInfo.companyName ? brandInfo.companyName : 'N/A'}
                    </p>
                  )}
                </div>
              </Grid>
              <Grid item xs={12}>
                <BrandDetailsBasic
                  brandInfo={brandInfo}
                  brandSuspended={brandSuspended}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <div className="contact details">
                  <div className="details-heading">
                    <Box flexDirection="row" alignItems="center">
                      <Box margin={{ right: 'xs' }}>
                        <FontAwesomeIcon
                          icon={faCircleUser}
                          style={{ fontSize: '18px' }}
                        />
                      </Box>
                      <h3>CSP Details</h3>
                    </Box>
                  </div>
                  <BrandCspDetails cspInfo={brandInfo.csp} />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="contact details">
                  <div className="details-heading">
                    <Flex sx={{ alignItems: 'center' }}>
                      <Box mr="xs">
                        <FontAwesomeIcon
                          icon={faAddressBook}
                          style={{ fontSize: '18px' }}
                        />
                      </Box>
                      <h3>Brand Details</h3>
                    </Flex>
                  </div>
                  <BrandDetailsSection brand={brandInfo} />
                  <div
                    className="details-heading"
                    style={{ marginTop: '30px' }}
                  >
                    <Flex sx={{ alignItems: 'center' }}>
                      <Box mr="xs">
                        <FontAwesomeIcon icon={faCircleUser} size="xl" />
                      </Box>
                      <h3>Brand Address Details</h3>
                    </Flex>
                  </div>
                  <BrandAddressDetails
                    street={brandInfo.street}
                    city={brandInfo.city}
                    state={brandInfo.state}
                    postalCode={brandInfo.postalCode}
                    country={brandInfo.country}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="vetting details">
                  <div className="details-heading">
                    <Flex sx={{ alignItems: 'center' }}>
                      <Box mr="xs">
                        <FontAwesomeIcon
                          icon={faFileMagnifyingGlass}
                          size="xl"
                        />
                      </Box>
                      <h3>Vetting Details</h3>
                    </Flex>
                  </div>
                  <Table
                    data-testid="vettingListingTable"
                    className="vetting-listing-table"
                    loading={false}
                    headRows={VettingDetailHeadRows}
                    emptyState="no vettings to view"
                    disableHover={true}
                    records={{ total: vettingRecords.length }}
                    tableData={vettingRecords.map((record, index) => (
                      <VettingDetailsListingRow key={index} record={record} />
                    ))}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="campaigns-listing details">
                  <Flex>
                    <div className="w-100">
                      <Flex sx={{ alignItems: 'center' }}>
                        <Box mr="xs">
                          <FontAwesomeIcon icon={faBullhorn} size="xl" />
                        </Box>
                        <h3>{`${campaignInfo.totalRecords} Campaigns`}</h3>
                      </Flex>
                    </div>
                  </Flex>
                  <Flex
                    sx={{
                      flexDirection: 'column',
                      mt: 'xs',
                      mb: 's',
                    }}
                  >
                    <Flex sx={{ justifyContent: 'space-between' }}>
                      <FilterToggleButton
                        visible={isFilterVisible}
                        onClick={this.toggleFilterVisibility}
                        size="small"
                      />
                      <Flex sx={{ gap: 's' }}>
                        <Button
                          data-testid="suspendAllCampaignButton"
                          portal="mno"
                          shape="rounded"
                          variant="standard"
                          color="primary"
                          size="small"
                          disabled={!campaignInfo.totalRecords}
                          onClick={(event) => {
                            // If we do not add stop Propagation and prevent default. It would close the modal.
                            event.stopPropagation();
                            event.preventDefault();
                            this.setState({ brandAction: 'suspend' });
                          }}
                        >
                          <Box>
                            <FontAwesomeIcon icon={faSquareMinus} size="lg" />
                          </Box>
                          <span>Suspend All Campaigns</span>
                        </Button>
                        <Button
                          data-testid="liftAllSuspensionButton"
                          portal="mno"
                          shape="rounded"
                          variant="standard"
                          color="secondary"
                          size="small"
                          disabled={!campaignInfo.totalRecords}
                          onClick={(event) => {
                            // If we do not add stop Propagation and prevent default. It would close the modal.
                            event.stopPropagation();
                            event.preventDefault();
                            this.setState({ brandAction: 'lift' });
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faRotateRight}
                            color="#FFFFFF"
                          />
                          <span>Lift All Campaign Suspensions</span>
                        </Button>
                      </Flex>
                    </Flex>
                    {isFilterVisible && (
                      <TableFilter
                        portal="mno"
                        configs={filterConfigs}
                        candidateValues={candidateFilters}
                        appliedValues={appliedFilters}
                        onCandidateValuesChange={
                          this.handleCandidateFiltersChange
                        }
                        onAppliedValuesChange={handleAppliedFiltersChange}
                        className="filters"
                        data-testid="brandDetailCampaigns"
                        style={{ marginTop: '10px' }}
                      />
                    )}
                  </Flex>
                  <Table
                    data-testid="campaignListingTable"
                    loading={tableLoader}
                    emptyState="no campaigns to view"
                    records={{ total: campaignInfo.totalRecords }}
                    handleChangePage={this.handleChangePage}
                    createSortHandler={this.handleSorting}
                    filter={additionalFilters}
                    headRows={headRows}
                    data={campaignInfo.records}
                    rowKey="uid"
                    renderRow={(data) => {
                      return (
                        <CampaignListingRow key={data.uid} campaign={data} />
                      );
                    }}
                    pagination={{
                      count: Math.ceil(
                        campaignInfo.totalRecords / campaignInfo.recordsPerPage
                      ),
                      page: campaignInfo.page,
                      rowsPerPage: campaignInfo.recordsPerPage,
                      totalRecords: campaignInfo.totalRecords,
                    }}
                  />
                </div>
              </Grid>
            </Grid>
            <Backdrop
              style={{
                background: 'rgba(14, 31, 43, 0.75)',
                zIndex: 1005,
              }}
              open={modalOpen}
            >
              <Container className="modal">
                <Spinner />
                <Typography
                  variant="body1"
                  align="center"
                  style={{
                    width: '100%',
                    marginTop: 40,
                    color: '#49535D',
                    wordBreak: 'break-all',
                  }}
                >
                  {modalMessage}
                </Typography>
              </Container>
            </Backdrop>
          </Container>
        )}
        <BrandActionModal
          open={!!brandAction}
          type={brandAction}
          cspUid={brandInfo.cspUid ?? ''}
          brandName={
            brandInfo.displayName
              ? brandInfo.displayName
              : brandInfo.entityType === 'SOLE_PROPRIETOR'
              ? 'Redacted due to PII'
              : 'N/A'
          }
          onCancel={() => this.setState({ brandAction: '' })}
          onSubmit={(explanation, suspensionCategory) => {
            if (brandAction === 'suspend') {
              this.suspendAllCampaigns(explanation, suspensionCategory);
            } else {
              this.liftAllSuspensions(explanation);
            }
            this.setState({ brandAction: '' });
          }}
        />
      </section>
    );
  }
}

export default withFilters(BrandDetail, {
  configs: FILTER_CONFIG,
  loaders: {
    suggestion: {
      cspName: {
        minLength: 2,
        load: (value) => fetchCspSuggestions({ prefix: value }),
      },
    },
  },
});
