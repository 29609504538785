import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, TextField } from '@material-ui/core';
import { S3_ASSETS_PATH } from '../../../../constants';
import { globalPutService } from '../../../../utils/globalApiServices';
import { toastFlashMessage, isValidInput } from '../../../../utils';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Button, Box } from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey } from '@fortawesome/pro-regular-svg-icons';

export default function AddNewApiKeyDialog(props) {
  const { type, open, account } = props;

  const [loader, setLoader] = useState(false);
  const [error, setError] = useState({});
  const [label, setLabel] = useState('');
  useEffect(() => {
    if (open) {
      setLabel(account.displayName);
      setError({});
      setLoader(false);
    }
  }, [open]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (type == 'add') {
      // props.fetchApiAccounts()
      props.handleClose();
    } else {
      setLoader(true);
      let validateNewInput = {
        label:
          label && label.trim().length
            ? label.trim().length > 20
              ? 'Maximum 20 chars allowed'
              : isValidInput(label.trim())
              ? ''
              : 'Invalid input'
            : 'Please enter Label',
      };
      if (
        Object.keys(validateNewInput).every((k) => {
          return validateNewInput[k] === '';
        })
      ) {
        globalPutService(`mno/api/account/${account.id}`, {
          displayName: label.trim(),
        }).then((response) => {
          setLoader(false);
          if (response.status >= 200 && response.status < 300) {
            toastFlashMessage(`API Key updated successfully`, 'success');
            props.setAccount(response.data);
            props.handleClose();
          }
        });
      } else {
        setLoader(false);
        setError(validateNewInput);
      }
    }
  };
  return (
    <Dialog
      open={open}
      aria-labelledby="responsive-dialog-title"
      // maxWidth="xs"
      fullWidth={true}
      id="successModal"
      className="api-action-dialog default-dialog add-new-api-key"
      data-testid="addNewApiKeyDialog"
    >
      <DialogContent className="content-wrapper">
        <form onSubmit={handleSubmit}>
          <h3 className="title heading1 text-center">
            <Box flexDirection="row" justifyContent="center">
              <Box margin={{ right: 'xs' }}>
                <FontAwesomeIcon icon={faKey} />
              </Box>
              <span>
                {type == 'add' ? `New API Key Created` : `Edit API Key`}
              </span>
            </Box>
          </h3>
          <div className="form-group-field copy-btn-wrapper">
            <TextField
              label="Key"
              value={account.apiKey}
              disabled
              data-testid="addNewApiKeyDialogApiKey"
            />
            {/* {error.key ? <h6 className="error-msg">{error.key}</h6> : ''} */}
            <CopyToClipboard
              text={account.apiKey}
              onCopy={() =>
                toastFlashMessage(
                  'API Key has been copied to your clipboard',
                  'success'
                )
              }
            >
              <Button
                data-testid="addNewApiKeyDialogApiKeyCopyButton"
                portal="mno"
                shape="rounded"
                variant="outline"
                color="secondary"
                className="btn-s btn-transparent copy-btn-position"
                onClick={(event) => {
                  // If we do not add stop Propagation and prevent default. It would close the modal.
                  event.stopPropagation();
                  event.preventDefault();
                }}
              >
                Copy
              </Button>
            </CopyToClipboard>
          </div>
          <div className="form-group-field copy-btn-wrapper">
            <TextField label="Secret" value={account.secret} disabled />
            <CopyToClipboard
              text={account.secret}
              onCopy={() =>
                toastFlashMessage(
                  'Secret has been copied to your clipboard',
                  'success'
                )
              }
            >
              <Button
                data-testid="addNewApiKeyDialogSecretCopyButton"
                portal="mno"
                shape="rounded"
                variant="outline"
                color="secondary"
                className="btn-s btn-transparent copy-btn-position"
                onClick={(event) => {
                  // If we do not add stop Propagation and prevent default. It would close the modal.
                  event.stopPropagation();
                  event.preventDefault();
                }}
              >
                Copy
              </Button>
            </CopyToClipboard>
            {/* {error.secret ? <h6 className="error-msg">{error.secret}</h6> : ''} */}
          </div>
          <div className="form-group-field">
            <TextField
              error={error.label ? true : false}
              label="Label"
              data-testid="addNewApiKeyDialogLabelInput"
              value={label}
              disabled={type == 'add' ? true : false}
              onChange={(e) => {
                setLabel(e.target.value);
                setError({ ...error, label: '' });
              }}
            />
            {error.label ? <h6 className="error-msg">{error.label}</h6> : ''}
          </div>
          <ul className="list-inline footer">
            {type == 'edit' ? (
              <li>
                <Button
                  data-testid="addNewApiKeyDialogCancelButton"
                  portal="mno"
                  shape="rounded"
                  variant="outline"
                  color="secondary"
                  onClick={(event) => {
                    // If we do not add stop Propagation and prevent default. It would close the modal.
                    event.stopPropagation();
                    event.preventDefault();
                    props.handleClose(false);
                  }}
                >
                  Cancel
                </Button>
              </li>
            ) : null}
            <li className="form-group-field">
              <Button
                data-testid="addNewApiKeyDialogSubmitButton"
                disabled={
                  loader || Object.keys(error).find((k) => error[k] != '')
                }
                portal="mno"
                shape="rounded"
                color="secondary"
                onClick={(event) => {
                  // If we do not add stop Propagation and prevent default. It would close the modal.
                  event.stopPropagation();
                  event.preventDefault();
                  handleSubmit(event);
                }}
              >
                {type == 'add' ? 'Close' : 'Save'}
              </Button>
            </li>
          </ul>
        </form>
      </DialogContent>
    </Dialog>
  );
}
