import PropTypes from 'prop-types';
import React from 'react';
import { Grid, IconButton, makeStyles } from '@material-ui/core';

import { S3_ASSETS_PATH } from '../../../../constants';
import { ToolTip } from '../../../../shared_elements';
import { Box } from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/pro-regular-svg-icons';

const useStyles = makeStyles({
  iconContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    paddingRight: 10,
  },
  iconButton: {
    width: 24,
    height: 24,
    background: 'rgba(0, 105, 143, 0.25)',
    '&:hover': {
      background: 'rgba(0, 105, 143, 0.45)',
    },
  },
});

const CampaignAttribute = (props) => {
  const { additionalSetup, additionalSetupActive, setAdditionalSetupActive } =
    props;
  const classes = useStyles();

  return (
    <Grid item xs={6} data-testid="campaignAttribute">
      <div className="campaign-attribute" style={{ position: 'relative' }}>
        <Grid container alignItems="center" className="view-container">
          <Grid
            item
            xs={7}
            className="title"
            data-testid="campaignAttributeLabel"
          >
            <Box flexDirection="row">
              <p className="">{props.label}</p>
              <ToolTip title={props.tooltip} />
            </Box>
          </Grid>
          <Grid item xs={5} className="buttons-view">
            <Grid container direction="row" alignItems="center">
              <div
                className="view-item right"
                data-testid="campaignAttributeValue"
              >
                {props.value === true ? (
                  <>
                    <img
                      src={`${S3_ASSETS_PATH}/images/MNO/checked-attribute-new.svg`}
                    />
                    <span style={{ color: '#0091B3' }}>Yes</span>
                  </>
                ) : (
                  <>
                    <img src={`${S3_ASSETS_PATH}/images/unchecked.svg`} />
                    <span style={{ fontWeight: 400 }}>Yes</span>
                  </>
                )}
              </div>
              <div className="view-item" data-testid="campaignAttributeValue">
                {props.value === false ? (
                  <>
                    <img
                      src={`${S3_ASSETS_PATH}/images/negative-checked.svg`}
                    />
                    <span style={{ color: '#D41C54' }}>No</span>
                  </>
                ) : (
                  <>
                    <img src={`${S3_ASSETS_PATH}/images/unchecked.svg`} />
                    <span style={{ fontWeight: 400 }}>No</span>
                  </>
                )}
              </div>
              {props.value && additionalSetup && (
                <div
                  className={classes.iconContainer}
                  data-testid="campaignAttributeValueSetUp"
                >
                  <IconButton
                    classes={{ root: classes.iconButton }}
                    aria-label="view"
                    onClick={() =>
                      setAdditionalSetupActive && setAdditionalSetupActive(true)
                    }
                  >
                    <FontAwesomeIcon
                      icon={faEye}
                      style={{
                        color: additionalSetupActive ? '#FFFFFF' : '#00698F',
                      }}
                      size="xs"
                    />
                  </IconButton>
                </div>
              )}
            </Grid>
          </Grid>
        </Grid>
        {props.value && additionalSetup && (
          <div
            style={{ display: additionalSetupActive ? 'block' : 'none' }}
            data-testid="campaignAttributeAdditionalSetup"
          >
            {additionalSetup}
          </div>
        )}
      </div>
    </Grid>
  );
};

CampaignAttribute.propTypes = {
  additionalSetup: PropTypes.node,
  additionalSetupActive: PropTypes.bool,
  setAdditionalSetupActive: PropTypes.func,
};

export default CampaignAttribute;
