import React, { useEffect, useState } from 'react';
import { Box, Grid, Modal, TextField, makeStyles } from '@material-ui/core';
import { S3_ASSETS_PATH, MAX_TEXT_LENGTH } from '../../../../constants';
import IntegrationReactSelect from '../../../../shared_elements/ui_elements/IntegrationReactSelect';
import { getSuspensionCategoryOptions } from '../../Brands/apiServices';
import PropTypes from 'prop-types';
import { Button, Box as CommonBox } from 'portal-commons';
import { isValidInput } from '../../../../utils';
import { SuspensionModalStyles } from '../../Brands/components/BrandSuspensionModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleLeft,
  faSquareMinus,
  faRotateRight,
  faBullhorn,
  faTag,
} from '@fortawesome/pro-regular-svg-icons';
const SuspensionModal = (props) => {
  const {
    open,
    handleClose,
    handleSubmit,
    universalEin,
    totalCampaigns,
    totalBrands,
    isSuspended,
  } = props;

  const classes = SuspensionModalStyles();
  const btnColor = isSuspended ? 'secondary' : 'primary';
  const [selectOptions, setSelectOptions] = useState([
    {
      label: 'Select Reason',
      value: '',
    },
  ]);
  const [suspendReason, setSuspendReason] = useState('');
  const [explanation, setExplanation] = useState('');
  const [explanationError, setExplanationError] = useState('');

  const getAllSuspensionCategories = async () => {
    const options = await getSuspensionCategoryOptions();
    setSelectOptions((prevState) => {
      return [...prevState, ...options];
    });
  };

  const validate = () => {
    if (explanation.length > MAX_TEXT_LENGTH) {
      setExplanationError(`Maximum ${MAX_TEXT_LENGTH} chars`);
      return false;
    } else if (!isValidInput(explanation)) {
      setExplanationError('Invalid input');
      return false;
    }

    setExplanationError('');
    return true;
  };

  const handleDialogClose = () => {
    setSuspendReason('');
    setExplanation('');
    setExplanationError('');
    handleClose();
  };
  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (validate()) {
      const data = {
        suspensionCategory: suspendReason,
        explanation,
      };
      handleSubmit(data);
      handleDialogClose();
    }
  };
  const handleExplanationUpdate = (event) => {
    event.preventDefault();
    const { value } = event.target;
    setExplanation(value);
    setExplanationError('');
  };
  const handleExplanationBlur = (event) => {
    event.preventDefault();
    validate();
  };

  useEffect(() => {
    getAllSuspensionCategories();
  }, []);

  return (
    <Modal
      open={open}
      onClose={handleDialogClose}
      aria-labelledby="Brand Suspension Modal"
      aria-describedby="Suspend or Lift Suspension Campaigns"
      data-testid="suspensionModal"
    >
      <Box className={classes.container}>
        <h2 className={classes.header}>
          <CommonBox margin={{ right: 'xs' }}>
            <FontAwesomeIcon icon={faSquareMinus} />
          </CommonBox>
          <span>
            {isSuspended
              ? 'Lift Universal EIN Suspension'
              : 'Universal EIN Suspension'}
          </span>
        </h2>
        <p className={classes.paragraph}>
          {isSuspended ? (
            <React.Fragment>
              Lifting the Suspension of this Universal EIN reactivates{' '}
              <span>
                {totalCampaigns} Campaigns and {totalBrands} Brands
              </span>{' '}
              created under this Universal EIN. Lifting the Suspension of this
              Universal EIN allows new campaigns to qualify for your network.
            </React.Fragment>
          ) : (
            <React.Fragment>
              Please note only campaigns and brands that are not currently in a
              Suspended state can have new Suspensions applied to them.
              Suspending this Universal EIN suspends{' '}
              <span>
                {totalCampaigns} Campaigns and {totalBrands} Brands
              </span>{' '}
              associated with it and prevents new campaigns for this Universal
              EIN from qualifying for your network.
            </React.Fragment>
          )}
        </p>
        <form className={classes.form}>
          <p className="hint">* Indicates a Required Field</p>
          <TextField
            inputProps={{ style: { fontWeight: 500 } }}
            variant="filled"
            data-testid="suspensionModalUniversalEinInput"
            label="Universal EIN"
            fullWidth
            size="small"
            disabled
            value={universalEin}
          />
          {!isSuspended && (
            <IntegrationReactSelect
              options={selectOptions}
              required={true}
              size="small"
              label="Suspension Category"
              placeholder="Select Reason"
              value={selectOptions.find(
                (option) => option.value === suspendReason
              )}
              handleChange={(option) => setSuspendReason(option.value)}
              keyName="suspendReason"
            />
          )}
          <TextField
            error={!!explanationError}
            variant="filled"
            label="Explanation"
            data-testid="suspensionModalExplanationInput"
            size="small"
            required
            fullWidth
            onChange={handleExplanationUpdate}
            onBlur={handleExplanationBlur}
            helperText={explanationError}
          />
        </form>
        <div className={classes.actionBtns}>
          <Button
            variant="outline"
            color={btnColor}
            onClick={handleDialogClose}
            data-testid="suspensionModalCancelButton"
          >
            Cancel
          </Button>
          <Button
            disabled={
              isSuspended
                ? !!explanationError || !explanation
                : !!explanationError || !explanation || !suspendReason
            }
            color={btnColor}
            onClick={handleFormSubmit}
            data-testid={
              isSuspended
                ? 'suspensionModalUnsuspendButton'
                : 'suspensionModalSuspendButton'
            }
          >
            {isSuspended ? 'Unsuspend' : 'Suspend'}
          </Button>
        </div>
      </Box>
    </Modal>
  );
};
SuspensionModal.prototype = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  universalEin: PropTypes.string.isRequired,
  totalCampaigns: PropTypes.number.isRequired,
  totalBrands: PropTypes.number.isRequired,
  isSuspended: PropTypes.bool.isRequired,
};
export default SuspensionModal;
