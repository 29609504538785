import {
  globalGetService,
  globalPutService,
  globalDeleteService,
} from '../../../utils/globalApiServices';
import { CreateUserPayload, UpdateProfilePayload, Profile } from './types';

export const getProfile = async () => {
  const response = await globalGetService('mno/profile');
  if (response.ok) {
    return response.data as Profile;
  }
};

export const updateProfile = async (
  data: UpdateProfilePayload
): Promise<Profile | undefined> => {
  const response = await globalPutService('mno/profile', data);
  if (response.ok) {
    return response.data;
  }
};

export const enableMfa = async () => {
  // FIXME : api endpoint
  const response = await globalPutService('mno/manage/users/mfa');
  return !!response.ok;
};

export const disableMfa = async () => {
  // FIXME : api endpoint
  const response = await globalDeleteService('mno/manage/users/mfa');
  return !!response.ok;
};

export const getUsers = async () => {
  // FIXME : api endpoint
  const response = await globalGetService('mno/manage/users');
  if (response.ok) {
    return response.data;
  }
  return [];
};

export const createUser = async (data: CreateUserPayload) => {
  // FIXME : api endpoint
  const response = await globalPutService('mno/manage/users', data);
  return !!response.ok;
};

export const deleteUser = async (userId: string) => {
  // FIXME : api endpoint
  const response = await globalDeleteService(
    `mno/manage/users/${userId}/remove`
  );
  return !!response.ok;
};

export const getApprovedDcaList = async () => {
  const response = await globalGetService('mno/profile/approvedDca');
  if (response.ok) {
    return response.data;
  }
  return [];
};
