import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { OktaAuth } from '@okta/okta-auth-js';
import { withOktaAuth } from '@okta/okta-react';
import TextField from '@material-ui/core/TextField';
import { InputAdornment } from '@material-ui/core';
import { OKTA_BASE_URL, S3_ASSETS_PATH } from '../../../constants';
import { fieldValidation } from '../../../utils/validator';
import { toastFlashMessage } from '../../../utils';
import regexExpressions from '../../../constants/regExpressions';
import { Button, Box } from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRightToArc,
  faEye,
  faEyeSlash,
} from '@fortawesome/pro-regular-svg-icons';

export default withOktaAuth(
  withRouter(
    class LoginForm extends Component {
      constructor(props) {
        super(props);
        this.state = {
          sessionToken: null,
          userInfo: {
            username: '',
            password: '',
          },
          error: {},
          errorCode: {
            username: {
              0: '',
              1: 'Please enter Email Address',
              4: 'Invalid Email Address',
            },
            password: {
              0: '',
              1: 'Please enter Password',
            },
            usernameObj: {
              requiredFlag: true,
              regexPattern: regexExpressions.email,
            },
            passwordObj: {
              requiredFlag: true,
            },
          },
          loader: false,
          showPassword: false,
        };
        this.oktaAuth = new OktaAuth({ issuer: OKTA_BASE_URL });
      }
      togglePasswordMask = () => {
        this.setState((prevState) => ({
          ...prevState,
          showPassword: !prevState.showPassword,
        }));
      };
      handleChange = (value, key) => {
        this.setState((prevState) => ({
          ...prevState,
          userInfo: {
            ...prevState.userInfo,
            [key]: value,
          },
          error: {
            ...prevState.error,
            [key]: '',
          },
        }));
      };
      handleError = (value, key) => {
        const { errorCode } = this.state;
        this.setState((prevState) => ({
          ...prevState,
          error: {
            ...prevState.error,
            [key]:
              errorCode[key][
                fieldValidation({ ...errorCode[key + 'Obj'], fieldval: value })
              ], //creating error message
          },
        }));
      };
      login = (e) => {
        e.preventDefault();
        const { errorCode, userInfo } = this.state;
        this.setState({
          loader: true,
        });
        const validateNewInput = {
          username:
            errorCode['username'][
              fieldValidation({
                ...errorCode['usernameObj'],
                fieldval: userInfo.username,
              })
            ],
          password:
            errorCode['password'][
              fieldValidation({
                ...errorCode['passwordObj'],
                fieldval: userInfo.password,
              })
            ],
        };
        if (
          Object.keys(validateNewInput).every((k) => {
            return validateNewInput[k] === '';
          })
        ) {
          this.oktaAuth
            .signIn({
              username: this.state.userInfo.username,
              password: this.state.userInfo.password,
            })
            .then((res) => {
              this.setState({
                sessionToken: res.sessionToken,
                loader: false,
              });
              if (res.status === 'SUCCESS') {
                localStorage.setItem('loginSuccess', 1);
                // this.props.history.push("/protected")
              } else if (res.status === 'PASSWORD_EXPIRED') {
                toastFlashMessage(
                  'Your password has been expired. Please reset your password.',
                  'error'
                );
                setTimeout(() => {
                  this.props.history.push('/expired-password');
                }, 500);
              } else {
                toastFlashMessage(
                  'Authentication fail, invalid username or password',
                  'error'
                );
              }
            })
            .catch((err) => {
              if (err.errorSummary) {
                this.setState({
                  loader: false,
                });
                if (err.errorCode == 'E0000064') {
                  this.props.history.push('/expired-password');
                } else {
                  toastFlashMessage(
                    'Authentication fail, invalid username or password',
                    'error'
                  );
                }
              }
            });
        } else {
          this.setState({
            error: validateNewInput,
            loader: false,
          });
        }
      };

      render() {
        const { userInfo, error, loader } = this.state;
        if (this.state.sessionToken) {
          this.props.oktaAuth.signInWithRedirect({
            sessionToken: this.state.sessionToken,
          });
          return null;
        }
        return (
          <div className="auth-user-form login" data-testid="loginForm">
            <form autocomplete="off">
              <div className="form-title">
                <h3 className="heading1">
                  <Box justifyContent="center" flexDirection="row">
                    <Box margin={{ right: 's' }}>
                      <FontAwesomeIcon icon={faArrowRightToArc} size="lg" />
                    </Box>
                    Login
                  </Box>
                </h3>
              </div>
              <ul className="list-unstyled">
                <li>
                  <div className="form-group-field">
                    <TextField
                      data-testid="loginFormUsernameInput"
                      error={error.username ? true : false}
                      value={userInfo.username}
                      id="username"
                      label="Email Address"
                      required={true}
                      fullWidth={true}
                      inputProps={{
                        'aria-label': 'username',
                        autocomplete: 'off',
                      }}
                      onChange={(event) =>
                        this.handleChange(event.target.value, 'username')
                      }
                      onBlur={(event) =>
                        this.handleError(event.target.value, 'username')
                      }
                    />
                    {error.username ? (
                      <h6 className="error-msg">{error.username}</h6>
                    ) : null}
                  </div>
                </li>
                <li>
                  <div className="form-group-field">
                    <TextField
                      error={error.password ? true : false}
                      value={userInfo.password}
                      label="Password"
                      data-testid="loginFormPasswordInput"
                      required={true}
                      id="password"
                      type={this.state.showPassword ? 'text' : 'password'}
                      fullWidth={true}
                      InputProps={{
                        autocomplete: 'off',
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            style={{ cursor: 'pointer' }}
                          >
                            {this.state.showPassword ? (
                              <FontAwesomeIcon
                                icon={faEye}
                                style={{ fontSize: '15px', color: '#00AC9D' }}
                                onClick={this.togglePasswordMask}
                              />
                            ) : (
                              <FontAwesomeIcon
                                icon={faEyeSlash}
                                style={{ fontSize: '15px', color: '#00AC9D' }}
                                onClick={this.togglePasswordMask}
                              />
                            )}
                          </InputAdornment>
                        ),
                        'aria-label': 'password',
                      }}
                      onChange={(event) =>
                        this.handleChange(event.target.value, 'password')
                      }
                      onBlur={(event) =>
                        this.handleError(event.target.value, 'password')
                      }
                    />
                    {error.password ? (
                      <h6 className="error-msg">{error.password}</h6>
                    ) : null}
                    <p className="account-link-option supportText forgotpwd">
                      <Link
                        to="forgot-password"
                        data-testid="loginFormForgotPasswordInput"
                      >
                        Forgot Password?
                      </Link>
                    </p>
                  </div>
                </li>
                <li>
                  <div
                    className="form-group-field h-center flex"
                    style={{ margin: 0 }}
                  >
                    <Button
                      data-testid="loginFormSubmitButton"
                      type="submit"
                      disabled={
                        loader || Object.keys(error).find((k) => error[k] != '')
                          ? true
                          : false
                      }
                      portal="mno"
                      shape="rounded"
                      color="secondary"
                      onClick={this.login}
                    >
                      Submit
                    </Button>
                  </div>
                </li>
              </ul>
              {/* <p className="text-center account-link-option supportText">Don't have an account? <Link to="register">Register Now</Link></p> */}
            </form>
          </div>
        );
      }
    }
  )
);
