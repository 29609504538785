import { PhoneHistory } from './types';
import { globalGetService } from '../../../utils/globalApiServices';
import { getTimezone } from '../../../utils/time';

export const findPhoneHistory = async (
  phone: string,
  queries: Record<string, any> = {}
) => {
  const response = await globalGetService(
    `mno/campaigns/byPhone/${phone}/campaign/history`,
    {
      ...queries,
      timezone: getTimezone(),
    }
  );
  if (response.ok) {
    return response.data as PhoneHistory;
  }
};
