import { globalGetService } from '../../../utils/globalApiServices';
import { US_DATE_FORMAT, convertTimeWithTimezone } from '../../../utils/time';

export function getCspDetailApi(cspUid) {
  return globalGetService(`mno/csp/${cspUid}`).then((response) => {
    this.setState({
      cspInfo: response.data,
      loader: false,
    });
  });
}

export const getCampaignsByCspApi = async (cspUid, query = {}) => {
  const response = await globalGetService('mno/campaigns', {
    status: 'ACTIVE',
    ...query,
    cspUid,
  });
  if (response.status >= 200 && response.status < 300) {
    const campaignInfo = response.data;
    campaignInfo.records.forEach((campaign) => {
      campaign.createDate = convertTimeWithTimezone(
        campaign.createDate,
        undefined,
        US_DATE_FORMAT
      );
    });
    return campaignInfo;
  }
  return null;
};

export const getAllUsecasesTypes = async () => {
  const response = await globalGetService('enums/usecaseTypes');
  if (response.status >= 200 && response.status < 300) {
    return response.data;
  }
  return [];
};

export const getCspListApi = async (query = {}) => {
  const response = await globalGetService('mno/csp', { ...query });
  if (response.status >= 200 && response.status < 300) {
    return response.data;
  }
  return null;
};
