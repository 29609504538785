import React, { useState, useEffect, useCallback } from 'react';
import {
  AppBar,
  Backdrop,
  Grid,
  Container,
  Tabs,
  Tab,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  SuspensionModal,
  MatchedCampaignRow,
  MatchedBrandRow,
} from '../components';
import Spinner from '../../Campaigns/components/Spinner';
import { ListingTable, Loader } from '../../../../shared_elements';
import { S3_ASSETS_PATH, MNO_STATUS_ENUM } from '../../../../constants';
import { toastFlashMessage } from '../../../../utils';
import {
  getSuspensionRule,
  getSuspensionPreviewCampaigns,
  getSuspensionPreviewBrands,
  createSuspensionRule,
  updateSuspensionRule,
  updateBulkCampaignStatusApi,
} from '../../Brands/apiServices';
import queryString from 'query-string';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { Button, Box, Table } from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleLeft,
  faSquareMinus,
  faSquareCheck,
  faRotateRight,
  faBullhorn,
  faTag,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faSquareMinus as faSquareMinusSolid,
  faSquareCheck as faSquareCheckSolid,
} from '@fortawesome/pro-solid-svg-icons';

const useStyles = makeStyles(() => ({
  root: {
    padding: 0,
    '& .top-blk .button-wrapper': {
      justifyContent: 'flex-end',
    },
  },
  backLink: {
    color: '#C4C8C9',
    '& img': {
      marginRight: '3px',
    },
  },
  title: {
    textAlign: 'center',
    marginBottom: '40px',
    '& .heading1': {
      fontSize: '18px',
      lineHeight: '21px',
      marginBottom: '16px',
      wordBreak: 'break-word',
    },
    '& .heading2': {
      fontWeight: 400,
      '& img': {
        marginRight: '3px',
        verticalAlign: 'top',
        height: '14px',
      },
      '& img + span': {
        fontWeight: 700,
      },
    },
  },
  tabs: {
    display: 'flex',
    '& .tab-label': {
      border: '1px solid #C0CCD4',
      width: '225px',
      height: '26px',
      padding: '10px 18px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      fontFamily: 'Roboto',
      fontSize: '16px',
      fontWeight: 500,
      color: '#49535D',
      backgroundColor: '#E5F0F4',
      cursor: 'pointer',
      '& img': {
        height: '18px',
        marginRight: '5px',
        filter: 'grayscale(1)',
      },
      '&.active': {
        color: '#00698F',
        fontWeight: 800,
        backgroundColor: '#FFFFFF',
        borderBottom: '1px solid #FFFFFF',
        position: 'relative',
      },
      '&.active img': {
        filter: 'grayscale(0)',
      },
      '& + .tab-label': {
        borderLeft: 0,
      },
    },
  },
  tabContents: {
    marginTop: '-1px',
    border: '1px solid #C0CCD4',
    padding: '15px',
    overflowX: 'auto',
  },
  progressBackground: {
    background: 'rgba(14, 31, 43, 0.75)',
    zIndex: 1005,
  },
  progressModal: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '430px',
    height: '226px',
    background: '#FFFFFF',
    borderRadius: '5px',
    marginLeft: 'calc(50% - 120px)',
  },
  progressText: {
    textAlign: 'center',
    width: '100%',
    marginTop: 30,
    color: '#49535D',
    wordBreak: 'break-all',
  },
}));

const CampaignHeadRows = [
  { id: 'uid', label: 'CAMPAIGN ID', sortable: true },
  { id: 'brandUid', label: 'BRAND ID', sortable: true },
  { id: 'brandName', label: 'BRAND NAME', sortable: false },
  { id: 'brand.csp.displayName', label: 'CSP NAME', sortable: false },
  { id: 'usecase', label: 'USE-CASE', sortable: true },
  { id: 'createDate', label: 'REGISTERED ON', sortable: true },
  { id: 'operationStatus', label: 'MNO STATUS', sortable: false },
];
const BrandHeadRows = [
  { id: 'uid', label: 'BRAND ID', sortable: true },
  { id: 'displayName', label: 'BRAND NAME', sortable: true },
  { id: 'csp.displayName', label: 'CSP NAME', sortable: false },
  { id: 'entityType', label: 'ENTITY TYPE', sortable: true },
  { id: 'createDate', label: 'REGISTERED ON', sortable: true },
];
const TabName = {
  CAMPAIGN: 'campaigns',
  BRAND: 'brands',
};

const UniversalEinListing = (props) => {
  const classes = useStyles();
  const UniversalEin = props.match.params.id;
  const [einIssuingCountry, ein] = UniversalEin.split('_');
  const locationState = props.history.location.state;
  const paths = props.match.path.split('/');
  const goBackUrl =
    locationState && locationState.goBackPage ? locationState.goBackPage : '';
  if (paths.length === 3) {
    props.history.replace({
      pathname: `/universal-ein/${UniversalEin}/campaigns`,
      state: { goBackPage: goBackUrl },
    });
  }

  const initFilter = {
    ascendingOrder: false,
    ein,
    einIssuingCountry,
    page: 1,
    recordsPerPage: 10,
    sortField: 'createDate',
  };
  const emptyPagination = {
    records: [],
    page: 1,
    recordsPerPage: 10,
    totalRecords: 0,
  };

  const activeTab = paths.length > 3 ? paths[3] : TabName.CAMPAIGN;
  const headRows =
    activeTab === TabName.CAMPAIGN ? CampaignHeadRows : BrandHeadRows;

  const [loader, setLoader] = useState(true);
  const [tableLoader, setTableLoader] = useState(false);
  const [universalEinSuspended, setUniversalEinSuspended] = useState(
    locationState && locationState.suspended ? locationState.suspended : null
  );
  const [campaignInfo, setCampaignInfo] = useState(emptyPagination);
  const [effectedCampaignCount, setEffectedCampaignCount] = useState(0);
  const [brandInfo, setBrandInfo] = useState(emptyPagination);
  const [filter, setFilter] = useState(initFilter);
  const [suspensionRuleId, setSuspensionRuleId] = useState(null);
  const [openSuspensionModal, setOpenSuspensionModal] = useState(false);

  // This page only create suspension rule, backend will update campaigns status
  const manualUpdateCampaign = false;
  const processAction = universalEinSuspended ? 'Lift' : 'Suspending';
  const [progressMsg, setProgressMsg] = useState(null);

  const handleChangeTab = (event, newTabName) => {
    event.preventDefault();
    props.history.push({
      pathname: `/universal-ein/${UniversalEin}/${newTabName}`,
      state: { goBackPage: goBackUrl },
    });
  };
  const handleChangePage = (newPage) => {
    props.history.push({
      search: `?${queryString.stringify(
        { ...filter, page: newPage },
        { encode: false }
      )}`,
      state: { goBackPage: goBackUrl },
    });
  };
  const createSortHandler = (sortField) => {
    const newFilter = {
      ...filter,
      sortField,
      ascendingOrder: !filter.ascendingOrder,
    };
    setFilter(newFilter);

    if (campaignInfo.totalRecords) {
      const query = {
        ...newFilter,
        page: campaignInfo.page,
      };
      props.history.push({
        search: `?${queryString.stringify(query, { encode: false })}`,
        state: { goBackPage: goBackUrl },
      });
    }
  };
  const tableInfo = (() => {
    if (activeTab === TabName.CAMPAIGN) {
      return campaignInfo;
    } else if (activeTab === TabName.BRAND) {
      return brandInfo;
    }
    return emptyPagination;
  })();
  const suspendBtnContent = () => {
    const imgAlt = universalEinSuspended ? 'restore' : 'minus';
    const actionText = universalEinSuspended
      ? 'Lift Universal EIN Suspension'
      : 'Suspend Universal EIN';
    return (
      <React.Fragment>
        {universalEinSuspended ? (
          <Box>
            <FontAwesomeIcon icon={faRotateRight} size="lg" />
          </Box>
        ) : (
          <Box>
            <FontAwesomeIcon icon={faSquareMinus} size="lg" />
          </Box>
        )}
        <span>{actionText}</span>
      </React.Fragment>
    );
  };

  const handleModalSubmit = (payload) => {
    let authUser = null;
    const user = localStorage.getItem('user');
    if (user && JSON.parse(user)) {
      authUser = JSON.parse(user).profile.mnoNetworkId;
    }
    const data = {
      ...payload, // explanation, suspensionCategory
      authUser,
      ein,
      einIssuingCountry,
      status: universalEinSuspended ? 'INACTIVE' : 'ACTIVE',
    };
    createUniversalEinSuspensionRule(data);
  };

  const createUniversalEinSuspensionRule = async (payload) => {
    try {
      setProgressMsg(
        `${suspensionRuleId ? 'Update' : 'Create'} suspension rule`
      );

      let response;
      if (suspensionRuleId) {
        response = await updateSuspensionRule(suspensionRuleId, payload);
      } else {
        response = await createSuspensionRule(payload);
      }

      if (response.ruleId && manualUpdateCampaign) {
        setProgressMsg(`${processAction} all related campaigns`);
        await updateAllCampaigns(response.explanation);
      }

      setTimeout(() => {
        setProgressMsg(null);
        props.history.push({ pathname: goBackUrl });
        const message = universalEinSuspended
          ? 'Brand Suspension Successfully Lifted'
          : 'Brand Successfully Suspended';
        toastFlashMessage(message, 'success');
      }, 1000);
    } catch (e) {
      setProgressMsg(null);
      props.history.push({ pathname: goBackUrl });
    }
  };

  const updateAllCampaigns = async (reason) => {
    let count = 0;
    let dataInfo = { ...campaignInfo };
    const total = dataInfo.totalRecords;
    const updateStatus = universalEinSuspended
      ? MNO_STATUS_ENUM.REGISTERED
      : MNO_STATUS_ENUM.SUSPENDED;

    try {
      while (dataInfo && count < total) {
        const campaignIds = dataInfo.records.map((campaign) => campaign.uid);
        count += campaignIds.length;
        setProgressMsg(`${processAction} ${count} / ${total} campaigns`);
        // eslint-disable-next-line no-await-in-loop
        await updateBulkCampaignStatusApi(campaignIds, updateStatus, reason);
        if (count < total) {
          // eslint-disable-next-line no-await-in-loop
          dataInfo = await getMatchedCampaigns(filter);
        }
      }
    } catch (e) {
      toastFlashMessage(
        `Update Campaigns Status to ${updateStatus} Failed`,
        'error'
      );
    }
  };

  const checkSuspensionStatus = async () => {
    if (universalEinSuspended === null || suspensionRuleId === null) {
      const rules = await getSuspensionRule({
        brandUniversalEin: UniversalEin,
        exactMatch: true,
      });
      if (rules && rules.records.length > 0) {
        const suspensionRule = rules.records.find(
          (rule) =>
            rule.ein === ein && rule.einIssuingCountry === einIssuingCountry
        );
        if (suspensionRule) {
          setSuspensionRuleId(suspensionRule.ruleId);
        }
        const result = suspensionRule && suspensionRule.status === 'ACTIVE';
        setUniversalEinSuspended(result);
      } else {
        setUniversalEinSuspended(false);
      }
    }
    setLoader(false);
  };

  const getEffectedCampaignCount = useCallback(async () => {
    if (universalEinSuspended === null) return;
    const queryEffect = {
      ...initFilter,
      mnoStatus: universalEinSuspended
        ? MNO_STATUS_ENUM.SUSPENDED
        : MNO_STATUS_ENUM.REGISTERED,
    };
    const effectedCampaign = await getMatchedCampaigns(queryEffect, false);
    if (effectedCampaign.totalRecords > 0) {
      setEffectedCampaignCount(effectedCampaign.totalRecords);
    }
  }, [universalEinSuspended]);
  const getMatchedCampaigns = useCallback(async (query, setToTable = true) => {
    setToTable && setTableLoader(activeTab === TabName.CAMPAIGN);
    const result = await getSuspensionPreviewCampaigns(query);
    if (result) {
      setToTable && setCampaignInfo(result);
    }
    setToTable && setTableLoader(false);
    return result;
  }, []);
  const getMatchedBrands = useCallback(async (query) => {
    setTableLoader(activeTab === TabName.BRAND);
    const result = await getSuspensionPreviewBrands(query);
    if (result) {
      setBrandInfo(result);
    }
    setTableLoader(false);
    return result;
  }, []);

  useEffect(() => {
    checkSuspensionStatus();
    getMatchedBrands(initFilter);
    getMatchedCampaigns(initFilter);
  }, []);

  useEffect(() => {
    getEffectedCampaignCount();
  }, [getEffectedCampaignCount]);

  useEffect(() => {
    const searchParams = new URLSearchParams(props.location.search);
    if (searchParams.size === 0) return;
    const newFilter = { ...filter };
    searchParams.forEach((value, key) => {
      if (newFilter.hasOwnProperty(key)) {
        newFilter[key] = value;
      }
    });
    if (activeTab === TabName.BRAND) {
      getMatchedBrands(newFilter);
    } else {
      getMatchedCampaigns(newFilter);
    }
  }, [props.location.search, getMatchedBrands, getMatchedCampaigns]);

  return (
    <section data-testid="universalEinListing" className="page-content">
      {loader ? (
        <Loader />
      ) : (
        <Container maxWidth={false} className={classes.root}>
          <Grid container className="top-blk">
            <Grid item xs={6}>
              <Link
                data-testid="universalEinListingBackButton"
                className={`paragraph ${classes.backLink}`}
                to="#"
                onClick={(event) => {
                  event.preventDefault();
                  if (goBackUrl !== '') {
                    props.history.push(goBackUrl);
                  } else {
                    props.history.goBack();
                  }
                }}
              >
                <Box flexDirection="row" alignItems="center">
                  <Box margin={{ right: 's' }}>
                    <FontAwesomeIcon icon={faAngleLeft} size="lg" />
                  </Box>
                  back
                </Box>
              </Link>
            </Grid>
            <Grid item xs={6} className="button-wrapper flex-centered">
              <Button
                color={universalEinSuspended ? 'secondary' : 'primary'}
                onClick={() => setOpenSuspensionModal(true)}
                data-testid={
                  universalEinSuspended
                    ? 'liftUniversalEINSuspensionButton'
                    : 'suspendUniversalEINButton'
                }
              >
                {suspendBtnContent()}
              </Button>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <div className={classes.title}>
                <h3 className="heading1">Universal EIN: {UniversalEin}</h3>
                <p className="heading2">
                  <span>Universal EIN Suspended: </span>
                  {universalEinSuspended ? (
                    <React.Fragment>
                      <FontAwesomeIcon
                        icon={faSquareMinusSolid}
                        size="xl"
                        style={{ color: 'D41C54' }}
                      />{' '}
                      <span>YES</span>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <FontAwesomeIcon
                        icon={faSquareCheckSolid}
                        size="xl"
                        style={{ color: '009B7C' }}
                      />{' '}
                      <span>NO</span>
                    </React.Fragment>
                  )}
                </p>
              </div>
            </Grid>
          </Grid>
          <Grid container xs={12}>
            <Grid item xs={12}>
              <div className={classes.tabs}>
                <div
                  onClick={(event) => handleChangeTab(event, TabName.CAMPAIGN)}
                  className={`tab-label ${
                    activeTab === TabName.CAMPAIGN ? 'active' : ''
                  }`}
                >
                  <Box float="left" margin={{ right: 'xs' }}>
                    <FontAwesomeIcon icon={faBullhorn} />
                  </Box>
                  {campaignInfo.totalRecords} Campaigns
                </div>
                <div
                  data-testid="universalEinListingBrandChangeButton"
                  onClick={(event) => handleChangeTab(event, TabName.BRAND)}
                  className={`tab-label ${
                    activeTab === TabName.BRAND ? 'active' : ''
                  }`}
                >
                  <Box float="left" margin={{ right: 'xs' }}>
                    <FontAwesomeIcon icon={faTag} />
                  </Box>
                  <span>{brandInfo.totalRecords} Brands</span>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} className={classes.tabContents}>
              <Table
                loading={tableLoader}
                emptyState={`no ${activeTab} to view`}
                records={{ total: tableInfo.totalRecords }}
                handleChangePage={handleChangePage}
                createSortHandler={createSortHandler}
                filter={filter}
                headRows={headRows}
                tableData={tableInfo.records.map((record) => {
                  if (activeTab === TabName.BRAND) {
                    return <MatchedBrandRow data={record} key={record.uid} />;
                  }
                  return <MatchedCampaignRow data={record} key={record.uid} />;
                })}
                pagination={{
                  count: Math.ceil(
                    tableInfo.totalRecords / tableInfo.recordsPerPage
                  ),
                  page: tableInfo.page,
                  rowsPerPage: tableInfo.recordsPerPage,
                  totalRecords: tableInfo.totalRecords,
                }}
              />
            </Grid>
          </Grid>
        </Container>
      )}
      <Backdrop
        className={classes.progressBackground}
        open={progressMsg !== null}
        data-testid="universalEinListingBackdrop"
      >
        <Container className={classes.progressModal}>
          <Spinner />
          <Typography variant="body1" className={classes.progressText}>
            {progressMsg}
          </Typography>
        </Container>
      </Backdrop>
      <SuspensionModal
        open={openSuspensionModal}
        handleClose={() => setOpenSuspensionModal(false)}
        handleSubmit={handleModalSubmit}
        universalEin={UniversalEin}
        totalCampaigns={effectedCampaignCount}
        totalBrands={brandInfo.totalRecords}
        isSuspended={universalEinSuspended}
      />
    </section>
  );
};
UniversalEinListing.prototype = {
  // name: PropTypes.string.isRequired,
};
export default withRouter(UniversalEinListing);
