import { FC } from 'react';
import { Flex } from 'portal-commons';
import Text from './Text';

interface DetailItemProps {
  label: string;
}

const DetailItem: FC<DetailItemProps> = ({ label, children }) => {
  return (
    <Flex>
      <Text variant="label" sx={{ width: '33%' }}>
        {label}:
      </Text>
      <Text variant="info" sx={{ width: '66%', overflowWrap: 'break-word' }}>
        {children}
      </Text>
    </Flex>
  );
};

export default DetailItem;
