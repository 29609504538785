import React from 'react';
import { BoxV2 as Box, Flex } from 'portal-commons';
import Highcharts, { Options } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { S3_ASSETS_PATH } from '../../../../../constants';

const DashboardChart: React.FC<{ options: Options }> = ({ options }) => {
  return (
    <Box
      sx={{ height: '100%' }}
      className="dashboard-chart"
      data-testid="dashboardChart"
    >
      {options && options.series?.length ? (
        <HighchartsReact highcharts={Highcharts} options={options} />
      ) : (
        <Flex
          sx={{
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
          className="no-chart-data-found"
        >
          <img src={`${S3_ASSETS_PATH}/images/no-data-found.svg`} alt="" />
          <p className="paragraph" style={{ textAlign: 'center' }}>
            No data,
            <br /> <span>{'no campaigns to view'}</span>
          </p>
        </Flex>
      )}
    </Box>
  );
};

export default DashboardChart;
