/**
 * This component is deprecated and will be removed in the future.
 * It replace by Table with VettingDetailsListingRow component.
 */
import React, { useState } from 'react';
import { Grid, Tooltip, ClickAwayListener } from '@material-ui/core';
import { S3_ASSETS_PATH } from '../../../../constants';
import { convertTimeWithTimezone } from '../../../../utils/time';
import { withStyles } from '@material-ui/core/styles';
import { toastFlashMessage } from '../../../../utils';
import { Box } from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/pro-regular-svg-icons';

const CustomTooltip = withStyles((theme) => ({
  arrow: {
    color: '#00797C',
  },
  tooltip: {
    background: '#FFFFFF',
    color: '#0091B3',
    fontSize: 14,
    borderRadius: '0',
    maxWidth: 526,
    width: 526,
    maxHeight: 355,
    fontFamily: 'Roboto',
    fontWeight: 400,
    padding: 0,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
  },
}))(Tooltip);

export default function BrandVettingDetails(props) {
  const { records = [], brandInfo } = props;
  const [viewDetails, setViewDetails] = useState(false);
  const [curElIndex, setCurElIndex] = useState(null);

  const getCopyTextTemplate = (text, keyName) => {
    if (!text) {
      return undefined;
    }
    return (
      <p
        className="p-l-xs pointer copy"
        style={{ marginRight: 17 }}
        onClick={() => {
          navigator.clipboard.writeText(text);
          toastFlashMessage(
            `${keyName} has been copied to your clipboard`,
            'success'
          );
        }}
      >
        copy
      </p>
    );
  };

  const remapStringReasonsToUITemplate = (stringReason) => {
    const array = JSON.parse(stringReason);
    return array.map((reason, index) => {
      return <li key={`reasons-${index}`}>{reason}</li>;
    });
  };
  return (
    <div className="vetting-listing-table list-unstyled">
      <ul className="vetting-list list-unstyled">
        <li className="heading-row">
          <Grid container spacing={1}>
            <Grid item className="gridItem">
              VETTING TYPE
            </Grid>
            <Grid item className="gridItem">
              VETTING PARTNER
            </Grid>
            <Grid item className="gridItem date">
              VETTED ON
            </Grid>
            <Grid item className="gridItem outcome">
              OUTCOME
            </Grid>
            <Grid item className="gridItem vettingId">
              VETTING STATUS
            </Grid>
          </Grid>
        </li>
        {/* <li className="contentRow">
                    <Grid container spacing={1} alignItems="center" justify="space-between">
                        <Grid item className="gridItem" >
                            <p className="paragraph"><span>{`Brand Registration  Score`}</span></p>
                        </Grid>
                        <Grid item className="gridItem">
                            <p className="paragraph">{`TCR Internal`}</p>
                        </Grid>
                        <Grid item className="gridItem date">
                            <p className="paragraph">{format(brandInfo.createDate, 'MM/dd/yyyy HH:mm')}</p>
                        </Grid>
                        <Grid item className="gridItem outcome">
                            <p className="paragraph">{brandInfo.registrationScore ? brandInfo.registrationScore : 'N/A'}</p>
                        </Grid>
                        <Grid item className="gridItem vettingId">
                            <p className="paragraph">{`N/A`}</p>
                        </Grid>
                    </Grid>
                </li> */}
        {records.map((item, index) => (
          <li className="contentRow" key={index}>
            <Grid container spacing={1} alignItems="center">
              <Grid item className="gridItem">
                <p className="paragraph">
                  <span>{item.vettingClass}</span>
                </p>
              </Grid>
              <Grid item className="gridItem">
                <p className="paragraph">{item.evpUid}</p>
              </Grid>
              <Grid item className="gridItem date">
                <p className="paragraph">
                  {item.vettingStatus.toLowerCase() === 'active'
                    ? item.vettedDate
                      ? convertTimeWithTimezone(item.vettedDate)
                      : '--'
                    : item.vettingStatus.toLowerCase() === 'expired'
                    ? 'Expired'
                    : item.vettingStatus.toLowerCase() === 'contact_vendor'
                    ? 'Contact Vendor'
                    : 'N/A'}
                </p>
              </Grid>
              <Grid item className="gridItem outcome">
                <p className="paragraph">
                  {item.vettingStatus.toLowerCase() === 'active'
                    ? item.score != null
                      ? `${item.score}`
                      : item.evpUid === 'CV'
                      ? 'Verified'
                      : '--'
                    : item.vettingStatus.toLowerCase() === 'expired'
                    ? 'Expired'
                    : item.vettingStatus.toLowerCase() === 'contact_vendor'
                    ? 'Contact Vendor'
                    : 'N/A'}
                  {(item.evpUid === 'AEGIS' ||
                    item.evpUid === 'CV' ||
                    item.evpUid === 'WMC') && (
                    <ClickAwayListener
                      onClickAway={
                        viewDetails && curElIndex === index
                          ? (e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              setViewDetails(false);
                              setCurElIndex(null);
                            }
                          : (e) => {
                              e.preventDefault();
                              e.stopPropagation();
                            }
                      }
                    >
                      <span>
                        <CustomTooltip
                          PopperProps={{
                            disablePortal: true,
                          }}
                          open={viewDetails && curElIndex === index}
                          placement={'bottom-end'}
                          title={
                            <div className="vetting-details-tooltip">
                              <h6 className="paragraph title">
                                Please see the vetting details below:
                              </h6>
                              {item && item.reasons && (
                                <div
                                  className="rasons-container"
                                  style={{ overflowY: 'unset' }}
                                >
                                  <ul className="vetting-detail-list-tooltip">
                                    {item && item.reasons
                                      ? remapStringReasonsToUITemplate(
                                          item.reasons
                                        )
                                      : null}
                                  </ul>
                                </div>
                              )}

                              {item.vettingClass === 'POLITICAL' ? (
                                item.evpUid === 'AEGIS' ? (
                                  <>
                                    <div
                                      className="rasons-container"
                                      style={{ flex: 1 }}
                                    >
                                      <ul
                                        className="vetting-detail-list-tooltip"
                                        style={{ maxHeight: 'unset' }}
                                      >
                                        {item.additionalRequestPayload &&
                                          Object.entries(
                                            JSON.parse(
                                              item.additionalRequestPayload
                                            )
                                          ).map(([key, value]) => (
                                            <li className="no-style">
                                              <span>{key}: </span> {value}
                                            </li>
                                          ))}
                                      </ul>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div
                                      className="rasons-container"
                                      style={{ flex: 1 }}
                                    >
                                      <ul
                                        className="vetting-detail-list-tooltip"
                                        style={{ maxHeight: 'unset' }}
                                      >
                                        {item.fullReport &&
                                          Object.entries(
                                            JSON.parse(item.fullReport)
                                          ).map(([key, value]) => (
                                            <li className="no-style">
                                              <span>{key}: </span> {value}
                                            </li>
                                          ))}
                                      </ul>
                                    </div>
                                  </>
                                )
                              ) : undefined}
                              <div className="copy-paste-container">
                                <hr />
                                {item.vettingClass === 'POLITICAL' ? (
                                  item.evpUid === 'AEGIS' ? (
                                    <>
                                      <div className="flex-1 flex space-between text-trucate">
                                        <p className="flex-2 text-trucate">
                                          <span>Vetting ID: </span>{' '}
                                          {item.vettingId || 'N/A'}
                                        </p>
                                        {getCopyTextTemplate(
                                          item.vettingId,
                                          'Vetting ID'
                                        )}
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div className="flex space-between">
                                        <div className="flex-1 flex space-between text-trucate">
                                          <p className="flex-2 text-trucate">
                                            <span>Vetting ID: </span>{' '}
                                            {item.vettingId || 'N/A'}
                                          </p>
                                          {getCopyTextTemplate(
                                            item.vettingId,
                                            'Vetting ID'
                                          )}
                                        </div>
                                        <div className="flex-1 flex space-between text-trucate">
                                          <p className="flex-2 text-trucate">
                                            <span>Vetting Token: </span>{' '}
                                            {item.vettingToken || 'N/A'}
                                          </p>
                                          {getCopyTextTemplate(
                                            item.vettingToken,
                                            'Vetting Token'
                                          )}
                                        </div>
                                      </div>
                                    </>
                                  )
                                ) : item.evpUid === 'WMC' ? (
                                  <>
                                    <div className="flex space-between">
                                      <div className="flex-1 flex space-between text-trucate">
                                        <p className="flex-2 text-trucate">
                                          <span>
                                            Vetting ID/Transaction ID:{' '}
                                          </span>{' '}
                                          {item.vettingId || 'N/A'}
                                        </p>
                                        {getCopyTextTemplate(
                                          item.vettingId,
                                          'Vetting ID/Transaction ID'
                                        )}
                                      </div>
                                      <div className="flex-1 flex space-between text-trucate">
                                        <p className="flex-2 text-trucate">
                                          <span>Vetting Token: </span>{' '}
                                          {(item.fullReport &&
                                            JSON.parse(item.fullReport)
                                              .vettingToken) ||
                                            'N/A'}
                                        </p>
                                        {item.fullReport &&
                                          getCopyTextTemplate(
                                            JSON.parse(item.fullReport)
                                              .vettingToken,
                                            'Vetting Token'
                                          )}
                                      </div>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="flex space-between">
                                      <div className="flex-1 flex space-between text-trucate">
                                        <p className="flex-2 text-trucate">
                                          <span>Vetting ID: </span>{' '}
                                          {item.vettingId || 'N/A'}
                                        </p>
                                        {getCopyTextTemplate(
                                          item.vettingId,
                                          'Vetting ID'
                                        )}
                                      </div>
                                      <div className="flex-1 flex space-between text-trucate">
                                        <p className="flex-2 text-trucate">
                                          <span>Vetting Token: </span>{' '}
                                          {item.vettingToken || 'N/A'}
                                        </p>
                                        {getCopyTextTemplate(
                                          item.vettingToken,
                                          'Vetting Token'
                                        )}
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          }
                          onClose={() => setViewDetails(false)}
                          disableFocusListener
                          disableHoverListener
                          disableTouchListener
                        >
                          <span
                            onClick={() => {
                              setViewDetails(true);
                              setCurElIndex(index);
                              console.log('clicl', 'click');
                            }}
                            className={`${
                              viewDetails && curElIndex === index
                                ? 'active'
                                : ''
                            } view-icon`}
                          >
                            <FontAwesomeIcon
                              icon={faEye}
                              size="lg"
                              style={{ padding: '1px 2px' }}
                            />
                          </span>
                        </CustomTooltip>
                      </span>
                    </ClickAwayListener>
                  )}
                </p>
              </Grid>
              <Grid item className="gridItem vettingId">
                <p className="paragraph">{item.vettingStatus}</p>
              </Grid>
            </Grid>
          </li>
        ))}
        {records.length == 0 && (
          <li className="no-data-row">
            <img src={`${S3_ASSETS_PATH}/images/no-data-found.svg`} alt="" />
            <p className="paragraph">
              No data found,
              <br /> <span>no vettings to view</span>
            </p>
          </li>
        )}
      </ul>
    </div>
  );
}
