export const ATTACHMENT_FOLDER = {
  MMS: 'MMS_SAMPLE_MEDIA',
  SUPPORTING_DOC: 'SUPPORTING_DOCUMENT',
};

export const MIGRATION_STATUS_LABEL_MAP = {
  ACTIVE: 'In Progress',
  COMPLETED: 'Completed',
  CANCELLED: 'No',
};
