import React from 'react';
import PropTypes from 'prop-types';
import { ENTITY_TYPES } from '../../../../constants';

export default function CspDetailsBasic(props) {
  const { cspInfo } = props;
  return (
    <ul className="list-inline basic" data-testid="cspDetailBasic">
      <li data-testid="cspDetailCountry">
        <h3 className="heading2">
          <span>Country of Registration:</span>
          {cspInfo.country}
        </h3>
      </li>
      <li data-testid="cspDetailEntityType">
        <h3 className="heading2 entityType">
          <span>Entity Type:</span>
          {ENTITY_TYPES.find((entity) => entity.value === cspInfo.entityType)
            ?.label ||
            cspInfo.entityType ||
            'N/A'}
        </h3>
      </li>
      <li data-testid="cspDetailCompanyName">
        <h3 className="heading2">
          <span>Legal Company Name:</span>
          {cspInfo.companyName}
        </h3>
      </li>
    </ul>
  );
}
CspDetailsBasic.propTypes = {
  campaign: PropTypes.object.isRequired,
};
