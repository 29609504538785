import { globalGetService } from '../../../utils/globalApiServices';
import apiInstance from '../../../utils/apiInterceptor';
import { convertTimeWithTimezone, US_DATE_FORMAT } from '../../../utils/time';
import { Pagination } from '../../../utils/types';
import { Brand } from '../Brands/types';
import { Campaign, CampaignsData } from '../Campaigns/types';
import { PhoneNumberHistory, TopCsp, TopUsecase } from './types';
import { EventData } from '../Events/types';

export const getCampaignTopCsps = async (
  query = {}
): Promise<TopCsp[] | undefined> => {
  const response = await globalGetService(
    'mno/statistics/campaign/topCsp',
    query
  );
  return response?.ok ? response.data : undefined;
};

export const getCampaignTopUsecases = async (
  query = {}
): Promise<TopUsecase[] | undefined> => {
  const response = await globalGetService(
    'mno/statistics/campaign/topUsecase',
    query
  );
  return response?.ok ? response.data : undefined;
};

export const getCampaignList = async (
  query = {}
): Promise<Pagination<CampaignsData> | undefined> => {
  const response = await globalGetService('mno/campaigns', {
    status: 'ACTIVE',
    ...query,
  });
  return response?.ok ? response.data : undefined;
};

export const getCampaignDetail = async (
  campaignId: string
): Promise<Campaign | undefined> => {
  const response = await globalGetService(`mno/campaigns/${campaignId}`);
  if (response?.ok) {
    const campaign = response.data;
    campaign.createDate = convertTimeWithTimezone(
      campaign.createDate,
      undefined,
      US_DATE_FORMAT
    );
    return campaign;
  }
  return undefined;
};

export const getBrandDetail = async (
  brandId: string
): Promise<Brand | undefined> => {
  const response = await globalGetService(`mno/brands/${brandId}`);
  if (response?.ok) {
    const brand = response.data;
    brand.createDate = convertTimeWithTimezone(
      brand.createDate,
      undefined,
      US_DATE_FORMAT
    );
    return brand;
  }
  return undefined;
};

export const getCampaignDetailByPhone = async (
  phone: string
): Promise<Campaign | undefined> => {
  const response = await apiInstance.get(`mno/campaigns/byPhone/${phone}`, {
    params: {},
    validateStatus: null,
  });
  if (response.status >= 200 && response.status < 300) {
    const campaign = response.data;
    campaign.createDate = convertTimeWithTimezone(
      campaign.createDate,
      undefined,
      US_DATE_FORMAT
    );
    return campaign;
  }
  return undefined;
};

export const queryCampaignPhoneHistory = async (
  phone: string,
  query = {}
): Promise<Pagination<PhoneNumberHistory> | undefined> => {
  const response = await apiInstance.get(
    `mno/campaigns/byPhone/${phone}/campaign/history`,
    { params: query, validateStatus: null }
  );
  if (response.status >= 200 && response.status < 300) {
    return response.data as Pagination<PhoneNumberHistory>;
  }
  return undefined;
};

export const getBrandNameSuggestions = async (
  query: Record<string, string>
): Promise<string[]> => {
  const response = await globalGetService('mno/brands/suggestions', {
    limit: 20,
    ...query,
  });
  return response?.ok
    ? response.data?.map(
        (d: { brandName: string; brandUid: string }) => d?.brandName
      )
    : [];
};

const convertDateFormatForSearch = (inputDateStr: string) => {
  // inputDate: MM/dd/yyyy -> yyyy-MM-dd
  const parts = inputDateStr.split('/');
  if (parts.length === 3) {
    const [mm, dd, yyyy] = parts;
    return `${yyyy}-${mm}-${dd}`;
  }
  return inputDateStr;
};

const parseDateRange = (dateRange: string) => {
  const [startDate, endDate] = dateRange.replace(/\s/gi, '').split('-');
  return {
    startDate: convertDateFormatForSearch(startDate),
    endDate: convertDateFormatForSearch(endDate),
  };
};

export const queryEventList = async (query: Record<string, string>) => {
  if (query && query.hasOwnProperty('dateRange')) {
    query = {
      ...query,
      ...parseDateRange(query.dateRange),
    };
    delete query.dateRange;
  }

  const response = await globalGetService('mno/event', { ...query });
  if (response?.ok) {
    const eventList: Pagination<EventData> = response.data;
    eventList.records.forEach((event: EventData) => {
      event.createDate = convertTimeWithTimezone(event.createDate, 'UTC+0');
    });
    return eventList;
  }
  return undefined;
};
