import React from 'react';
import { TableCell, TableRow, useMediaQuery } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import ExpansionList from '../../../../shared_elements/ui_elements/ExpansionList';
import { getEventTypeImage } from '../../../../constants';
import { Box } from 'portal-commons';

function EventsListingRow(props) {
  const matches = useMediaQuery('(max-width:1280px)');
  const matches2 = useMediaQuery('(max-width:1500px)');
  const { event } = props;
  return (
    <TableRow
      onClick={(e) => {
        event.campaignUid
          ? props.history.push({
              pathname: `/campaigns/${event.campaignUid}`,
              state: {
                goBackPage: `${props.location.pathname}${
                  props.location.search ? props.location.search : ''
                }`,
              },
            })
          : e.preventDefault();
      }}
      className={event.campaignUid ? 'link' : ''}
      data-testid="eventsListingRow"
    >
      <TableCell
        align="left"
        style={{ minWidth: 170, wordBreak: 'break-word' }}
        data-testid="eventsListingRowEventTypeDescription"
      >
        <div className="event-type-wrapper">
          <Box flexDirection="row">
            <Box margin={{ right: 'xs' }}>
              {getEventTypeImage(event.eventType)}
            </Box>
            <p>{event.eventType || '--'}</p>
          </Box>
        </div>
      </TableCell>
      <TableCell
        align="left"
        style={{ minWidth: 100 }}
        data-testid="eventsListingRowCategoryType"
      >
        {event.categoryType}
      </TableCell>
      <TableCell
        align="left"
        className={`${event.campaignUid ? 'events-listing-table-link' : ''}`}
        style={{ minWidth: 100 }}
        data-testid="eventsListingRowCampaignUid"
        onClick={(e) => {
          e.stopPropagation();
          if (event.campaignUid) {
            props.history.push({
              pathname: `/campaigns/${event.campaignUid}`,
              state: {
                goBackPage: `${props.location.pathname}${
                  props.location.search ? props.location.search : ''
                }`,
              },
            });
          }
        }}
      >
        {event.campaignUid}
      </TableCell>
      <TableCell
        align="left"
        className={`${event.brandUid ? 'events-listing-table-link' : ''}`}
        style={{ minWidth: 100 }}
        data-testid="eventsListingRowBrandUid"
        onClick={(e) => {
          e.stopPropagation();
          if (event.brandUid) {
            props.history.push({
              pathname: `/brands/${event.brandUid}`,
              state: {
                goBackPage: `${props.location.pathname}${
                  props.location.search ? props.location.search : ''
                }`,
              },
            });
          }
        }}
      >
        {event.brandUid}
      </TableCell>
      <TableCell
        style={{ minWidth: 130 }}
        data-testid="eventsListingRowCreateDate"
      >
        {event.createDate}
      </TableCell>
      <TableCell
        style={{ width: 400, overflow: 'hidden' }}
        align="left"
        onClick={(e) => e.stopPropagation()}
        data-testid="eventsListingRowDescription"
      >
        {event.description.length > (matches ? 60 : matches2 ? 60 : 60) ? (
          <ExpansionList
            title={
              matches
                ? `${event.description.slice(0, 50)}...`
                : matches2
                ? `${event.description.slice(0, 50)}...`
                : `${event.description.slice(0, 50)}...`
            }
          >{`${event.description}`}</ExpansionList>
        ) : (
          event.description
        )}
      </TableCell>
    </TableRow>
  );
}
export default withRouter(EventsListingRow);
