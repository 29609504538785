import axiosInstance, { axiosInstanceV2 } from './apiInterceptor';

export const globalGetService = (url, params, responseType = 'json') => {
  return new Promise(function (resolve, reject) {
    axiosInstance({
      method: 'GET',
      url: url,
      params: params,
      responseType,
    }).then((response) => {
      resolve(response);
    });
  });
};
export const globalPostService = (url, data) => {
  return new Promise(function (resolve, reject) {
    axiosInstance({
      method: 'POST',
      url: url,
      data: data,
    }).then((response) => {
      resolve(response);
    });
  });
};
export const globalPutService = (url, data) => {
  return new Promise(function (resolve, reject) {
    axiosInstance({
      method: 'PUT',
      url: url,
      data: data,
    }).then((response) => {
      resolve(response);
    });
  });
};
export const globalDeleteService = (url, data) => {
  return new Promise(function (resolve, reject) {
    axiosInstance({
      method: 'DELETE',
      url: url,
      data: data,
    }).then((response) => {
      resolve(response);
    });
  });
};

export const globalGetServiceV2 = (url, params, responseType = 'json') => {
  return new Promise((resolve, reject) => {
    axiosInstanceV2({
      method: 'GET',
      url,
      params,
      responseType,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const globalPostServiceV2 = (url, data) => {
  return new Promise((resolve, reject) => {
    axiosInstanceV2({
      method: 'POST',
      url,
      data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const globalPutServiceV2 = (url, data) => {
  return new Promise((resolve, reject) => {
    axiosInstanceV2({
      method: 'PUT',
      url,
      data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const globalDeleteServiceV2 = (url, data) => {
  return new Promise((resolve, reject) => {
    axiosInstanceV2({
      method: 'DELETE',
      url,
      data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
