import { FC, ComponentProps } from 'react';
import { faTrashCan } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Flex, TextInput, BoxV2 as Box } from 'portal-commons';

interface IRemovableTextInput extends ComponentProps<typeof TextInput> {
  removable?: boolean;
  onRemoveClick?: () => void;
}

const RemovableTextInput: FC<IRemovableTextInput> = ({
  removable = true,
  onRemoveClick,
  ...inputProps
}) => {
  return (
    <Flex sx={{ alignItems: 'flex-start', gap: 's' }}>
      <TextInput {...inputProps} style={{ flex: 1 }} />
      {removable && (
        <Box
          data-testid="removeEmailBtn"
          role="button"
          sx={{ cursor: 'pointer', mt: '12px' }}
          onClick={() => onRemoveClick?.()}
        >
          <FontAwesomeIcon
            icon={faTrashCan}
            color="#333E41"
            fontSize="14px"
            fontWeight={900}
          />
        </Box>
      )}
    </Flex>
  );
};

export default RemovableTextInput;
