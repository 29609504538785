import React, { Component } from 'react';
import { Grid, Container } from '@material-ui/core';
import { isEqual, isEmpty } from 'lodash';
import queryString from 'query-string';
import { globalGetService } from '../../../../utils/globalApiServices';
import { Loader, FilterToggleButton } from '../../../../shared_elements';
import EventsListingRow from '../components/EventsListingRow';
import { convertTimeWithTimezone } from '../../../../utils/time';
import { getAllEventTypes } from '../apiServices';
import '../assets/events-listing-module.scss';
import { MAX_EVENT_RECORDS } from '../constants';
import { Box, Table, FilterType, TableFilter } from 'portal-commons';
import { withFilters } from '../../../../hocs';

const FILTER_CONFIG = {
  campaignUid: {
    type: FilterType.Text,
    label: 'Campaign ID',
    placeholder: 'Enter Campaign ID',
  },
  brandUid: {
    type: FilterType.Text,
    label: 'Brand ID',
    placeholder: 'Enter Brand ID',
  },
  brandName: {
    type: FilterType.Text,
    label: 'Brand Name',
    placeholder: 'Enter Brand Name',
    suggestions: [],
  },
  cspName: {
    type: FilterType.Text,
    label: 'CSP Name',
    placeholder: 'Enter CSP Name',
    suggestions: [],
  },
  eventType: {
    type: FilterType.Dropdown,
    options: [],
    label: 'Event Type',
  },
};

const headRows = [
  { label: 'EVENT TYPE', id: 'eventType', sortable: false },
  { label: 'SOURCE', id: 'categoryType', sortable: false },
  { label: 'CAMPAIGN ID', id: 'campaignUid', sortable: false },
  { label: 'BRAND ID', id: 'brandUid', sortable: false },
  { label: 'DATE & TIME', id: 'updateDate', sortable: false },
  { label: 'DESCRIPTION', id: 'description', sortable: false },
];

const fetchBrandSuggestions = (query = {}) =>
  globalGetService('mno/brands/suggestions', { ...query, limit: 20 }).then(
    (response) => {
      if (response && response.status >= 200 && response.status < 300) {
        return response.data?.map((d) => d?.brandName) ?? [];
      }
      return [];
    }
  );

const fetchCspSuggestions = (query = {}) =>
  globalGetService('mno/csp/suggestions', { ...query, limit: 20 }).then(
    (response) => {
      if (response && response.status >= 200 && response.status < 300) {
        return response.data?.map((d) => d?.cspName) ?? [];
      }
      return [];
    }
  );

class Events extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventInfo: {
        records: [],
        page: 1,
        recordsPerPage: 10,
        totalRecords: 0,
      },
      tableLoader: true,
      loader: true,
      additionalFilters: {},
      isFilterVisible: false,
    };
  }

  fetchFilterOptions = async () => {
    const { setOptions } = this.props.filter;
    const eventTypes = await getAllEventTypes();
    setOptions(
      'eventType',
      eventTypes.map((item) => ({
        label: item.id,
        value: item.id,
      }))
    );
  };

  parseSearchParams = (params = {}) => {
    let updatedFilter = this.state.additionalFilters;
    const { appliedFilters } = this.props.filter;
    const queries = queryString.parse(params, { decode: true });
    if (!Object.keys(queries).length) {
      updatedFilter = {};
    } else if (queries.sortField || queries.ascendingOrder) {
      updatedFilter = {
        sortField: queries.sortField,
        ascendingOrder: JSON.parse(queries.ascendingOrder),
      };
    }

    this.setState({ additionalFilters: updatedFilter });

    return {
      ...updatedFilter,
      ...appliedFilters,
      page: queries.page ? queries.page : 1,
    };
  };

  componentDidMount() {
    this.fetchFilterOptions();
    if (this.props.location.search) {
      const params = this.parseSearchParams(this.props.location.search);
      this.fetchEventList(params);
    } else {
      this.fetchEventList({ ...this.state.additionalFilters });
    }
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.location.search, this.props.location.search)) {
      const params = this.parseSearchParams(this.props.location.search);
      this.fetchEventList(params);
    }
  }

  fetchEventList = (query = {}) => {
    this.setState({ tableLoader: true });
    globalGetService('mno/event', { ...query }).then((response) => {
      this.setState({ tableLoader: false });
      if (response.status >= 200 && response.status < 300) {
        const eventInfo = response.data;
        eventInfo.records.forEach((event) => {
          event.createDate = convertTimeWithTimezone(event.createDate);
        });
        this.setState({ eventInfo, loader: false });
        if (
          eventInfo.records.length === 0 &&
          eventInfo.totalRecords > 0 &&
          eventInfo.page > 1
        ) {
          const lastPageNo = Math.ceil(eventInfo.totalRecords / 10);
          this.setState({ loader: true });
          this.writeQueryString({ ...query, page: lastPageNo });
        }
      }
    });
  };

  handleChangePage = (newPage) => {
    const { additionalFilters } = this.state;
    const { appliedFilters } = this.props.filter;

    this.writeQueryString({
      ...additionalFilters,
      ...appliedFilters,
      page: newPage,
    });
  };

  handleSorting = (sortField) => {
    const { eventInfo, additionalFilters } = this.state;
    const { appliedFilters } = this.props.filter;
    this.setState((prevState) => ({
      ...prevState,
      additionalFilters: {
        ...prevState.additionalFilters,
        ascendingOrder: !prevState.additionalFilters.ascendingOrder,
        sortField,
      },
    }));

    if (eventInfo.totalRecords) {
      this.writeQueryString({
        ...additionalFilters,
        ...appliedFilters,
        page: eventInfo.page,
        sortField,
        ascendingOrder: !additionalFilters.ascendingOrder,
      });
    }
  };

  writeQueryString = (searchParams = {}) => {
    this.props.history.push({
      search: `?${queryString.stringify(searchParams, { encode: false })}`,
    });
  };

  toggleFilterVisibility = () => {
    this.setState((prev) => ({
      isFilterVisible: !prev.isFilterVisible,
    }));
  };

  render() {
    const {
      eventInfo,
      tableLoader,
      loader,
      additionalFilters,
      isFilterVisible,
    } = this.state;

    const {
      configs: filterConfigs,
      appliedFilters,
      candidateFilters,
      handleEdit: handleCandidateFiltersChange,
      handleApply: handleAppliedFiltersChange,
    } = this.props.filter;

    const maskedTotalRecords =
      eventInfo.totalRecords > MAX_EVENT_RECORDS
        ? MAX_EVENT_RECORDS
        : eventInfo.totalRecords;
    const maskedPaginationData = {
      count: Math.ceil(maskedTotalRecords / eventInfo.recordsPerPage),
      rowsPerPage: eventInfo.recordsPerPage,
      page: eventInfo.page,
      totalRecords: maskedTotalRecords,
    };

    return (
      <section
        className="events-listing-section page-content"
        data-testid="events"
      >
        {loader ? (
          <Loader />
        ) : (
          <Container maxWidth={false} className="events-listing-container">
            <Box>
              <div className="title-block">
                <h2>{!loader ? `${maskedTotalRecords} Events` : 'Events'}</h2>
                <span>
                  This page can only show the latest{' '}
                  {MAX_EVENT_RECORDS.toLocaleString()} records.Contact support
                  for details on your remaining records.
                </span>
              </div>
            </Box>
            <Box flexDirection="column" margin={{ top: 's', bottom: 's' }}>
              <Box>
                <FilterToggleButton
                  visible={isFilterVisible}
                  onClick={this.toggleFilterVisibility}
                />
              </Box>
              {isFilterVisible && (
                <TableFilter
                  portal="mno"
                  configs={filterConfigs}
                  candidateValues={candidateFilters}
                  appliedValues={appliedFilters}
                  onCandidateValuesChange={handleCandidateFiltersChange}
                  onAppliedValuesChange={handleAppliedFiltersChange}
                  className="filters"
                  data-testid="events"
                />
              )}
            </Box>
            <Grid container justifyContent="center" spacing={0}>
              <Table
                headRows={headRows}
                emptyState="no events to view"
                records={{ total: eventInfo.totalRecords }}
                loading={tableLoader}
                tableData={eventInfo.records.map((record, index) => {
                  return <EventsListingRow key={index} event={record} />;
                })}
                handleChangePage={this.handleChangePage}
                createSortHandler={this.handleSorting}
                filter={additionalFilters}
                pagination={maskedPaginationData}
              />
            </Grid>
          </Container>
        )}
      </section>
    );
  }
}

export default withFilters(Events, {
  configs: FILTER_CONFIG,
  loaders: {
    suggestion: {
      brandName: {
        minLength: 2,
        load: (value) => fetchBrandSuggestions({ prefix: value }),
      },
      cspName: {
        minLength: 2,
        load: (value) => fetchCspSuggestions({ prefix: value }),
      },
    },
  },
});
