import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

export default function IntegrationReactSelect(props) {
  function handleChangeOption(selectedOption) {
    return props.handleChange(selectedOption, props.keyName);
  }
  return (
    <Autocomplete
      // {...defaultProps}
      autoFocus
      options={props.options}
      getOptionLabel={(option) =>
        typeof option === 'string' ? option : option.label
      }
      placeholder={props.label}
      id="disable-clearable"
      value={props.value}
      disableClearable
      onChange={(...props) => handleChangeOption(props[1])}
      renderInput={(params) => (
        <TextField
          {...params}
          autoComplete="new-password"
          label={props.value ? props.label : ''}
          placeholder={props.label}
          required={props.required ?? false}
          size={props.size ?? 'medium'}
          margin="normal"
          fullWidth
        />
      )}
    />
  );
}
