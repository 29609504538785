import React, { Component } from 'react';
import { Grid, Container } from '@material-ui/core';
import { isEqual, isEmpty } from 'lodash';
import { globalGetService } from '../../../../utils/globalApiServices';
import {
  Loader,
  ToolTip,
  FilterToggleButton,
} from '../../../../shared_elements';
import {
  getCspDetailApi,
  getCampaignsByCspApi,
  getAllUsecasesTypes,
} from '../apiServices';
import { MNO_STATUS } from '../../../../constants';
import {
  CampaignListingRow,
  CspDetailsBasic,
  CspContactDetails,
} from '../components';
import queryString from 'query-string';
import '../assets/csp-details-module.scss';
import { Box, Table, FilterType, TableFilter } from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleLeft,
  faCircleUser,
  faBullhorn,
} from '@fortawesome/pro-regular-svg-icons';
import { withFilters } from '../../../../hocs';
import { MAX_EVENT_RECORDS } from '../../Events/constants';

const FILTER_CONFIG = {
  brandUid: {
    type: FilterType.Text,
    label: 'Brand ID',
    placeholder: 'Enter Brand ID',
  },
  brandName: {
    type: FilterType.Text,
    label: 'Brand Name',
    placeholder: 'Enter Brand Name',
    suggestions: [],
  },
  usecase: {
    type: FilterType.Dropdown,
    options: [],
    label: 'Use Case',
    placeholder: 'Use-Case',
  },
  status: {
    type: FilterType.Dropdown,
    options: [
      { label: 'Active', value: 'ACTIVE' },
      { label: 'Deactivated', value: 'EXPIRED' },
    ],
    label: 'TCR Status',
    placeholder: 'TCR Status',
  },
  mnoStatus: {
    type: FilterType.Dropdown,
    options: MNO_STATUS,
    label: 'MNO Status',
    placeholder: 'MNO Status',
    multiple: true,
    width: 250,
  },
};

const DEFAULT_ADDITIONAL_FILTERS = {
  sortField: 'createDate',
  ascendingOrder: false,
};

const headRows = [
  { id: 'uid', label: 'CAMPAIGN ID', sortable: true },
  { id: 'brandUid', label: 'BRAND ID', sortable: true },
  { id: 'brandName', label: 'BRAND NAME', sortable: false },
  { id: 'usecase', label: 'USE-CASE', sortable: true },
  { id: 'createDate', label: 'REGISTERED ON', sortable: true },
  { id: 'operationStatus', label: 'MNO STATUS', sortable: false },
];

const fetchBrandSuggestions = (query = {}) =>
  globalGetService('mno/brands/suggestions', { ...query, limit: 20 }).then(
    (response) => {
      if (response && response.status >= 200 && response.status < 300) {
        return response.data?.map((d) => d?.brandName) ?? [];
      }
      return [];
    }
  );

class CspDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cspUid: this.props.match.params.id,
      loader: true,
      campaignInfo: {
        records: [],
        page: 1,
        recordsPerPage: 10,
        totalRecords: 0,
      },
      cspInfo: {},
      tableLoader: false,
      goBackPage: '/csps',
      additionalFilters: DEFAULT_ADDITIONAL_FILTERS,
      isFilterVisible: false,
    };
    this.getCspDetailApi = getCspDetailApi.bind(this);
  }

  fetchFilterOptions = async () => {
    const { setOptions } = this.props.filter;
    const usecaseTypes = await getAllUsecasesTypes();

    setOptions(
      'usecase',
      usecaseTypes.map((item) => ({
        label: item.displayName,
        value: item.id,
      }))
    );
  };

  updateMnoStatusReviewOptionDisabled = (disabled = false) => {
    const { setOptions } = this.props.filter;
    setOptions(
      'mnoStatus',
      MNO_STATUS.map((item) =>
        item.value === 'REVIEW' ? { ...item, disabled } : item
      )
    );
  };

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.location.search, this.props.location.search)) {
      const params = this.parseSearchParams(this.props.location.search);
      this.fetchCampaignsByCsp(params);
    }
  }

  parseSearchParams = (params = {}) => {
    let updatedFilter = this.state.additionalFilters;
    const { appliedFilters } = this.props.filter;
    const queries = queryString.parse(params, { decode: true });
    if (!Object.keys(queries).length) {
      updatedFilter = DEFAULT_ADDITIONAL_FILTERS;
    } else if (queries.sortField || queries.ascendingOrder) {
      updatedFilter = {
        sortField: queries.sortField,
        ascendingOrder: JSON.parse(queries.ascendingOrder),
      };
    }

    this.setState({ additionalFilters: updatedFilter });

    return {
      ...updatedFilter,
      ...appliedFilters,
      page: queries.page ? queries.page : 1,
    };
  };

  processFilterSpecialCase = (values = {}) => {
    const newValues = { ...values };
    const { configs } = this.props.filter;
    const isStatusExpired = values?.status === 'EXPIRED';
    const isMnoStatusHasPreview = values?.mnoStatus?.includes('REVIEW');
    const mnoStatusReviewIsDisabled = configs.mnoStatus.options.some(
      (item) => item.value === 'REVIEW' && item?.disabled
    );

    if (isStatusExpired) {
      if (isMnoStatusHasPreview) {
        const newMnoStatus = values.mnoStatus
          .split(',')
          .filter((item) => item !== 'REVIEW')
          .join(',');
        if (newMnoStatus) {
          newValues.mnoStatus = newMnoStatus;
        } else {
          delete newValues.mnoStatus;
        }
      }
      if (!mnoStatusReviewIsDisabled) {
        this.updateMnoStatusReviewOptionDisabled(true);
      }
    } else if (mnoStatusReviewIsDisabled) {
      this.updateMnoStatusReviewOptionDisabled(false);
    }
    return newValues;
  };

  componentDidMount() {
    if (this.props.location.state) {
      if (this.props.location.state.goBackPage) {
        this.setState({ goBackPage: this.props.location.state.goBackPage });
      }
    }
    this.getCspDetailApi(this.state.cspUid);

    this.fetchFilterOptions();
    if (this.props.location.search) {
      const queries = this.parseSearchParams(this.props.location.search);
      const finalQuery = this.processFilterSpecialCase(queries);
      if (isEqual(queries, finalQuery)) {
        this.fetchCampaignsByCsp(finalQuery);
      } else {
        this.writeQueryString(finalQuery);
      }
    } else {
      this.fetchCampaignsByCsp({ ...this.state.additionalFilters });
    }
  }

  fetchCampaignsByCsp = async (query = {}) => {
    this.setState({ tableLoader: true });
    const updatedQuery = { ...query };
    const isStatusExpired = query?.status === 'EXPIRED';
    if (isStatusExpired && !query?.mnoStatus) {
      updatedQuery.mnoStatus = 'REJECTED,REGISTERED,SUSPENDED';
    }

    const campaignInfo = await getCampaignsByCspApi(
      this.state.cspUid,
      updatedQuery
    );
    if (campaignInfo) {
      this.setState({ campaignInfo });
      if (
        campaignInfo.records.length === 0 &&
        campaignInfo.totalRecords > 0 &&
        campaignInfo.page > 1
      ) {
        const lastPageNo = Math.ceil(
          campaignInfo.totalRecords / campaignInfo.recordsPerPage
        );
        this.setState({ loader: true });
        this.writeQueryString({ ...query, page: lastPageNo });
        return;
      }
    }
    this.setState({ tableLoader: false, loader: false });
  };

  handleCandidateFiltersChange = (values = {}) => {
    const newValues = this.processFilterSpecialCase(values);
    this.props.filter.handleEdit(newValues);
  };

  handleChangePage = (newPage) => {
    const { additionalFilters } = this.state;
    const { appliedFilters } = this.props.filter;

    this.writeQueryString({
      ...appliedFilters,
      ...additionalFilters,
      page: newPage,
    });
  };

  handleSorting = (sortField) => {
    const { campaignInfo, additionalFilters } = this.state;
    const { appliedFilters } = this.props.filter;
    this.setState((prevState) => ({
      ...prevState,
      additionalFilters: {
        ...prevState.additionalFilters,
        ascendingOrder: !prevState.additionalFilters.ascendingOrder,
        sortField,
      },
    }));

    if (campaignInfo.totalRecords) {
      this.writeQueryString({
        ...additionalFilters,
        ...appliedFilters,
        page: campaignInfo.page,
        sortField,
        ascendingOrder: !additionalFilters.ascendingOrder,
      });
    }
  };

  writeQueryString = (searchParams = {}) => {
    this.props.history.push({
      search: `?${queryString.stringify(searchParams, { encode: false })}`,
    });
  };

  toggleFilterVisibility = () => {
    this.setState((prev) => ({
      isFilterVisible: !prev.isFilterVisible,
    }));
  };

  campaignListTitle = (totalRecords, status) => {
    return `${totalRecords} ${
      status === 'EXPIRED' ? 'Deactivated' : 'Active'
    } Campaigns`;
  };

  handleGoBack = () => {
    const { goBackPage } = this.state;
    this.props.history.push(`${goBackPage}`);
  };

  render() {
    const {
      campaignInfo,
      tableLoader,
      cspInfo,
      loader,
      goBackPage,
      additionalFilters,
      isFilterVisible,
    } = this.state;

    const {
      configs: filterConfigs,
      appliedFilters,
      candidateFilters,
      handleApply: handleAppliedFiltersChange,
    } = this.props.filter;

    const maskedTotalRecords =
      campaignInfo.totalRecords > MAX_EVENT_RECORDS
        ? MAX_EVENT_RECORDS
        : campaignInfo.totalRecords;
    const maskedPaginationData = {
      count: Math.ceil(maskedTotalRecords / campaignInfo.recordsPerPage),
      rowsPerPage: campaignInfo.recordsPerPage,
      page: campaignInfo.page,
      totalRecords: maskedTotalRecords,
    };

    return (
      <section
        className="csp-details-section page-content"
        data-testid="cspDetail"
      >
        {loader ? (
          <Loader />
        ) : (
          <Container maxWidth={false} className="csp-details-container">
            <Grid container className="top-blk">
              <Grid
                item
                xs={6}
                className="back-btn"
                data-testid="cspDetailBackButton"
              >
                <a onClick={this.handleGoBack} className="paragraph">
                  <Box flexDirection="row" alignItems="center">
                    <Box margin={{ right: 's' }}>
                      <FontAwesomeIcon icon={faAngleLeft} size="lg" />
                    </Box>
                    {goBackPage == '/csps' ? 'back to all csps' : 'back'}
                  </Box>
                </a>
              </Grid>
            </Grid>
            <Grid container className="csp-details basic">
              <Grid item xs={12}>
                <div className="title" data-testid="cspDetailDisplayName">
                  <h3 className="heading1">
                    <Box flexDirection="row" justifyContent="center">
                      <span>{cspInfo.displayName}</span>
                      <span className="infoIcon">
                        <ToolTip title="Doing Business As or Brand Name" />
                      </span>
                    </Box>
                  </h3>
                </div>
              </Grid>
              <Grid item xs={12}>
                <CspDetailsBasic cspInfo={cspInfo} />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <div className="contact details">
                  <div className="details-heading">
                    <h3 className="heading2">Contact Details</h3>
                    <FontAwesomeIcon
                      icon={faCircleUser}
                      size="lg"
                      style={{
                        position: 'absolute',
                        top: '2px',
                        left: '0',
                        width: '18px',
                        fontSize: '18px',
                      }}
                    />
                  </div>
                  <CspContactDetails cspInfo={cspInfo} />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className="campaigns-listing details">
                  <div className="details-heading campaigns-listing">
                    <h3 className="heading2">
                      {this.campaignListTitle(
                        maskedTotalRecords,
                        appliedFilters?.status
                      )}
                    </h3>
                    <FontAwesomeIcon
                      icon={faBullhorn}
                      size="lg"
                      style={{
                        position: 'absolute',
                        top: '2px',
                        left: '0',
                        width: '18px',
                      }}
                    />
                  </div>
                  <Box
                    flexDirection="column"
                    margin={{ top: 's', bottom: 's' }}
                  >
                    <Box>
                      <FilterToggleButton
                        visible={isFilterVisible}
                        onClick={this.toggleFilterVisibility}
                      />
                    </Box>
                    {isFilterVisible && (
                      <TableFilter
                        portal="mno"
                        configs={filterConfigs}
                        candidateValues={candidateFilters}
                        appliedValues={appliedFilters}
                        onCandidateValuesChange={
                          this.handleCandidateFiltersChange
                        }
                        onAppliedValuesChange={handleAppliedFiltersChange}
                        className="filters"
                        data-testid="cspDetailCampaigns"
                        style={{ marginTop: '10px' }}
                      />
                    )}
                  </Box>
                  <Table
                    testId="cspDetailCampaignsTable"
                    loading={tableLoader}
                    emptyState="no campaigns to view"
                    records={{ total: campaignInfo.totalRecords }}
                    handleChangePage={this.handleChangePage}
                    createSortHandler={this.handleSorting}
                    filter={additionalFilters}
                    headRows={headRows}
                    data={campaignInfo.records}
                    rowKey="uid"
                    renderRow={(data) => {
                      return (
                        <CampaignListingRow key={data.uid} campaign={data} />
                      );
                    }}
                    pagination={maskedPaginationData}
                  />
                </div>
              </Grid>
            </Grid>
          </Container>
        )}
      </section>
    );
  }
}

export default withFilters(CspDetail, {
  configs: FILTER_CONFIG,
  loaders: {
    suggestion: {
      brandName: {
        minLength: 2,
        load: (value) => fetchBrandSuggestions({ prefix: value }),
      },
    },
  },
});
