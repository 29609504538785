import { FC, ComponentProps } from 'react';
import { BoxV2 as Box } from 'portal-commons';

interface Text extends ComponentProps<typeof Box> {
  variant: 'h1' | 'h2' | 'h3' | 'body' | 'label' | 'info' | 'caption';
}

const VARIANT_MAP = {
  h1: {
    as: 'h1',
    sx: {
      color: 't.grey500',
      fontSize: '22px',
      fontWeight: 700,
    },
  },
  h2: {
    as: 'h2',
    sx: {
      color: 't.black70',
      fontSize: 'H500',
      fontWeight: 'semiBold',
    },
  },
  h3: {
    as: 'h3',
    sx: {
      color: 't.black70',
      fontSize: 'H400',
      fontWeight: 'semiBold',
    },
  },
  body: {
    as: 'p',
    sx: {
      color: 't.black70',
      fontSize: 'H300',
    },
  },
  label: {
    as: 'label',
    sx: {
      color: 't.black70',
      fontSize: 'H300',
    },
  },
  info: {
    as: 'p',
    sx: {
      color: 't.black70',
      fontSize: 'H300',
      fontWeight: 'semiBold',
    },
  },
  caption: {
    as: 'span',
    sx: {
      color: 't.black70',
      fontSize: 'H100',
      display: 'block',
    },
  },
};

const Text: FC<Text> = ({ variant, children, sx, ...restProps }) => {
  const {
    sx: vSx,
    as,
    ...restVariantProps
  } = VARIANT_MAP[variant] || VARIANT_MAP.body;
  return (
    <Box
      as={(as as React.ElementType<any>) || 'div'}
      sx={{
        lineHeight: 'normal',
        fontWeight: 'normal',
        overflowWrap: 'break-word',
        ...vSx,
        ...sx,
      }}
      {...restVariantProps}
      {...restProps}
    >
      {children}
    </Box>
  );
};

export default Text;
