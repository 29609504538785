import React from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  US_DATE_FORMAT,
  convertTimeWithTimezone,
} from '../../../../utils/time';

function MatchedBrandRow(props) {
  const { data } = props;
  return (
    <TableRow
      data-testid="matchedBrandRow"
      className="link"
      onClick={() =>
        props.history.push({
          pathname: `/brands/${data.uid}`,
          state: {
            goBackPage: `${props.location.pathname}${
              props.location.search ? props.location.search : ''
            }`,
          },
        })
      }
    >
      <TableCell
        align="left"
        style={{ minWidth: 125 }}
        data-testid="matchedBrandId"
      >
        <span>{data.uid ? data.uid : data.id}</span>
      </TableCell>
      <TableCell
        align="left"
        style={{ minWidth: 125, overflowWrap: 'break-word', maxWidth: 220 }}
        data-testid="matchedBrandCompanyName"
      >
        <span>{data.companyName ? data.companyName : ''}</span>
      </TableCell>
      <TableCell
        align="left"
        style={{ minWidth: 125, overflowWrap: 'break-word', maxWidth: 220 }}
        data-testid="matchedBrandDisplayName"
      >
        <span>
          {data.csp && data.csp.displayName ? data.csp.displayName : ''}
        </span>
      </TableCell>
      <TableCell
        align="left"
        style={{ minWidth: 125 }}
        data-testid="matchedBrandEntityType"
      >
        <span>{data.entityType ? data.entityType : ''}</span>
      </TableCell>
      <TableCell
        align="left"
        style={{ minWidth: 135 }}
        data-testid="matchedBrandCreateDate"
      >
        {convertTimeWithTimezone(data.createDate, undefined, US_DATE_FORMAT)}
      </TableCell>
    </TableRow>
  );
}
MatchedBrandRow.propTypes = {
  data: PropTypes.object,
};
export default withRouter(MatchedBrandRow);
