import { useState, useEffect, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEnvelope,
  faAddressBook,
  faCircleCheck,
  faCircleUser,
  faLockKeyhole,
  faPen,
} from '@fortawesome/pro-regular-svg-icons';
import { BoxV2 as Box, Flex, Table, Tooltip, Button } from 'portal-commons';

import { CustomSwitch } from '../../../../shared_elements/ui_elements';
import { Loader } from '../../../../shared_elements';
import { User, Profile } from '../types';
import {
  Text,
  Divider,
  DetailItem,
  UserCreateModal,
  UserDeleteModal,
  MfaToggleModal,
  EmailPreferenceEditModal,
  UserListingRow,
  SectionBlock,
} from '../components';
import { toastFlashMessage } from '../../../../utils';
import {
  getUsers,
  getProfile,
  createUser,
  deleteUser,
  enableMfa,
  disableMfa,
  getApprovedDcaList,
  updateProfile,
} from '../api';
import { CreateUserPayload, ApprovedDca } from '../types';

const USER_TABLE_COLUMNS = [
  { id: 'fullName', label: 'NAME', sortable: true },
  { id: 'email', label: 'EMAIL', sortable: false },
  { id: 'created', label: 'CREATED ON', sortable: true },
  { id: 'lastLogin', label: 'LAST LOGIN', sortable: true },
  { id: 'action', label: '', sortable: false },
];

enum Scene {
  Details = 'details',
  EditEmailPreferences = 'editEmailPreferences',
  CreateUser = 'createUser',
  DeleteUser = 'deleteUser',
  ChangeMfa = 'changeMfa',
}

type LoadingType =
  | 'profile'
  | 'createUser'
  | 'deleteUser'
  | 'mfaChange'
  | 'editEmails'
  | 'users'
  | 'dca';

const TABLE_PAGE_SIZE = 10;

export const ProfilePage = () => {
  const [profile, setProfile] = useState<Profile>();
  const [mfaEnabled, setMfaEnabled] = useState(false);
  const [approvedDcaList, setApprovedDcaList] = useState<ApprovedDca[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [scene, setScene] = useState<Scene>(Scene.Details);
  const [editingUser, setEditingUser] = useState<User>();
  const [userTableFilter, setUserTableFilter] = useState({
    sortField: 'created',
    ascendingOrder: false,
  });
  const [dcaTableFilter, setDcaTableFilter] = useState({
    sortField: 'displayName',
    ascendingOrder: true,
  });
  const [userTableCurrentPage, setUserTableCurrentPage] = useState(1);
  const [loading, setLoading] = useState<Record<LoadingType, boolean>>({
    profile: false,
    users: false,
    dca: false,
    createUser: false,
    deleteUser: false,
    mfaChange: false,
    editEmails: false,
  });
  const isMaxUsersReached =
    !!profile?.maxPortalUsers && users.length >= profile?.maxPortalUsers;

  const setLoadingByType = (key: LoadingType, value: boolean) => {
    setLoading((prev) => ({ ...prev, [key]: value }));
  };

  const fetchProfile = async () => {
    setLoadingByType('profile', true);
    const profile = await getProfile();
    setLoadingByType('profile', false);
    if (profile) {
      setProfile(profile);
    }
  };

  const fetchUsers = async () => {
    setLoadingByType('users', true);
    const users = await getUsers();
    setUsers(users ?? []);
    setLoadingByType('users', false);
  };

  const fetchApprovedDcaList = async () => {
    setLoadingByType('dca', true);
    const dcaList = await getApprovedDcaList();
    setApprovedDcaList(dcaList ?? []);
    setLoadingByType('dca', false);
  };

  useEffect(() => {
    fetchProfile();
    fetchUsers();
    fetchApprovedDcaList();
  }, []);

  useEffect(() => {
    setMfaEnabled(profile?.mfaEnabled ?? false);
  }, [profile]);

  const handleCreateUser = async (payload: CreateUserPayload) => {
    setLoadingByType('createUser', true);
    const success = await createUser(payload);
    setLoadingByType('createUser', false);
    if (success) {
      fetchUsers();
      setScene(Scene.Details);
      toastFlashMessage(
        `New User ${payload.firstName} ${payload.lastName} Created`,
        'success'
      );
    }
  };
  const handleDeleteUser = async () => {
    if (editingUser) {
      setLoadingByType('deleteUser', true);
      const success = await deleteUser(editingUser.id);
      setLoadingByType('deleteUser', false);
      if (success) {
        fetchUsers();
        setScene(Scene.Details);
        toastFlashMessage(
          `User ${editingUser.profile.fullName} Deleted`,
          'success'
        );
      }
    }
  };

  const handleMfaChange = async () => {
    setLoadingByType('mfaChange', true);
    const success = mfaEnabled ? await disableMfa() : await enableMfa();
    setLoadingByType('mfaChange', false);
    if (success) {
      setScene(Scene.Details);
      toastFlashMessage(
        `MFA ${mfaEnabled ? 'deactivated' : 'activated'} for all portal users`,
        'success'
      );
      setMfaEnabled(!mfaEnabled);
    }
  };

  const handleEmailPreferenceSave = async (emails: string[]) => {
    setLoadingByType('editEmails', true);
    const profile = await updateProfile({
      email: emails.map((e) => e.trim()).join(','),
    });
    setLoadingByType('editEmails', false);
    if (profile) {
      setScene(Scene.Details);
      toastFlashMessage('Email Address Successfully Updated', 'success');
      setProfile(profile);
    }
  };

  const approvedDcaTableData = useMemo(() => {
    return (
      approvedDcaList
        .slice()
        .sort((a, b) => {
          if (dcaTableFilter.sortField === 'displayName') {
            return dcaTableFilter.ascendingOrder
              ? a.displayName.localeCompare(b.displayName)
              : b.displayName.localeCompare(a.displayName);
          }
          return 0;
        })
        // add this map() to avoid TSLint error for <Table>
        .map((dca) => ({
          ...dca,
        }))
    );
  }, [approvedDcaList, dcaTableFilter]);

  const usersTableData = useMemo(() => {
    return users
      .slice()
      .sort((a, b) => {
        if (userTableFilter.sortField === 'fullName') {
          return userTableFilter.ascendingOrder
            ? a.profile.fullName.localeCompare(b.profile.fullName)
            : b.profile.fullName.localeCompare(a.profile.fullName);
        }
        if (userTableFilter.sortField === 'created') {
          return userTableFilter.ascendingOrder
            ? a.created - b.created
            : b.created - a.created;
        }
        if (userTableFilter.sortField === 'lastLogin') {
          return userTableFilter.ascendingOrder
            ? a.lastLogin - b.lastLogin
            : b.lastLogin - a.lastLogin;
        }
        return 0;
      })
      .slice(
        (userTableCurrentPage - 1) * TABLE_PAGE_SIZE,
        userTableCurrentPage * TABLE_PAGE_SIZE
      );
  }, [userTableFilter, userTableCurrentPage, users]);

  if (loading.profile) {
    return <Loader />;
  }

  const emails = profile?.email.split(',') ?? [];

  return (
    <div className="page-content" data-testid="mnoProfilePage">
      <Text
        variant="h1"
        sx={{ mb: '57px', display: 'block', textAlign: 'center' }}
      >
        {profile?.displayName}
      </Text>
      <SectionBlock>
        <Flex sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <Flex sx={{ gap: 'xs', mb: 'xl', alignItems: 'center' }}>
            <SectionBlock.Icon size="xl" icon={faEnvelope} />
            <Text variant="h2">Email Preferences</Text>
          </Flex>
          <Flex
            sx={{
              flexDirection: 'row',
              columnGap: '4px',
              color: 't.green140',
              fontWeight: 'normal',
              fontSize: 'H300',
              lineHeight: 'H400',
              cursor: 'pointer',
            }}
            role="button"
            onClick={() => setScene(Scene.EditEmailPreferences)}
            data-testid="editEmailPreferencesButton"
          >
            <FontAwesomeIcon icon={faPen} />
            <span>Edit</span>
          </Flex>
        </Flex>
        <Flex sx={{ flexDirection: 'column', gap: 'xs' }}>
          <Flex sx={{ gap: 'xs', alignItems: 'center' }}>
            <SectionBlock.Icon size="md" icon={faAddressBook} />
            <Text variant="h3">Contact Email</Text>
          </Flex>
          <Text variant="body">
            Used for business communications and system event notifications.
          </Text>
          <Divider />
        </Flex>
        <Box
          data-testid="contactEmailsRoot"
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            gap: 's',
            mt: 's',
          }}
        >
          {emails.map((email: string, index: number) => (
            <DetailItem key={`${index}-${email}`} label="Email Address">
              {email}
            </DetailItem>
          ))}
        </Box>
      </SectionBlock>

      <SectionBlock>
        <Flex sx={{ gap: 'xs', alignItems: 'center' }}>
          <SectionBlock.Icon size="xl" icon={faCircleCheck} />
          <Text variant="h2">MNO Approved DCAs</Text>
        </Flex>
        <Divider sx={{ marginY: 'm' }} />
        <Table
          data-testid="approvedDcaTable"
          disableHover
          loading={loading.dca}
          emptyState="no DCA to view"
          records={{ total: approvedDcaList.length }}
          headRows={[{ id: 'displayName', label: 'NAME', sortable: true }]}
          data={approvedDcaTableData}
          rowKey="uid"
          filter={dcaTableFilter}
          createSortHandler={(field) => {
            setDcaTableFilter((prev) => ({
              ...prev,
              sortField: field,
              ascendingOrder:
                prev.sortField === field ? !prev.ascendingOrder : true,
            }));
          }}
        />
      </SectionBlock>

      <SectionBlock>
        <Flex sx={{ mb: 'l', justifyContent: 'space-between' }}>
          <Flex sx={{ gap: 'xs', alignItems: 'center' }}>
            <SectionBlock.Icon size="xl" icon={faCircleUser} />
            <Text variant="h2">User Management</Text>
          </Flex>
          <Button
            shape="square"
            size="small"
            onClick={() => {
              setScene(Scene.CreateUser);
            }}
            disabled={isMaxUsersReached}
            data-testid="createUserButton"
          >
            Create New User
          </Button>
        </Flex>
        <Divider sx={{ marginY: 'm' }} />
        <Flex sx={{ alignItems: 'center', mb: 'm', gap: 'xs' }}>
          <SectionBlock.Icon size="md" icon={faLockKeyhole} />
          <Text variant="h3">Enable Multi Factor Authentication (MFA)</Text>
          <Tooltip
            portal="csp"
            title="Select this option to activate Multi Factor Authentication for all Users. MFA is available through Google Authenticator, Okta Verify or SMS authentication."
            style={{
              position: 'static',
              fontSize: '16px',
              display: 'block',
            }}
          />
          <CustomSwitch
            data-testid="toggleMfaButton"
            val={mfaEnabled}
            handleChange={() => setScene(Scene.ChangeMfa)}
          />
        </Flex>
        <Table
          disableHover
          testId="usersTable"
          rowKey="id"
          loading={loading.users}
          emptyState="No users"
          records={{ total: users.length }}
          headRows={USER_TABLE_COLUMNS}
          filter={userTableFilter}
          handleChangePage={(page) => setUserTableCurrentPage(page)}
          createSortHandler={(field) => {
            setUserTableFilter((prev) => ({
              ...prev,
              sortField: field,
              ascendingOrder:
                prev.sortField === field ? !prev.ascendingOrder : true,
            }));
          }}
          tableData={usersTableData.map((user) => (
            <UserListingRow
              key={user.id}
              user={user}
              onDelete={() => {
                setEditingUser(user);
                setScene(Scene.DeleteUser);
              }}
            />
          ))}
          pagination={{
            count: Math.ceil(users.length / TABLE_PAGE_SIZE),
            page: userTableCurrentPage,
            rowsPerPage: TABLE_PAGE_SIZE,
            totalRecords: users.length,
          }}
        />
        {isMaxUsersReached && (
          <Box sx={{ mt: 'l', textAlign: 'center' }}>
            Maximum number of users reached!
            <br />
            Please delete existing users if you wish to create a new one.
          </Box>
        )}
      </SectionBlock>
      <UserCreateModal
        open={scene === Scene.CreateUser}
        onClose={() => setScene(Scene.Details)}
        onSubmit={handleCreateUser}
        disabled={loading.createUser}
      />
      <UserDeleteModal
        open={scene === Scene.DeleteUser}
        user={editingUser}
        onClose={() => setScene(Scene.Details)}
        onSubmit={handleDeleteUser}
        disabled={loading.deleteUser}
      />
      <MfaToggleModal
        on={!mfaEnabled}
        disabled={loading.mfaChange}
        open={scene === Scene.ChangeMfa}
        onClose={() => setScene(Scene.Details)}
        onSubmit={handleMfaChange}
      />
      <EmailPreferenceEditModal
        open={scene === Scene.EditEmailPreferences}
        emails={emails}
        disabled={loading.editEmails}
        onClose={() => setScene(Scene.Details)}
        onSubmit={handleEmailPreferenceSave}
      />
    </div>
  );
};

export default ProfilePage;
