import React from 'react';
import CspDetailItem from './CspDetailItem';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

const CspContactDetails = (props) => {
  const { cspInfo } = props;
  return (
    <Grid container>
      <Grid item xs={6} data-testid="cspContactDetailsEmail">
        <CspDetailItem title="Email Address" value={cspInfo.email} />
      </Grid>
      <Grid item xs={6} data-testid="cspContactDetailsPhone">
        <CspDetailItem title="Phone Number" value={cspInfo.phone} />
      </Grid>
    </Grid>
  );
};

CspContactDetails.propTypes = {
  cspInfo: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
  }).isRequired,
};

export default CspContactDetails;
