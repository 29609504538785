import React from 'react';
import { Link } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { S3_ASSETS_PATH } from '../../../constants';
import { Button } from 'portal-commons';

const ResetEmailSuccess = (props) => {
  return (
    <div className="campaign-registry" data-testid="resetEmailSuccess">
      <Grid item className="content-blk">
        <img
          src={`${S3_ASSETS_PATH}/images/MNO/thankyou-mno.svg`}
          alt="logo"
        ></img>
        <h4 className="heading1">Thank You!</h4>
        <p className="heading1 text ">
          A reset password link has been sent to your registered email address.
        </p>
        <br />
        <p className="heading1 text">
          {' '}
          Please click on the link in the email to reset your password.
        </p>
        <br />
        <p className="supportText">
          If you are unable to reset your password, please contact Customer
          Support.
        </p>
      </Grid>
      <Grid item className="link flex h-center">
        <Link to="/login" data-testid="resetEmailSuccessLoginButton">
          <Button
            data-testid="backToLoginButton"
            type="submit"
            portal="mno"
            shape="rounded"
            color="secondary"
          >
            Back to Login
          </Button>
        </Link>
      </Grid>
    </div>
  );
};

export default ResetEmailSuccess;
