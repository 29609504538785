import { FC, useEffect, Fragment } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { Button, Flex, BoxV2 as Box } from 'portal-commons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faAddressBook } from '@fortawesome/pro-regular-svg-icons';

import Modal from './Modal';
import RemovableTextInput from './RemovableTextInput';
import Text from './Text';
import Divider from './Divider';
import {
  isRequired,
  isValidEmail,
  createMaxLengthValidator,
} from '../validators';

interface EmailPreferenceEditModalProps {
  open: boolean;
  emails?: string[];
  disabled?: boolean;
  onClose: () => void;
  onSubmit: (emails: string[]) => void;
}

const DEFAULT_EMAIL_ITEM = { email: '' };
const MAX_COUNT = {
  contactEmail: 5,
};
const MAX_LENGTH = {
  contactEmail: 100,
};

const EmailPreferenceEditModal: FC<EmailPreferenceEditModalProps> = ({
  open,
  emails = [],
  onClose,
  onSubmit,
  disabled = false,
}) => {
  const { handleSubmit, control, reset, getValues } = useForm({
    defaultValues: { emails: [DEFAULT_EMAIL_ITEM] },
  });

  const emailArray = useFieldArray({
    control,
    name: 'emails',
  });

  useEffect(() => {
    if (open) {
      const emailItems =
        emails.length > 0
          ? emails.map((email) => ({ email }))
          : [DEFAULT_EMAIL_ITEM];
      reset({
        emails: emailItems,
      });
    }
    // ignore emails prop in dependency array to avoid UI inconsistency on save button clicked
  }, [open]);

  const handleAddEmail = () => {
    emailArray.append({ email: '' });
  };
  const handleRemoveEmail = (index: number) => {
    emailArray.remove(index);
  };
  const handleSaveClick = () => {
    const { emails } = getValues();
    onSubmit?.(emails.map((item: any) => item.email));
  };
  return (
    <Modal
      open={open}
      width="750px"
      maxWidth="md"
      data-testid="emailPreferenceModal"
    >
      <Flex sx={{ gap: 's', alignContent: 'center', mb: 'xxl' }}>
        <Flex
          sx={{
            alignItems: 'center',
            color: 't.black70',
            fontSize: 'H500',
          }}
        >
          <FontAwesomeIcon icon={faEnvelope} />
        </Flex>
        <Text variant="h2">Email & Notification Preferences</Text>
      </Flex>
      <Flex sx={{ gap: '8px', alignItems: 'center' }}>
        <Flex
          sx={{
            alignItems: 'center',
            color: 't.black70',
            fontSize: 'H400',
            mr: '8px',
          }}
        >
          <FontAwesomeIcon icon={faAddressBook} />
        </Flex>

        <Text variant="h3">Contact Email</Text>
        {emailArray.fields.length < MAX_COUNT.contactEmail && (
          <Box
            role="button"
            tabIndex={0}
            sx={{
              ml: 'H400',
              color: 't.green140',
              fontSize: 'H300',
              fontWeight: 500,
              lineHeight: 'normal',
              border: 'none',
              background: '#fff',
              display: 'inline-block',
              textAlign: 'left',
              width: 'fit-content',
              cursor: 'pointer',
            }}
            onClick={handleAddEmail}
          >
            + Add a New Email
          </Box>
        )}
      </Flex>
      <Divider sx={{ mt: 's', mb: 'm' }} />
      <form
        noValidate
        onSubmit={handleSubmit(handleSaveClick)}
        autoComplete="off"
      >
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr',
            columnGap: '8px',
            rowGap: 'l',
          }}
        >
          {emailArray.fields.map((field, index) => (
            <Fragment key={field.id ?? Date.now()}>
              <Controller
                name={`emails.${index}`}
                control={control}
                rules={{
                  validate: {
                    isRequired: (v: any) => isRequired(v.email),
                    maxLength: (v: any) =>
                      createMaxLengthValidator(MAX_LENGTH.contactEmail)(
                        v.email
                      ),
                    isValidEmail: (v: any) => isValidEmail(v.email),
                  },
                }}
                render={({ field, fieldState: { error = {} } }) => (
                  <RemovableTextInput
                    data-testid="emailInput"
                    required
                    maxLength={MAX_LENGTH.contactEmail}
                    {...field}
                    error={!!error.message}
                    note={error.message}
                    label="Email Address"
                    removable={emailArray.fields.length > 1}
                    onRemoveClick={() => handleRemoveEmail(index)}
                    onChange={(val) => field.onChange({ email: val })}
                    value={(field.value as any).email}
                  />
                )}
              />
            </Fragment>
          ))}
        </Box>
        <Flex
          sx={{
            flexDirection: 'row',
            justifyContent: 'center',
            columnGap: '20px',
            padding: '24px 0 40px',
          }}
        >
          <Button
            variant="outline"
            onClick={onClose}
            data-testid="cancelButton"
          >
            Cancel
          </Button>
          <Button disabled={disabled} data-testid="saveButton" type="submit">
            Save
          </Button>
        </Flex>
      </form>
    </Modal>
  );
};

export default EmailPreferenceEditModal;
