import React from 'react';
import Grid from '@material-ui/core/Grid';

interface Props {
  index: string;
  message: string;
}

const SampleMessageView: React.FC<Props> = (props: Props) => {
  const { index, message } = props;
  return (
    <Grid container className="sample-message-item">
      <Grid item xs={12}>
        <div className="message-count">
          <h3 data-testid="index">{index}</h3>
        </div>
        <p className="paragraph" data-testid="message">
          {message}
        </p>
      </Grid>
    </Grid>
  );
};

export default SampleMessageView;
