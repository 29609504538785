import Login from './containers/Login';
import ForgotPassword from './containers/ForgotPassword';
import ResetPassword from './containers/ResetPassword';
import UserAuthThanks from './containers/UserAuthThanks';
import LoggedUserRedirect from '../../hocs/LoggedUserRedirect';

export const authRoutes = [
  {
    path: '/',
    component: LoggedUserRedirect(Login),
    key: 'login',
  },
  {
    path: '/login',
    component: LoggedUserRedirect(Login),
    key: 'login',
  },
  {
    path: '/forgot-password',
    component: LoggedUserRedirect(ForgotPassword),
    key: 'forgotPassword',
  },
  {
    path: '/expired-password',
    component: LoggedUserRedirect(ForgotPassword),
    key: 'expiredPassword',
  },
  {
    path: '/reset-password',
    component: LoggedUserRedirect(ResetPassword),
    key: 'resetPassword',
  },
  {
    path: '/set-password',
    component: LoggedUserRedirect(ResetPassword),
    key: 'setPassword',
  },
  {
    path: '/user/reset/successfully',
    component: LoggedUserRedirect(UserAuthThanks),
    key: 'userAuthThanks',
  },
];
