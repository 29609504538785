import React, { useState, Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';

function LoggedUserRed(ComposedComponent, extraInfo) {
  class LoginAccessCrtl extends Component {
    constructor(props) {
      super(props);
      this.state = {
        userDetail: localStorage.getItem('okta-token-storage')
          ? JSON.parse(localStorage.getItem('okta-token-storage'))
          : {},
      };
    }
    componentDidMount() {
      let { userDetail } = this.state;
      if (userDetail.idToken && userDetail.accessToken) {
        this.props.history.push('/dashboard');
      } else {
        localStorage.clear();
      }
    }
    render() {
      const { userDetail } = this.state;
      if (!(userDetail.idToken && userDetail.accessToken)) {
        localStorage.clear();
        return <ComposedComponent />;
      } else {
        return <Redirect to="/dashboard" />;
      }
    }
  }
  return withRouter(LoginAccessCrtl);
}
export default LoggedUserRed;
